import React, { Component } from "react";
import { Link } from "react-router-dom";
import Text from "react-text";
import Modal from "react-modal";
import Login from "../../pages/login.component";

let arabicmodal =
  localStorage.getItem("language") === "ar" ? "arabicmodal" : "";
let crossarabic =
  localStorage.getItem("language") === "ar" ? "crossarabic" : "";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMovies: "",
      showModal: false,
      showRegisterModal: false
    };
  }

  render() {
    const d = new Date();
    const yr = d.getFullYear();
    return (
      <>
        <footer className="footer padbothvertical-40">
          <div className="home-content-container">
            <div className="footer-upper">
              <div className="footer-left">
                <h4>
                  {localStorage.getItem("language") !== "ar" ? (
                    "MORE LINKS"
                  ) : (
                    <Text>روابط مهمة   </Text>
                  )}
                </h4>
                <div className="footer-menu">

                  {(localStorage.getItem("token") == '' || localStorage.getItem("token") == "undefined" || !localStorage.getItem("token")) ?
                    <>
                      <div className="footer-part">
                        <ul>
                          <li>
                            <a href="#" onClick={() => this.setState({ showModal: true })}>
                              {localStorage.getItem("language") !== "ar" ? (
                                "sign in"
                              ) : (
                                <Text>تسجيل الدخول</Text>
                              )}
                            </a>
                          </li>
                          <li>
                            <a href="#" onClick={() => this.setState({ showRegisterModal: true })}>
                              {localStorage.getItem("language") !== "ar" ? (
                                "register"
                              ) : (
                                <Text>تسجيل</Text>
                              )}
                            </a>
                            {/* <Link
                        to={{
                          pathname: "/login",
                          state: { showRegister: true },
                        }}
                      >
                        Register
                      </Link> */}
                          </li> </ul>
                      </div> </> : ''}

                  <div className="footer-part">
                    <ul>
                      <li>
                        <a href="/movies">
                          {localStorage.getItem("language") !== "ar" ? (
                            "Now Showing"
                          ) : (
                            <Text>يعرض حالياً </Text>
                          )}
                        </a>
                      </li>
                      <li>
                        <a href="/movies?type=comingsoon">
                          {localStorage.getItem("language") !== "ar" ? (
                            "Coming Soon"
                          ) : (
                            <Text>يعرض قريباً</Text>
                          )}
                        </a>
                      </li>
                      <li>
                        <a href="/locations">
                          {localStorage.getItem("language") !== "ar" ? (
                            "locations"
                          ) : (
                            <Text>المواقع</Text>
                          )}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-part space">
                    <ul>
                      <li>
                        <a href="/faq">
                          {localStorage.getItem("language") !== "ar" ? (
                            "FAQs"
                          ) : (
                            <Text>أسئلة شائعة                            </Text>
                          )}
                        </a>
                      </li>
                      <li>
                        <a href="http://corporate.kncc.com/" target="_blank">
                          {localStorage.getItem("language") !== "ar" ? (
                            "corporate"
                          ) : (
                            <Text>عن الشركة</Text>
                          )}
                        </a>
                      </li>
                      <li>
                        <a href="/contactus">
                          {localStorage.getItem("language") !== "ar" ? (
                            "contact us"
                          ) : (
                            <Text>تواصل معنا </Text>
                          )}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-part">
                    <ul>
                      <li>
                        <a href="/promotion">
                          {localStorage.getItem("language") !== "ar" ? (
                            "promotions"
                          ) : (
                            <Text>العروض والأخبار
                            </Text>
                          )}
                        </a>
                      </li>
                      <li>
                        <a href="/terms">
                          {localStorage.getItem("language") !== "ar" ? (
                            "terms and conditions"
                          ) : (
                            <Text> الشروط والأحكام </Text>
                          )}
                        </a>
                      </li>
                      <li>
                        <a href="/privacypolicy">
                          {localStorage.getItem("language") !== "ar" ? (
                            "privacy policy"
                          ) : (
                            <Text>سياسة الخصوصية </Text>
                          )}
                        </a>
                      </li>

                      <li>
                        <a href="/agerating">
                          {localStorage.getItem("language") !== "ar" ? (
                            "AGE RATING"
                          ) : (
                            <Text>الفئات العمرية</Text>
                          )}
                        </a>
                      </li>


                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-middle">
                <h4>
                  {localStorage.getItem("language") !== "ar" ? (
                    "DOWNLOAD OUR MOBILE APP"
                  ) : (
                    <Text>لتطبيق سينسكيب للهواتف
                   </Text>
                  )}
                </h4>
                <div className="footer-middle-img">
                  <a href="#">
                    <img
                      src={require("../../assets/googleplay.png").default}
                      alt="google-play"
                    />
                  </a>
                  <a href="#">
                    <img
                      src={require("../../assets/appstore.png").default}
                      alt="app-store"
                    />
                  </a>
                </div>
              </div>
              <div className="footer-right">
                <h4>
                  {localStorage.getItem("language") !== "ar" ? (
                    "SOCIAL MEDIA"
                  ) : (
                    <Text>برامج التواصل الاجتماعي  </Text>
                  )}
                </h4>
                <div className="footer-right-img">
                  <a
                    href="https://www.facebook.com/CinescapeKuwait"
                    target="_blank"
                  >
                    <img src={require("../../assets/fb.png").default} alt="" />
                  </a>
                  <a
                    href="https://www.instagram.com/cinescapekuwait/"
                    target="_blank"
                  >
                    <img src={require("../../assets/in.png").default} alt="" />
                  </a>
                  <a href="https://twitter.com/Cinescapekuwait" target="_blank">
                    <img src={require("../../assets/tw.40bd11b0.png").default} alt="" />
                  </a>
                  <a href="https://www.youtube.com/@CinescapeKuwait" target="_blank">
                    <img src={require("../../assets/yt.png").default} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-lower">
              <p>
                {localStorage.getItem("language") !== "ar" ? (
                  `Copyright ${yr} Cinescape. All Rights Reserved. `
                ) : (
                  <Text>حقوق الطبع والنشر {yr} سينسكيب. كل الحقوق محفوظة.</Text>
                )}
              </p>
            </div>
          </div>
        </footer>
        {/* for mobile */}

        <footer className="footer footer-mobile padbothvertical-40">

        <div class="mobile-border-bot"></div>
          <div className="footer-middle">
            <h4>
              {localStorage.getItem("language") !== "ar" ? (
                "DOWNLOAD OUR MOBILE APP"
              ) : (
                <Text>لتطبيق سينسكيب للهواتف
                </Text>
              )}
            </h4>
            <div className="footer-middle-img">
              <a href="#">
                <img
                  src={require("../../assets/googleplay.png").default}
                  alt="google-play"
                />
              </a>
              <a href="#">
                <img
                  src={require("../../assets/appstore.png").default}
                  alt="app-store"
                />
              </a>
            </div>
          </div>
          <div className="footer-right">
            <h4>
              {localStorage.getItem("language") !== "ar" ? (
                "SOCIAL MEDIA"
              ) : (
                <Text>برامج التواصل الاجتماعي </Text>
              )}
            </h4>
            <div className="footer-right-img">
              <a
                href="https://www.facebook.com/CinescapeKuwait"
                target="_blank"
              >
                <img src={require("../../assets/fb.png").default} alt="" />
              </a>
              <a
                href="https://www.instagram.com/cinescapekuwait/"
                target="_blank"
              >
                <img src={require("../../assets/in.png").default} alt="" />
              </a>
              <a href="https://twitter.com/Cinescapekuwait" target="_blank">
                <img src={require("../../assets/tw.40bd11b0.png").default} alt="" />
              </a>
              <a href="https://www.youtube.com/@CinescapeKuwait" target="_blank">
                <img src={require("../../assets/yt.png").default} alt="" />
              </a>
            </div>
          </div>
          <div className="footer-upper">
            <div className="footer-left">
              <h4>
                {localStorage.getItem("language") !== "ar" ? (
                  "MORE LINKS"
                ) : (
                  <Text>روابط مهمة</Text>
                )}
              </h4>
              <div className="footer-menu">

                <div className="footer-part">
                  <ul>

                    {(localStorage.getItem("token") == '' || localStorage.getItem("token") == "undefined" || !localStorage.getItem("token")) ?
                      <>

                        <li>
                          <a href="#" onClick={() => this.setState({ showModal: true })}>
                            {localStorage.getItem("language") !== "ar" ? (
                              "sign in"
                            ) : (
                              <Text>تسجيل الدخول</Text>
                            )}
                          </a>
                        </li>
                        <li>
                          <a href="#" onClick={() => this.setState({ showRegisterModal: true })}>
                            {localStorage.getItem("language") !== "ar" ? (
                              "register"
                            ) : (
                              <Text>تسجيل</Text>
                            )}
                          </a>
                        </li>
                      </> : ''}
                    <li>


                      <a href="/#nowshowing">
                        {localStorage.getItem("language") !== "ar" ? (
                          "Now Showing"
                        ) : (
                          <Text>يعرض حالياً </Text>
                        )}
                      </a>
                    </li>
                    <li>
                      <a href="/comingsoon">
                        {localStorage.getItem("language") !== "ar" ? (
                          "Coming Soon"
                        ) : (
                          <Text>يعرض قريباً  </Text>
                        )}
                      </a>
                    </li>
                    <li>
                      <a href="/locations">
                        {localStorage.getItem("language") !== "ar" ? (
                          "locations"
                        ) : (
                          <Text>المواقع</Text>
                        )}
                      </a>
                    </li>

                  
                    <li>
                      <a href="/faq">
                        {localStorage.getItem("language") !== "ar" ? (
                          "FAQ"
                        ) : (
                          <Text>أسئلة شائعة</Text>
                        )}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {localStorage.getItem("language") !== "ar" ? (
                          "corporate"
                        ) : (
                          <Text>عن الشركة</Text>
                        )}
                      </a>
                    </li>


                   

                  </ul>
                </div>
                <div className="footer-part space">
                  <ul>
                    {/* <li>
                      <a href="/information/faq">
                        {localStorage.getItem("language") !== "ar" ? (
                          "FAQ"
                        ) : (
                          <Text>أسئلة شائعة</Text>
                        )}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {localStorage.getItem("language") !== "ar" ? (
                          "corporate"
                        ) : (
                          <Text>عن الشركة</Text>
                        )}
                      </a>
                    </li> */}
                    <li>
                      <a href="/contactus">
                        {localStorage.getItem("language") !== "ar" ? (
                          "contact us"
                        ) : (
                          <Text>تواصل معنا                          </Text>
                        )}
                      </a>
                    </li>
                    <li>
                      <a href="/promotion">
                        {localStorage.getItem("language") !== "ar" ? (
                          "promotions"
                        ) : (
                          <Text>الترقيات</Text>
                        )}
                      </a>
                    </li>
                    <li>
                      <a href="/terms">
                        {localStorage.getItem("language") !== "ar" ? (
                          "terms & conditions"
                        ) : (
                          <Text>الأحكام والشروط</Text>
                        )}
                      </a>
                    </li>
                    <li>
                      <a href="/privacypolicy">
                        {localStorage.getItem("language") !== "ar" ? (
                          "privacy policy"
                        ) : (
                          <Text>سياسة خاصة</Text>
                        )}
                      </a>
                    </li>

                    <li>
                      <a href="/agerating">
                        {localStorage.getItem("language") !== "ar" ? (
                          "AGE RATING"
                        ) : (
                          <Text>سياسة خاصة</Text>
                        )}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-lower">
            <p>
              {localStorage.getItem("language") !== "ar" ? (
                `Copyright ${yr} Cinescape. All Rights Reserved.`
              ) : (
                <Text>حقوق الطبع والنشر {yr} سينسكيب. كل الحقوق محفوظة.</Text>
              )}
            </p>
          </div>
        </footer>
        <>
          <Modal
            isOpen={this.state.showModal}
            contentLabel="Example Modal"
            portalClassName={`trai-modal modal-name modal-login ${arabicmodal}`}
          >
            {" "}
            <button
              className={`trailer-cross modal-cross ${crossarabic}`}
              onClick={() =>
                this.setState({ showModal: false, autoplay: true })
              }
            >
              <i class="fas fa-times"></i>
            </button>
            <Login />
          </Modal>
        </>

        <>
          <Modal
            isOpen={this.state.showRegisterModal}
            contentLabel="Example Modal"
            portalClassName={`trai-modal modal-name modal-login ${arabicmodal}`}
          >
            {" "}
            <button
              className={`trailer-cross modal-cross ${crossarabic}`}
              onClick={() =>
                this.setState({ showRegisterModal: false, autoplay: true })
              }
            >
              <i class="fas fa-times"></i>
            </button>
            <Login showRegisterModal={this.state.showRegisterModal} />
          </Modal>
        </>
      </>
    );
  }
}
export default Footer;
