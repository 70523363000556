import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import Swal from "sweetalert2";

import axios from "axios";
import * as Constants from "../Constants/index";
import Switch from "react-switch";
import Switch1 from "react-switch";
import Switch2 from "react-switch";
// import DatePicker from "react-datetime";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Loading } from "../components/header/loader.component";
import OtpInput from "react-otp-input";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import Select from "react-select";
import "react-dropdown/style.css";
import "react-datepicker/dist/react-datepicker.css";
import Text from "react-text";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cities: [],
      lat: 0.0,
      lng: 0.0,
      otplogin: false,
      showLogin: (this.props.showRegisterModal) ? false : true,
      showForgot: false,
      show: this.props.show,
      name: "",
      mobile: "",
      email: "",
      confirmemail: "",
      isEmailValid: true,
      isConfirmEmailValid: true,
      otp: "",
      password: "",
      confirmpassword: "",
      showRegister: (this.props.showRegisterModal) ? true : false,
      dob: "",
      checked1: true,
      checked2: true,
      checked: true,
      userName: "",
      firstName: "",
      lastName: "",
      cc: "",
      loading: false,
      showGuest: false,
      countryCode: [],
      ccode: "",
      gender: "",
      showotpshow: false,
      bothotpshow: false,
      mobileotpshow: false,
      emailotpshow: false,
      showVerifyOTP: false,
      showConfirmVerifyType: false,
      showupdateVerifyType: false,
      showVerifyOTPOnly:false,
      emline:'',
      showMobileVerifyOTPOnly:false,
      showEmailVerifyOTPOnly:false,
      afterAccountVerified:false,
      duration: 30, // Duration of the countdown in seconds
      countdown: 0, // Current countdown time in seconds
      visible: true, // Flag to control visibility
      showGoogle:false,
      showGoogleInside:false,
      showLoginwihtoutUserid:false,
      showSwal:false


    };
    this.handleChange = this.handleChange.bind(this);

    
    this.registervalidator = new SimpleReactValidator();
    this.validator = new SimpleReactValidator();
    this.guestValidator = new SimpleReactValidator();
    this.bothotppassvalidator = new SimpleReactValidator();
    this.emailotppassvalidator = new SimpleReactValidator();
    this.resetvalidator = new SimpleReactValidator({
      messages: {
        required: 'The :attribute field is required.',
        min: 'The :attribute must be at least 8 characters long.',
        regex: 'The :attribute must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
      },
      validators: {
        strongPasswords: {
          message: 'The :attribute must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
          rule: (val, params, validator) => {
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(val);
          },
        },
      },
    });
    this.mobileotppassvalidator = new SimpleReactValidator();
    this.resetpassvalidator = new SimpleReactValidator({
      messages: {
        required: 'The :attribute field is required.',
        min: 'The :attribute must be at least 8 characters long.',
        regex: 'The :attribute must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
      },
      validators: {
        strongPasswordreset: {
          message: 'The :attribute must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
          rule: (val, params, validator) => {
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(val);
          },
        },
      },
    });
    this.forgotvalidator = new SimpleReactValidator({
      messages: {
        required: 'The :attribute field is required.',
        min: 'The :attribute must be at least 8 characters long.',
        regex: 'The :attribute must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
      },
      validators: {
        strongPassword: {
          message: 'The :attribute must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
          rule: (val, params, validator) => {
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(val);
          },
        },
      },
    });
    this.registervalidator = new SimpleReactValidator({
      messages: {
        required: 'The :attribute field is required.',
        min: 'The :attribute must be at least 8 characters long.',
        regex: 'The :attribute must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
      },
      validators: {
        strongPassword: {
          message: 'The :attribute must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
          rule: (val, params, validator) => {
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(val);
          },
        },
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.showRegisterModal !== prevProps.showRegisterModal) {
      // Update the state when the prop changes
      this.setState({ showRegister: this.props.showRegisterModal, showLogin:false });
    }
  }

  startCountdown = () => {
    this.clearCountdownInterval();

    this.setState({ countdown: this.state.duration, visible: true });
  
    this.countdownInterval = setInterval(() => {
      if (this.state.countdown === 1) {
        clearInterval(this.countdownInterval);
        this.setState({ countdown: 0, visible: false });
      } else {
        this.setState((prevState) => ({ countdown: prevState.countdown - 1 }));
      }
    }, 1000); // Update every second
  };

  clearCountdownInterval = () => {
    // Clear the countdown interval
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  };
  
  componentDidMount() {
    const token = localStorage.getItem("token");

    axios
      .get(Constants.api + "customer/getcountry?id=", "", {
        headers: {
          "Accept-Language": localStorage.getItem("language"),
          Authorization: `Bearer ${token}`,

          appversion: Constants.av,
          platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
        },
      })
      .then((res) => {
        let countryCode = [];
        console.log(res.data.output);

        // res.data.output.map((country, i) => {
        //   const { countryCode, isdCode } = country;
        //   let obj = { countryCode, isdCode };
        //   console.log(obj);
        // });
        res.data.output.map((country) => {
          countryCode.push(country);
        });
        this.setState({
          countryCode: countryCode,
        });
      });
  }

  handleChange1(checked1) {
    this.setState({ checked1 });
  }
  handleChange2(checked2) {
    this.setState({ checked2 });
  }
  handleChange(checked) {
    this.setState({ checked });
  }
  firstnameHandleChange = (event) => {
    // alert("ahjh");
    this.setState({ firstName: event.target.value });
  };
  lastnameHandleChange = (event) => {
    this.setState({ lastName: event.target.value });
  };
  emailHandleChange = (event) => {
    const email = event.target.value;
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    this.setState({ email: event.target.value, isEmailValid});
  };
  confirmemailHandleChange = (event) => {
    const isConfirmEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event.target.value);
    this.setState({ confirmemail: event.target.value, isConfirmEmailValid });
  };
  numberhandleChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (inputValue.length <= 8 && regex.test(inputValue)) {
      this.setState({ mobile: inputValue });
    }
  };
  passwordhandleChange = (event) => {
    this.setState({ password: event.target.value });
   
    this.registervalidator.showMessageFor('password');

  };
  confirmpasswordhandleChange = (event) => {
    this.setState({ confirmpassword: event.target.value });
  };
  usernameHandleChange = (event) => {
    this.setState({ userName: event.target.value });
  };
  handleGenderChange = (gender) => {
    this.setState({ gender: gender });
  };
  handleOtpChange = (otp) => this.setState({ otp });
  handleEmailOtpChange = (eotp) => this.setState({ eotp });
  handleMobileOtpChange = (motp) => this.setState({ motp });

  handleLoginSubmit = () => {
    this.setState({ loading: true });
    if (this.validator.allValid()) {
      axios
        .post(Constants.api + "customer/login", {
          password: this.state.password,
          userName: this.state.userName,
        })
        .then((res) => {
          console.log(res);
          // swal("you Have succesfully Logged in");

          if (res.data.result == "success") {
            window.scrollTo(0, 0);
            this.setState({ loading: false });
            localStorage.setItem("user_id", res.data.output.userId);

            if (res.data.output.otp_require == "BOTH") {
              localStorage.setItem("reguserid", res.data.output.userId);
              this.setState({
                showLogin: false,
                sendotpshow: true,
                // bothotpshow: true,
                mobileotpshow: false,
                emailotpshow: false,
                showRegister: false,
              });
            }

            if (res.data.output.otp_require == "MOBILE") {
              localStorage.setItem("reguserid", res.data.output.userId);
              this.setState({
                showLogin: false,
                // sendotpshow: true,
                showMobileVerifyOTPOnly:true,
                // mobileotpshow: true,
                showRegister: false,
                emline:res.data.output.em

              });
            }

            if (res.data.output.otp_require == "EMAIL") {
              localStorage.setItem("reguserid", res.data.output.userId);
              this.setState({
                showLogin: false,
              //  emailotpshow: true,
                // sendotpshow: true,
                showEmailVerifyOTPOnly:true,
                emline:res.data.output.em,

                showRegister: false,
              });
            }

            if (res.data.output.otp_require == "NONE") {
              // swal("", "You Have Succesfully Logged In.", "success");
              // swal({
              //   title: "CiNESCAPE",
              //   text: "You Have Succesfully Logged In success",
              // });

              localStorage.setItem("useremail", res.data.output.user.email);
              localStorage.setItem("userid", res.data.output.user.userName);
              localStorage.setItem("mobilePhone", res.data.output.user.mobilePhone);

              localStorage.setItem(
                "userphone",
                res.data.output.user.mobilePhone
              );
              localStorage.setItem("username", res.data.output.user.firstName);

              localStorage.setItem(
                "token",
                res.data.output.user.tokens.access.token
              );
              localStorage.setItem(
                "refreshtoken",
                res.data.output.user.tokens.refresh.token
              );

              localStorage.setItem(
                "tokenexpiry",
                res.data.output.user.tokens.access.expires
              );
              this.setState({ loading: false });
              let testIfJson = JSON.parse(
                localStorage.getItem("seatLayoutJson")
              );

              if (
                localStorage.getItem("seatLayoutJson") != null &&
                typeof testIfJson == "object"
              ) {
                window.location = testIfJson.url;
              } else window.location = "/";
              // window.location.reload(false);
            }
          } else {
            this.setState({ loading: false });

            // this.setState({alert:res.data.msg});
            // swal(res.data.msg);
            swal({
              title: "CiNESCAPE",
              text: " Please enter a valid username and password.",
            });
          }
        });
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  handleForgotSubmit = () => {
    if (this.forgotvalidator.allValid()) {
      axios
        .post(Constants.api + "customer/forgotpassword", {
          password: "",
          userName: this.state.userName,
        })
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            localStorage.setItem("reguserid", res.data.output.userId);
            this.setState({
              showLogin: false,
              showForgot: false,
              otpshow: true,
              // userId: res.data.output.userid,
              showRegister: false,
            });
          } else {
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);
          }
        });
    } else this.forgotvalidator.showMessages();
    this.forceUpdate();
  };
  handleGetOtp = type => {
    localStorage.setItem('verifyType', type)
    this.setState({ otp: '' })
    axios
      .post(Constants.api + "customer/getOtp", {
        password: "",
        userName: this.state.userName,
        "userid": localStorage.getItem('user_id'),
        "motp": "",
        "eotp": "",
        "password": "",
        "verifyType": type
      })
      .then((res) => {
        console.log(res);
        if (res.data.result == "success") {
          this.setState({
            showLogin: false,
            showForgot: false,
            sendotpshow: false,
            // userId: res.data.output.userid,
            showVerifyOTP: true,
            showRegister: false,
          });

          this.startCountdown();
        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });

  };
  handleGetResendOtp = type => {
    localStorage.setItem('verifyType', type)
    this.setState({ otp: '' })
    axios
      .post(Constants.api + "customer/getOtp", {
        password: "",
        userName: this.state.userName,
        "userid": localStorage.getItem('user_id'),
        "motp": "",
        "eotp": "",
        "password": "",
        "verifyType": type
      })
      .then((res) => {
        console.log(res);
        if (res.data.result == "success") {
          // this.setState({
          //   showLogin: false,
          //   showForgot: false,
          //   sendotpshow: false,
          //   // userId: res.data.output.userid,
          //   showVerifyOTP: true,
          //   showRegister: false,
          // });

          this.startCountdown();
        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });
  };
  updatePassword = () => {
    let token = localStorage.getItem("token");
    if (this.resetpassvalidator.allValid()) {
      if (this.state.password == this.state.confirmpassword) {
        axios
          .post(
            Constants.api + "customer/updatepassword",
            {
              eotp: this.state.otp,
              motp: this.state.otp,
              password: this.state.password,
              userid: localStorage.getItem("reguserid"),
            },
            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.data.result == "success") {
              swal("Passwords changed succefully");
              this.setState({
                showLogin: true,
                showForgot: false,
                otpshow: false,
                showRegister: false,
              });
            } else {
              // this.setState({alert:res.data.msg});
              swal(res.data.msg);
            }
          });
      } else swal("Passwords do not match");
    } else this.resetpassvalidator.showMessages();
    this.forceUpdate();
  };
  dobHandleChange = (date) => {
    console.log(date);
    // if (date < fromnow) {
    // let dob = date;
    this.state.dob = moment(date).format("dd-MM-yyyy");
    this.setState({
      dob: date,
    });
    // this.setState({
    //   dob: date,
    // });
    // } else swal("dob incorrect");
  };
  hanldeOtpVerifySubmit = () => {
    let otp = this.state.otp;
    if (otp.length == 6) {
      if (localStorage.getItem('verifyType') == 'MOBILE') {
        this.setState({ motp: otp }, this.mverifymobile)

      }
      else {
        this.setState({ eotp: otp }, this.mverifyemail)

      }
    }
    this.setState({ otp: '' })
  }
  handleUpdateEM = () => {
    if(localStorage.getItem('verifyType') == 'MOBILE')
    {
      if(this.state.email !=this.state.confirmemail)
      {
        swal('Email Ids do not match')
        return;
      }
    }
    else{
      if(this.state.mobile.length==0)
       {  
        swal('Please enter mobile number')
       return;
      }
    }
    axios
        .post(
          Constants.api + "customer/updateEM",
          {
            userid: localStorage.getItem("user_id"),
            em: localStorage.getItem('verifyType') == 'MOBILE' ? this.state.email : this.state.mobile,
            updateType: localStorage.getItem('verifyType') == 'MOBILE' ? 'EMAIL' : 'MOBILE'
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              // Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            // localStorage.setItem("useremail", res.data.output.user.email);
            // localStorage.setItem("userid", res.data.output.user.userName);
            // localStorage.setItem("userphone", res.data.output.user.mobilePhone);
            // localStorage.setItem("username", res.data.output.user.firstName);
            // this.setState({ loading: false });
            // let testIfJson = JSON.parse(localStorage.getItem("seatLayoutJson"));
            // if (
            //   localStorage.getItem("seatLayoutJson") != null &&
            //   typeof testIfJson == "object"
            // ) {
            //   window.location = testIfJson.url;
            // } else window.location = "/";
   
            this.setState({showVerifyOTPOnly:true,showupdateVerifyType:false});
            this.startCountdown();
           
          } else {
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);
          }
        });
  }
  handleUserVerify = () => {
    if (this.state.otp.length == 6) {
      axios
        .post(
          Constants.api + "customer/verifyUser",
          {
            userid: localStorage.getItem("user_id"),

            motp: localStorage.getItem('verifyType') == 'MOBILE' ? '' : this.state.otp,
            eotp: localStorage.getItem('verifyType') == 'MOBILE' ? this.state.otp : '',
            password: '',
            verifyType: localStorage.getItem('verifyType') == 'MOBILE' ? 'EMAIL' : 'MOBILE'
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              // Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            localStorage.setItem("useremail", res.data.output.user.email);
            localStorage.setItem("userid", res.data.output.user.userName);
            localStorage.setItem("userphone", res.data.output.user.mobilePhone);
            localStorage.setItem("username", res.data.output.user.firstName);
               localStorage.setItem(
            "token",
            res.data.output.user.tokens.access.token
          );
            this.setState({ loading: false, afterAccountVerified:true, showVerifyOTPOnly:false, showConfirmVerifyType:false });
            // let testIfJson = JSON.parse(localStorage.getItem("seatLayoutJson"));

            // if (
            //   localStorage.getItem("seatLayoutJson") != null &&
            //   typeof testIfJson == "object"
            // ) {
            //   window.location = testIfJson.url;
            // } else window.location = "/";
          } else {
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);
          }
        });
    }
  };
  handleMUserVerify = () => {
    if (this.state.otp.length == 6) {
      axios
        .post(
          Constants.api + "customer/verifyUser",
          {
            userid: localStorage.getItem("user_id"),

            motp: this.state.otp,
            eotp:  '',
            password: '',
            verifyType: 'MOBILE'
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              // Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            localStorage.setItem("useremail", res.data.output.user.email);
            localStorage.setItem("userid", res.data.output.user.userName);
            localStorage.setItem("userphone", res.data.output.user.mobilePhone);
            localStorage.setItem("username", res.data.output.user.firstName);
            localStorage.setItem(
              "token",
              res.data.output.user.tokens.access.token
            );
            this.setState({ loading: false });
            let testIfJson = JSON.parse(localStorage.getItem("seatLayoutJson"));

            if (
              localStorage.getItem("seatLayoutJson") != null &&
              typeof testIfJson == "object"
            ) {
              window.location = testIfJson.url;
            } else window.location = "/";
          } else {
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);
          }
        });
    }
  };
  handleEUserVerify = () => {
    if (this.state.otp.length == 6) {
      axios
        .post(
          //Constants.api + "customer/verifyUser",
          Constants.api + "customer/mverify",
          {
            userid: localStorage.getItem("user_id"),

            motp: '',
            eotp:  this.state.otp,
            password: '',
            verifyType: 'EMAIL'
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              // Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            localStorage.setItem("useremail", res.data.output.user.email);
            localStorage.setItem("userid", res.data.output.user.userName);
            localStorage.setItem("userphone", res.data.output.user.mobilePhone);
            localStorage.setItem("username", res.data.output.user.firstName);
            localStorage.setItem(
              "token",
              res.data.output.user.tokens.access.token
            );
            this.setState({ loading: false });
            let testIfJson = JSON.parse(localStorage.getItem("seatLayoutJson"));

            if (
              localStorage.getItem("seatLayoutJson") != null &&
              typeof testIfJson == "object"
            ) {
              window.location = testIfJson.url;
            } else window.location = "/";
          } else {
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);
          }
        });
    }
  };

  handleAfterAccountVerified = () => {
    let testIfJson = JSON.parse(localStorage.getItem("seatLayoutJson"));
    if (
      localStorage.getItem("seatLayoutJson") != null &&
      typeof testIfJson == "object"
    ) {
      window.location = testIfJson.url;
    } else window.location = "/";
  };

  mverifyboth = () => {
    let token = localStorage.getItem("token");
    if (this.bothotppassvalidator.allValid()) {
      axios
        .post(
          Constants.api + "customer/mverify",
          {
            eotp: this.state.eotp,
            motp: this.state.motp,

            userid: localStorage.getItem("reguserid"),
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            localStorage.setItem("useremail", res.data.output.user.email);
            localStorage.setItem("userid", res.data.output.user.userName);
            localStorage.setItem("userphone", res.data.output.user.mobilePhone);
            localStorage.setItem("username", res.data.output.user.firstName);
            this.setState({ loading: false });
            let testIfJson = JSON.parse(localStorage.getItem("seatLayoutJson"));

            if (
              localStorage.getItem("seatLayoutJson") != null &&
              typeof testIfJson == "object"
            ) {
              window.location = testIfJson.url;
            } else window.location = "/";
          } else {
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);
          }
        });
    } else this.bothotppassvalidator.showMessages();
    this.forceUpdate();
  };
  mverifyemail = () => {
    //let token = localStorage.getItem("token");
    // if (this.emailotppassvalidator.allValid()) {
    axios
      .post(
        // Constants.api + "customer/mverify",
        Constants.api + "customer/verifyUser",
        {
          eotp: this.state.eotp,
          motp: "",
          verifyType:"EMAIL",
          userid: localStorage.getItem("reguserid"),
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            // Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.result == "success") {
          this.setState({ showConfirmVerifyType: true, showVerifyOTP: false })
          // localStorage.setItem("useremail", res.data.output.user.email);
          // localStorage.setItem("userid", res.data.output.user.userName);
          // localStorage.setItem("userphone", res.data.output.user.mobilePhone);
          // localStorage.setItem("username", res.data.output.user.firstName);
          // localStorage.setItem(
          //   "token",
          //   res.data.output.user.tokens.access.token
          // );
          // this.setState({ loading: false });
          // let testIfJson = JSON.parse(localStorage.getItem("seatLayoutJson"));

          // if (
          //   localStorage.getItem("seatLayoutJson") != null &&
          //   typeof testIfJson == "object"
          // ) {
          //   window.location = testIfJson.url;
          // } else window.location = "/";

          this.startCountdown();
        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });
    // } 
    // else this.emailotppassvalidator.showMessages();
    // this.forceUpdate();
  };
  mverifymobile = () => {
    // let token = localStorage.getItem("token");
    // if (this.mobileotppassvalidator.allValid()) {
    axios
      .post(
        // Constants.api + "customer/mverify",
        Constants.api + "customer/verifyUser",
        {
          eotp: "",
          motp: this.state.motp,
          verifyType:"MOBILE",
          userid: localStorage.getItem("reguserid"),
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            //  Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.result == "success") {
          this.setState({ showConfirmVerifyType: true, showVerifyOTP: false,emline:res.data.output.em})

          // localStorage.setItem("useremail", res.data.output.user.email);
          // localStorage.setItem("userid", res.data.output.user.userName);
          // localStorage.setItem("userphone", res.data.output.user.mobilePhone);
          // localStorage.setItem("username", res.data.output.user.firstName);
          // localStorage.setItem(
          //   "token",
          //   res.data.output.user.tokens.access.token
          // );
          // this.setState({ loading: false });
          // let testIfJson = JSON.parse(localStorage.getItem("seatLayoutJson"));

          // if (
          //   localStorage.getItem("seatLayoutJson") != null &&
          //   typeof testIfJson == "object"
          // ) {
          //   window.location = testIfJson.url;
          // } else window.location = "/";
          this.startCountdown();
        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });
    // } else this.mobileotppassvalidator.showMessages();
    // this.forceUpdate();
  };
  handleSignupSubmit = () => {
    //event.preventDefault();

    if (this.registervalidator.allValid()) {
      if (this.state.password == this.state.confirmpassword) {
        axios
          .post(
            Constants.api + "customer/register",
            {
              countryCode: this.state.cc,
              dob: "",
              email: this.state.email,
              firstName: this.state.firstName,
              gender: this.state.gender,
              lastName: this.state.lastName,
              mobilePhone: this.state.mobile,
              password: this.state.password,
              promoEmail: true,
              promoMobile: true,
              reserveNotification: true,
              userName: this.state.userName,
            },
            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              },
            }
          )
          .then((res) => {
            console.log(res);
            swal(res.data.msg);
            if (res.data.result == "success") {
              window.scrollTo(0, 0);

              if (res.data.output.otp_require == "BOTH") {
                localStorage.setItem("reguserid", res.data.output.userId);

                this.setState({
                  showLogin: false,
                  bothotpshow: true,
                  showRegister: false,
                });
              }
              if (res.data.output.otp_require == "MOBILE") {
                localStorage.setItem("reguserid", res.data.output.userId);

                this.setState({
                  showLogin: false,
                  mobileotpshow: true,
                  showRegister: false,
                });
              }

              if (res.data.output.otp_require == "EMAIL") {
                localStorage.setItem("reguserid", res.data.output.userId);

                this.setState({
                  showLogin: false,
                  emailotpshow: true,
                  showRegister: false,
                });
              }
            } else {
              swal(res.data.msg);
            }
          });
      }
      // } else {
      //   swal("Password and Confirm Password do not match");
      // }
    } else this.registervalidator.showMessages();
    this.forceUpdate();
  };
  ccHandleChange = (cc) => {
    this.setState({ cc: cc.value, ccode: cc });
  };

  
  handleGuestLogin = () => {
    //event.preventDefault();

    if (this.guestValidator.allValid()) {
      axios
        .post(
          Constants.api + "customer/guestlogin",
          {
            countryCode: this.state.cc,
            dob: "",
            email: this.state.email,
            firstName: this.state.firstName,
            gender: "string",
            lastName: "",
            mobilePhone: this.state.mobile,
            password: "",
            promoEmail: true,
            promoMobile: true,
            reserveNotification: true,
            userName: "",
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            },
          }
        )
        .then((res) => {
          console.log(res);

          if (res.data.result == "success") {
            if (res.data.output.otp_require == "true") {
              this.setState({
                showLogin: false,
                otpshow: true,
                showRegister: false,
              });
            } else {
              // swal(res.data.msg);
              localStorage.setItem("useremail", res.data.output.user.email);
              localStorage.setItem("userid", res.data.output.user.userName);
              localStorage.setItem("userphone", res.data.output.user.mobilePhone);
              localStorage.setItem("username", res.data.output.user.firstName);
              localStorage.setItem("guest", "guest");
              localStorage.setItem(
                "token",
                res.data.output.user.tokens.access.token
              );
              localStorage.setItem(
                "refreshtoken",
                res.data.output.user.tokens.refresh.token
              );

              let testIfJson = JSON.parse(
                localStorage.getItem("seatLayoutJson")
              );

              if (
                localStorage.getItem("seatLayoutJson") != null &&
                typeof testIfJson == "object"
              ) {
                window.location = testIfJson.url;
              } else window.location = "/";
              // window.location.reload(false);
            }
          } else {
            swal(res.data.msg);
          }
        });

      // } else {
      //   swal("Password and Confirm Password do not match");
      // }
    } else this.guestValidator.showMessages();
    this.forceUpdate();
  };
  render() {
    const minYear = new Date().getFullYear() - 90; // Set a minimum year (e.g., 5 years ago)
    const maxYear = new Date().getFullYear() - 10; // Set a maximum year (e.g., 5 years from the current year)
    const maxMonth = new Date().getMonth();
    const maxDay = new Date().getDate();
    // const countryOptions = [];
    // this.state.countryCode.map(function (country) {
    //   const { countryCode, isdCode } = country;
    //   let obj = { countryCode, isdCode };
    //   countryOptions.push(obj);
    // });
    console.log(this.state.countryCode);

    let responseFacebook = (response) => {
      console.log("responseFacebook");
      console.log(response);
      this.setState({
        firstName: response.name,
        email: response.email,
        mobile: response.mobile,
      });
    };
    let responseGoogle = (response) => {
      console.log("responseGoogle");
      console.log(response);
      this.setState({
        firstName: response.name,
        email: response.email,
        mobile: response.mobile,
      });
      const token = localStorage.getItem("token");
      localStorage.setItem("useremail",response.email);
      localStorage.setItem("username",response.name);
      localStorage.setItem("mobilePhone",response.mobile);

      localStorage.setItem("socialId",response.googleId);
      localStorage.setItem("socialType", "GOOGLE");

      axios
      .post(Constants.api + "customer/getsocial", {
        "email": response.email,
        "socialId": response.googleId,
        "socialType": "GOOGLE"
      })
        .then((res) => {
          console.log(res);
          this.setState({showGoogle:true,showGoogleInside:true,showLogin:false})
        });
    };
    if (this.state.loading) {
      return <Loading />;
    }
    let options = [];
    this.state.countryCode.map(function (item, i) {
      options.push({
        value: item.isdCode,
        label: (
          <div>
            &nbsp;{item.isdCode}
          </div>
        ),
      });
    });
    console.log(options[0]);
    let defaultValue = options[0];

    const { countdown, visible } = this.state;
    
    return (
      <>
        <div className="login-info">
          <div className="login-banners login">
            {this.state.showRegister ? (
              <>
                <div className="login-box signup-box">
                  {/* <div
                  className="login-overlay"
                  style={{ height: "670px" }}
                ></div> */}
                  <div className="login-up">
                    {localStorage.getItem("language") !== "ar" ? (
                      <h3>SIGN UP</h3>
                    ) : (
                      <h3>
                        {" "}
                        <Text> اشتراك</Text>
                      </h3>
                    )}

                    <div className="new-signup">
                      <div className="signup-flex">
                        {localStorage.getItem("language") !== "ar" ? (
                          <label>Name *</label>
                        ) : (
                          <label>
                            <Text>اسم*</Text>
                          </label>
                        )}
                        <input
                          type="text"
                          name="firstname"
                          value={this.state.firstName}
                          onChange={this.firstnameHandleChange}
                        />

                        {this.registervalidator.message(
                          "first name",
                          this.state.firstName,
                          "required"
                        )}
                      </div>

                      <div className="signup-flex">
                        {localStorage.getItem("language") !== "ar" ? (
                          <label>Last Name *</label>
                        ) : (
                          <label>
                            <Text>اسم العائلة*</Text>
                          </label>
                        )}
                        <input
                          type="text"
                          name="lastname"
                          value={this.state.lastName}
                          onChange={this.lastnameHandleChange}
                        />
                        {this.registervalidator.message(
                          "last name",
                          this.state.lastName,
                          "required"
                        )}
                      </div>
                    </div>

                    <div className="new-signup">
                      <div className="signup-flex">
                        {localStorage.getItem("language") !== "ar" ? (
                          <label>Email *</label>
                        ) : (
                          <label>
                            {" "}
                            <Text>بريد إلكتروني</Text>
                          </label>
                        )}

                        <input
                          type="email"
                          value={this.state.email}
                          onChange={this.emailHandleChange}
                        />
                        {this.registervalidator.message(
                          "email",
                          this.state.email,
                          "required|email"
                        )}
                      </div>

                      <div className="signup-flex">
                        {localStorage.getItem("language") !== "ar" ? (
                          <label>Password *</label>
                        ) : (
                          <label>
                            {" "}
                            <Text>كلمة المرور</Text>
                          </label>
                        )}
                          <input
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.passwordhandleChange}
                          />
                          {this.registervalidator.message('password', this.state.password, 'required|min:8|strongPassword')}

                      </div>
                    </div>

                    <div className="new-signup">
                      <div className="signup-flex">
                        {localStorage.getItem("language") !== "ar" ? (
                          <label>Confirm Password *</label>
                        ) : (
                          <label>
                            <Text>تأكيد كلمة المرور *</Text>
                          </label>
                        )}
                        <input
                          type="password"
                          name="con-password"
                          value={this.state.confirmpassword}
                          onChange={this.confirmpasswordhandleChange}
                        />
                        {this.registervalidator.message(
                          "confirm password",
                          this.state.confirmpassword,
                          "required"
                        )}
                         {this.state.password !== this.state.confirmpassword && (
    <span style={{ color: 'red' }}>Passwords do not match</span>
  )}
                      </div>

                      <div className="signup-flex">
                        {localStorage.getItem("language") !== "ar" ? (
                          <label>Mobile *</label>
                        ) : (
                          <label>
                            <Text>متحرك *</Text>
                          </label>
                        )}
                        <div className="drop-mobile phones">
                          <Select
                            styles={style}
                            options={options}
                            onChange={this.ccHandleChange}
                            value={this.state.ccode}
                            placeholder={" +965"}
                            defaultValue={options[0]}
                          />

                          <input
                            type="text"
                            name="mobile"
                            value={this.state.mobile}
                            onChange={this.numberhandleChange}
                            maxLength={8}
                          // keyfilter={/^\d*$/}
                          />
                        </div>

                        {this.registervalidator.message(
                          "mobile",
                          this.state.mobile,
                          "required|numeric"
                        )}
                      </div>
                    </div>

                    <div className="new-signup">
                      <div className="signup-flex">
                        {localStorage.getItem("language") !== "ar" ? (
                          <label>Date of Birth *</label>
                        ) : (
                          <label>
                            {" "}
                            <Text>بريد إلكتروني</Text>
                          </label>
                        )}
                        <DatePicker
                          selected={this.state.dob}
                          onChange={this.dobHandleChange}
                          // showTimeSelect={false}
                          closeOnSelect={true}
                          closeOnClickOutside={true}
                          timeFormat={false}
                          placeholderText=""
                          shouldCloseOnSelect={true}
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown={true}
                          showYearDropdown={true}
                          minDate={new Date(minYear, 0, 1)}
                          maxDate={new Date(maxYear, maxMonth, maxDay)}
                        />
                      </div>

                      <div className="signup-flex">
                        <div className="signup-male radio-contain">
                          {localStorage.getItem("language") !== "ar" ? (
                            <p>Gender</p>
                          ) : (
                            <label>
                              <Text>جنس </Text>
                            </label>
                          )}
                          <div className="radio-grp">
                            <div className="radio-inline">
                              <label className="radio-container head">
                                <input
                                  type="radio"
                                  name="gender"
                                  onChange={() =>
                                    this.handleGenderChange("Male")
                                  }
                                  checked={
                                    this.state.gender == "Male" ? true : false
                                  }
                                  id=""
                                />
                                <span class="checkmark"></span>
                                Male
                              </label>
                            </div>

                            <div className="radio-inline">
                              <label className="radio-container head">
                                <input
                                  type="radio"
                                  name="gender"
                                  id=""
                                  onChange={() =>
                                    this.handleGenderChange("Female")
                                  }
                                  checked={
                                    this.state.gender == "Female" ? true : false
                                  }
                                />
                                <span class="checkmark checkmark-two"></span>
                                Female
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="switches-container">
                      <div className="new-signup">
                        <div className="signup-flex">
                          <div className="switches-tag">
                            {localStorage.getItem("language") !== "ar" ? (
                              <p>Receive promotional emails</p>
                            ) : (
                              <label>
                                <Text>
                                  تلقي رسائل البريد الإلكتروني الترويجية{" "}
                                </Text>
                              </label>
                            )}

                            <div className="radio-grps">
                              <div className="radio-inline">
                                <label className="radio-container ">
                                  <input
                                    type="radio"
                                    name="promoemail"
                                    onChange={() =>
                                      this.handleChange1(this.state.checked)
                                    }
                                    checked={this.state.checked}
                                    id=""
                                  />
                                  <span class="checkmark"></span>
                                  {localStorage.getItem("language") !== "ar" ? (
                                    "Yes"
                                  ) : (
                                    <Text>نعم</Text>
                                  )}
                                </label>
                              </div>

                              <div className="radio-inline radio-container-two">
                                <label className="radio-container ">
                                  <input
                                    type="radio"
                                    name="promoemail"
                                    onChange={() =>
                                      this.handleChange1(!this.state.checked1)
                                    }
                                    checked={!this.state.checked1}
                                    id=""
                                  />
                                  <span class="checkmark"></span>
                                  {localStorage.getItem("language") !== "ar" ? (
                                    "No"
                                  ) : (
                                    <Text>لا</Text>
                                  )}
                                </label>
                              </div>
                              {/* <div className="radio-gen">
                          <input type="radio" name="gender" />
                          <label>Female</label>
                        </div> */}
                            </div>
                          </div>
                        </div>

                        <div className="signup-flex">
                          <div className="switches-tag">
                            {localStorage.getItem("language") !== "ar" ? (
                              <p>Receive promotional mobile notifications</p>
                            ) : (
                              <label>
                                <Text>تلقي إخطارات المحمول الترويجية</Text>
                              </label>
                            )}

                            <div className="radio-grps">
                              {/* <div className="radio-gen">
                          <input type="radio" name="gender" />
                          <label>Male</label>
                        </div> */}

                              <div className="radio-inline">
                                <label className="radio-container ">
                                  <input
                                    type="radio"
                                    name="gender1"
                                    id=""
                                    onChange={() =>
                                      this.handleChange2(this.state.checked)
                                    }
                                    className="radio-yes"
                                    checked={this.state.checked}
                                  />
                                  <span class="checkmark"></span>
                                  {localStorage.getItem("language") !== "ar" ? (
                                    "Yes"
                                  ) : (
                                    <Text>نعم</Text>
                                  )}
                                </label>
                              </div>

                              <div className="radio-inline radio-container-two">
                                <label className="radio-container ">
                                  <input
                                    type="radio"
                                    name="gender1"
                                    onChange={() =>
                                      this.handleChange2(!this.state.checked2)
                                    }
                                    className="radio-yes"
                                    checked={!this.state.checked2}
                                    id=""
                                  />
                                  <span class="checkmark"></span>
                                  {localStorage.getItem("language") !== "ar" ? (
                                    "No"
                                  ) : (
                                    <Text>لا</Text>
                                  )}
                                </label>
                              </div>



                              {/* <div className="radio-gen">
                          <input type="radio" name="gender" />
                          <label>Female</label>
                        </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <p className="reg-info">
                        {localStorage.getItem("language") !== "ar" ? (
                          <>
                            We recommend to keep the notifications on to receive
                            your reservation notifications and <br></br>
                            reminders.
                          </>
                        ) : (
                          <Text>
                            نوصي بالاحتفاظ بالإشعارات لتلقي إشعارات الحجز
                            والتذكيرات.
                          </Text>
                        )}
                      </p>
                    </div>
                    <div className="signup-contain">
                      {/* <p>
                        By signing up I agree to the{" "}
                        <a href="/information/tc">terms & conditions</a>
                      </p> */}
                      {/* <button
                        className="btnc btnc primary"
                        onClick={this.handleSignupSubmit}
                      >
                        Sign up
                      </button> */}
                      <div className="save-cancel-container new-btn-login">
                        {/* <button
                          className="btnc btnc-gray"
                          onClick={() => this.setState({
                            showRegister: false, showLogin: false,
                            showForgot: false,
                          })}
                        >
                          {localStorage.getItem("language") !== "ar" ? (
                            "Cancel"
                          ) : (
                            <Text>يلغي</Text>
                          )}
                        </button> */}
                        <button
                          className="btnc btnc-primary"
                          onClick={this.handleSignupSubmit}
                        >
                          {localStorage.getItem("language") !== "ar" ? (
                            "Save"
                          ) : (
                            <Text>يحفظ</Text>
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="login-bottom login-new-btn">
                      <p>
                        {localStorage.getItem("language") !== "ar" ? (
                          "    Have an Account?"
                        ) : (
                          <Text>هل لديك حساب؟</Text>
                        )}
                        <a
                          href="#"
                          onClick={() =>
                            this.setState({
                              showRegister: false,
                              showLogin: true,
                            })
                          }
                        >
                          <b>
                            {" "}
                            {localStorage.getItem("language") !== "ar" ? (
                              "Sign in"
                            ) : (
                              <Text> تسجيل الدخول</Text>
                            )}
                          </b>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {this.state.sendotpshow ? (
              <>
                <div className="login-box  ">
                  {/* <div className="login-overlay"></div> */}

                  <div className="login-up">
                    {/*.................................. verify-user.................................................*/}
                    <div className="verify-user ">
                      <h3>VERIFY USER</h3>
                      <p>
                        Verify your     {this.state.emailotpshow ? ' Email' : ''}   {this.state.emailotpshow && this.state.mobileotpshow ? ' and' : ''}   {this.state.mobileotpshow ? ' Mobile number' : ''} to make
                        your future login faster.
                      </p>

                      <div className="send-sms">
                        
                        {/* {this.state.mobileotpshow ?
                        
                          <button type="button" className="btn-sms-otp" onClick={() => this.handleGetOtp("MOBILE")}>
                            <img src={require("../assets/Phone Icon.png").default} />
                            
                            Send SMS OTP
                          </button> : ''} */}

                          <button type="button" className="btn-sms-otp" onClick={() => this.handleGetOtp("MOBILE")}>
                            <img src={require("../assets/Phone Icon.png").default} />
                            
                            Send SMS OTP
                          </button>

                        {/* {this.state.emailotpshow ?
                          <button type="button" className="btn-sms-otp" onClick={() => this.handleGetOtp("EMAIL")}>
                             <img src={require("../assets/Mail Icon.png").default} />
                            Send Email OTP
                          </button> : ''} */}


                          <button type="button" className="btn-sms-otp" onClick={() => this.handleGetOtp("EMAIL")}>
                             <img src={require("../assets/Mail Icon.png").default} />
                            Send Email OTP
                          </button>
                      </div>
                    </div>
                    {/*.................................. verify-user.................................................*/}



                    {/* ---------------verify email otp -------------------------------------------------------------------*/}

                    <div className="verify-user login-hide">
                      <h3>CONFIRM EMAIL</h3>
                      <p>Please confirm or update your email address</p>
                      <div className="otp-profile">
                        
                          <div className="otp-email mt-60">
                            <p>An OTP is sent to ******an@gmail.com</p>
                          </div>
                          
                          <div class="otp_login bg-otp">
                          <OtpInput
                            value={this.state.otp}
                            class="otp_login"
                            onChange={this.handleOtpChange}
                            numInputs={6}
                            separator={<span>&nbsp;</span>}
                            inputmode="numeric"

                          />
                          {this.resetpassvalidator.message(
                            "OTP",
                            this.state.otp,
                            "required"
                          )}
                        </div>

                        <div className="remaning-time">
                          <p>
                            Remaning time<span className="time">6:35</span>
                          </p>
                        </div>

                        <div className="btn-submit">
                          <div class="submit-btn-container">
                            <button class="btnc btnc-primary clear-btn">
                              Clear
                            </button>
                            <button class="btnc btnc-primary"> Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* -------------------------verify email otp-------------------------------------------------------- */}

                    {/* --------------------------account verified -------------------------------------------------------------------*/}
                    
                    {/* ------------------------------account verified-------------------------------------------------------- */}

                    {/*.................................. verify-email-otp.................................................*/}

                    <div className="verify-user login-hide">
                      <h3>VERIFY SMS OTP</h3>
                      <p>Please enter OTP sent to your registered mobile.</p>

                      <div className="otp-profile">
                        <div class="otp_login">
                          <div className="otp-email">
                            <p>An OTP is sent to ******an@gmail.com</p>
                          </div>
                       <div className="bg-otp">
                          <OtpInput
                            value={this.state.otp}
                            class="otp_login"
                            onChange={this.handleOtpChange}
                            numInputs={6}
                            separator={<span>&nbsp;</span>}
                            inputmode="numeric"

                          />
                          {this.resetpassvalidator.message(
                            "OTP",
                            this.state.otp,
                            "required"
                          )}
                        </div>
                        </div>

                        <div className="remaning-time">
                          <p>
                            Remaning time<span className="time">6:35</span>
                          </p>
                        </div>

                        <div className="btn-submit mb-40">
                          <div class="submit-btn-container">
                            <button class="btnc btnc-primary clear-btn">
                              Clear
                            </button>
                            <button class="btnc btnc-primary"> Submit</button>
                          </div>
                        </div>

                        <div className="dont-receive">
                          {/* <p>
                            Didn’t receive?{" "}
                            <span className="white-text text-send">Send again</span>
                          </p> */}
                          <p>
                            Forgot registered email?{" "}
                            <span className="white-text">Verify by SMS.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*.................................. verify-email-otp.................................................*/}

                



                    {/* <h3>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Sign in"
                      ) : (
                        <Text> تسجيل الدخول</Text>
                      )}
                    </h3>
                    <div className="login-flex">
                      <div class="login-sec">
                        <div className="login-input-box">
                          <label>
                            {localStorage.getItem("language") !== "ar" ? (
                              "Email*"
                            ) : (
                              <Text>البريد الإلكتروني / اسم المستخدم</Text>
                            )}
                          </label>
                          <input
                            type="text"
                            name="email"
                            className="spaces"
                            value={this.state.userName}
                            onChange={this.usernameHandleChange}
                          />
                          {this.validator.message(
                            "User Name",
                            this.state.userName,
                            "required"
                          )}

                          {localStorage.getItem("language") !== "ar" ? (
                            <label>Password *</label>
                          ) : (
                            <label>
                              {" "}
                              <Text>كلمة المرور</Text>
                            </label>
                          )}
                          <input
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.passwordhandleChange}
                          />
                          {this.validator.message(
                            "Password",
                            this.state.password,
                            "required"
                          )}
                          <div
                            className="forget-password"

                          >
                            <a href="#" onClick={() =>
                              this.setState({
                                showForgot: true,
                                showLogin: false,
                              })
                            }>
                              {" "}
                              {localStorage.getItem("language") !== "ar" ? (
                                "Forgot Password?"
                              ) : (
                                <Text>هل نسيت كلمة المرور ؟</Text>
                              )}
                            </a>
                          </div>
                        </div>
                        <div class="mix-btn">
                          <div className="submit-btn-container">
                            <button
                              onClick={this.handleLoginSubmit}
                              className="btnc btnc-primary"
                            >
                              {localStorage.getItem("language") !== "ar" ? (
                                "Sign in"
                              ) : (
                                <Text> تسجيل الدخول</Text>
                              )}
                            </button>

                            

                            

                            
                          </div>
                          <div className="hidden-desk">
                          <div class="guest-continue gust-as-continue">Continue as &nbsp;<b>Guest</b></div>
                          </div>
                          {(
                            localStorage.getItem("seatLayoutJson") != null &&
                            typeof testIfJson == "object"
                          ) ?
                            <div
                              className="guest-continue gust-as-continue"
                              onClick={() =>
                                this.setState({
                                  showGuest: true,
                                  showLogin: false,
                                })
                              }
                            >
                              {localStorage.getItem("language") !== "ar" ? (
                                <>
                                  Continue as &nbsp;
                                  <b>Guest</b>
                                </>
                              ) : (
                                <Text>متابعة كـ ضيف</Text>
                              )}
                            </div>
                            : ''}
                        </div>
                      </div>
                    </div>
                    <div className="login-bottom">
                      <p>
                        {localStorage.getItem("language") !== "ar" ? (
                          " Not Registered? "
                        ) : (
                          <Text>لديك بطاقة عضوية ؟ تفعيل</Text>
                        )}
                        <a
                          href="#"
                          onClick={() =>
                            this.setState({
                              showRegister: true,
                              showLogin: false,
                              showForgot: false,
                            })
                          }
                        >
                          {localStorage.getItem("language") !== "ar" ? (
                            <b>SIGN UP</b>
                          ) : (
                            <b>
                              {" "}
                              <Text> اشتراك</Text>
                            </b>
                          )}
                        </a>
                      </p>

                    </div> */}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
              {this.state.showGoogle ? (
              <>
                <div className="login-box  ">
                  {/* <div className="login-overlay"></div> */}
             
                  <div className="login-up">
                    {/*.................................. verify-user.................................................*/}
                    {this.state.showGoogleInside ? (
                       <div className="verify-user ">
                      <h3>Google Login</h3><br/>
                    {localStorage.getItem("username")}<br/>
                    {localStorage.getItem("useremail")}<br/>
                    { localStorage.getItem("mobilePhone")?.slice(-2)}<br/>
                      <div className="send-sms">
                        
                        {/* {this.state.mobileotpshow ?
                        
                          <button type="button" className="btn-sms-otp" onClick={() => this.handleGetOtp("MOBILE")}>
                            <img src={require("../assets/Phone Icon.png").default} />
                            
                            Send SMS OTP
                          </button> : ''} */}

<p>
{localStorage.getItem("language") !== "ar" ? (
                                " We have found a Cinescape account with your email address. Would you like to link the account?"
                              ) : (
                                <Text>لقد عثرنا على حساب Cinescape بعنوان بريدك الإلكتروني.<br/>هل ترغب في ربط الحساب؟</Text>
                              )}
                      </p>
                      <div className="btn-submit">
                        <div class="submit-btn-container">
                          <button class="btnc btnc-primary clear-btn" onClick={()=>this.setState({showSwal:true,showGoogleInside:false})}>
                            No
                          </button>
                          <button class="btnc btnc-primary" onClick={()=>this.setState({showLoginwihtoutUserid:true,showGoogle:false,showSwal:false,showGoogleInside:false})}> Yes</button>
                        </div>
                      </div>
                        {/* {this.state.emailotpshow ?
                          <button type="button" className="btn-sms-otp" onClick={() => this.handleGetOtp("EMAIL")}>
                             <img src={require("../assets/Mail Icon.png").default} />
                            Send Email OTP
                          </button> : ''} */}


                          {/* <button type="button" className="btn-sms-otp" onClick={() => this.handleGetOtp("EMAIL")}>
                             <img src={require("../assets/Mail Icon.png").default} />
                            Send Email OTP
                          </button> */}
                      </div>
                    </div>):''
                   }
                   {this.state.showSwal ? (
                    <div className="verify-user ">
                          <div className="send-sms">
                            
                            {/* {this.state.mobileotpshow ?
                            
                              <button type="button" className="btn-sms-otp" onClick={() => this.handleGetOtp("MOBILE")}>
                                <img src={require("../assets/Phone Icon.png").default} />
                                
                                Send SMS OTP
                              </button> : ''} */}

            <p>
            {localStorage.getItem("language") !== "ar" ? (
                                    " Kindly Login to Cinescape account to continue."
                                  ) : (
                                    <Text>يرجى تسجيل الدخول إلى حساب سينيسكيب للمتابعة.</Text>
                                  )}
                          </p>
                          <div className="btn-submit">
                            <div class="submit-btn-container">
                              <button class="btnc btnc-primary clear-btn" onClick={()=>this.setState({showGoogleInside:true,showSwal:false})}>
                                No
                              </button>
                              <button class="btnc btnc-primary" onClick={()=>this.setState({showLoginwihtoutUserid:false,showGoogleInside:false,showSwal:false,showGoogle:false,showLogin:true})}> Yes</button>
                            </div>
                          </div>
                            {/* {this.state.emailotpshow ?
                              <button type="button" className="btn-sms-otp" onClick={() => this.handleGetOtp("EMAIL")}>
                                <img src={require("../assets/Mail Icon.png").default} />
                                Send Email OTP
                              </button> : ''} */}


                              {/* <button type="button" className="btn-sms-otp" onClick={() => this.handleGetOtp("EMAIL")}>
                                <img src={require("../assets/Mail Icon.png").default} />
                                Send Email OTP
                              </button> */}
                          </div>
                    </div>
                   ):''}
                    {/*.................................. verify-user.................................................*/}

                 
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {this.state.showVerifyOTP ?
              <div className="login-box  ">
                {/* <div className="login-overlay"></div> */}

                <div className="login-up">
                  <div className="verify-user ">
                    <h3>VERIFY {localStorage.getItem('verifyType') == 'MOBILE' ? 'SMS' : 'EMAIL'} OTP</h3>
                    <p>Please enter OTP sent to your registered {localStorage.getItem('verifyType') == 'MOBILE' ? 'mobile' : 'email'}.</p>
                    <div className="otp-profile">
                      <div class="otp_login bg-otp">
                        <OtpInput
                          value={this.state.otp}
                          class="otp_login"
                          onChange={this.handleOtpChange}
                          numInputs={6}
                          separator={<span>&nbsp;</span>}
                          inputmode="numeric"
                          type="number"

                        />
                        {this.resetpassvalidator.message(
                          "OTP",
                          this.state.otp,
                          "required"
                        )}
                      </div>

                      <div className="remaning-time">
                        {/* <p>
                          Remaning time<span className="time">6:35</span>
                        </p> */}

                          <p>
                            {countdown === 0 ? (
                              <>
                              Didn’t receive?
                              <span className="white-text text-send" style={{ cursor: "pointer" }} onClick={() => this.handleGetResendOtp(localStorage.getItem('verifyType'))}>Send again</span>
                              </>
                            ) : (
                              <p>Remaning time {countdown} seconds</p>
                            )}
                          </p>
                      </div>

                      <div className="btn-submit">
                        <div class="submit-btn-container">
                          <button class="btnc btnc-primary clear-btn" onClick={() => this.setState({ otp: '' })}>
                            Clear
                          </button>
                          <button class="btnc btnc-primary" onClick={this.hanldeOtpVerifySubmit}> Submit</button>
                        </div>
                      </div>

                      <div className="dont-receive">
                         
                        {/* {visible && (
                          
                        )} */}

                        <p>
                          Forgot registered {localStorage.getItem('verifyType') == 'MOBILE' ? 'Mobile number' : 'EMAIL'}?{" "}
                          <span className="white-text" style={{ cursor: "pointer" }} onClick={() => this.handleGetOtp(localStorage.getItem('verifyType') == 'MOBILE' ? 'EMAIL' : 'MOBILE')}>Verify by&nbsp;{localStorage.getItem('verifyType') == 'MOBILE' ?    'email' :'sms'}.</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : ''}
                 {this.state.showVerifyOTPOnly ?
              <div className="login-box  ">
                {/* <div className="login-overlay"></div> */}

                <div className="login-up">
                  <div className="verify-user ">
                    <h3>VERIFY {localStorage.getItem('verifyType') == 'MOBILE' ? 'EMAIL' : 'SMS'} OTP</h3>
                    <p>Please enter OTP sent to your registered {localStorage.getItem('verifyType') == 'MOBILE' ? 'email' : 'mobile'}.</p>
                    <div className="otp-profile">
                      <div class="otp_login bg-otp">
                        <OtpInput
                          value={this.state.otp}
                          class="otp_login"
                          onChange={this.handleOtpChange}
                          numInputs={6}
                          separator={<span>&nbsp;</span>}
                          inputmode="numeric"
                          type="number"

                        />
                        {this.resetpassvalidator.message(
                          "OTP",
                          this.state.otp,
                          "required"
                        )}
                      </div>

                      {/* <div className="remaning-time">
                                      <p>
                                        Remaning time<span className="time">6:35</span>
                                      </p>
                                    </div> */}

                      <div className="remaning-time">
                        {/* <p>
                          Remaning time<span className="time">6:35</span>
                        </p> */}

                        <p>
                          {countdown === 0 ? (
                            <>
                            Didn’t receive?
                            <span className="white-text text-send" style={{ cursor: "pointer" }} onClick={() => this.handleGetResendOtp(localStorage.getItem('verifyType'))}>Send again</span>
                            </>
                          ) : (
                            <p>Remaning time {countdown} seconds</p>
                          )}
                        </p>
                      </div>

                      <div className="btn-submit">
                        <div class="submit-btn-container">
                          <button class="btnc btnc-primary clear-btn" onClick={() => this.setState({ otp: '' })}>
                            Clear
                          </button>
                          <button class="btnc btnc-primary" onClick={this.handleUserVerify}> Submit</button>
                        </div>
                      </div>

                   
                    </div>
                  </div>
                </div>
              </div>
              : ''}
                 {this.state.showMobileVerifyOTPOnly ?
              <div className="login-box  ">
                {/* <div className="login-overlay"></div> */}

                <div className="login-up">
                  <div className="verify-user ">
                    <h3>VERIFY SMS OTP</h3>
                    <p>Please enter OTP sent to your registered mobile.</p>
                    <div className="otp-profile">
                    
                      <div className="otp-email">
                            <p>An OTP is sent to {this.state.emline}</p>
                          </div>
                          <div class="otp_login bg-otp">
                        <OtpInput
                          value={this.state.otp}
                          class="otp_login"
                          onChange={this.handleOtpChange}
                          numInputs={6}
                          separator={<span>&nbsp;</span>}
                          inputmode="numeric"
                          type="number"

                        />
                        {this.resetpassvalidator.message(
                          "OTP",
                          this.state.otp,
                          "required"
                        )}
                      </div>

                      {/* <div className="remaning-time">
                        <p>
                          Remaning time<span className="time">6:35</span>
                        </p>
                      </div> */}

                      <div className="btn-submit">
                        <div class="submit-btn-container">
                          <button class="btnc btnc-primary clear-btn" onClick={() => this.setState({ otp: '' })}>
                            Clear
                          </button>
                          <button class="btnc btnc-primary" onClick={this.handleMUserVerify}> Submit</button>
                        </div>
                      </div>

                   
                    </div>
                  </div>
                </div>
              </div>
              : ''}
                    {this.state.showEmailVerifyOTPOnly ?
              <div className="login-box  ">
                {/* <div className="login-overlay"></div> */}

                <div className="login-up">
                  <div className="verify-user ">
                    <h3>VERIFY Email OTP</h3>
                    <p>Please enter OTP sent to your registered email.</p>
                    <div className="otp-profile">
                      <div class="otp_login">
                      <div className="otp-email">
                            <p>{this.state.emline}</p>
                          </div>
                          <div className="otp-login bg-otp">
                        <OtpInput
                          value={this.state.otp}
                          class="otp_login"
                          onChange={this.handleOtpChange}
                          numInputs={6}
                          separator={<span>&nbsp;</span>}
                          inputmode="numeric"
                          type="number"
                          isInputNum={true}

                        />
                        {this.resetpassvalidator.message(
                          "OTP",
                          this.state.otp,
                          "required"
                        )}
                        </div>
                      </div>

                      {/* <div className="remaning-time">
                                      <p>
                                        Remaning time<span className="time">6:35</span>
                                      </p>
                                    </div> */}

                      <div className="btn-submit">
                        <div class="submit-btn-container">
                          <button class="btnc btnc-primary clear-btn" onClick={() => this.setState({ otp: '' })}>
                            Clear
                          </button>
                          <button class="btnc btnc-primary" onClick={this.handleEUserVerify}> Submit</button>
                        </div>
                      </div>

                   
                    </div>
                  </div>
                </div>
              </div>
              : ''}

            {this.state.showConfirmVerifyType ?
              <>
                <div className="login-box  ">
                  {/* <div className="login-overlay"></div> */}

                  <div className="login-up">
                    {/* ---------------confirm email -------------------------------------------------------------------*/}

                    <div className="verify-user">
                      <h3>CONFIRM  {localStorage.getItem('verifyType') == 'MOBILE' ? 'EMAIL' : 'Mobile number'}</h3>
                      <p>Please confirm or update your {localStorage.getItem('verifyType') == 'MOBILE' ? 'email address' : 'mobile number'}</p>
                      <div className="otp-profile">
                        <div class="otp_login">
                          <div className="confirm-email">
                            <p>
                              <span className="white-text-email">
                                {" "}
                                Confirm  {localStorage.getItem('verifyType') == 'MOBILE' ? 'Email' : 'Mobile number'}
                              </span>
                              <span onClick={() => this.setState({ 'showupdateVerifyType': true, showConfirmVerifyType: false,  })}>
                                Update {localStorage.getItem('verifyType') == 'MOBILE' ? 'Email' : 'Mobile number'}</span>
                            </p>
                          </div>
                          <div className="otp-email">
                          <p>An OTP is sent to {this.state.emline}</p>

                            {/* <p>An OTP is sent to ******an@gmail.com</p> */}
                          </div>

                          
 <div className="otp-login bg-otp">

                          <OtpInput
                            value={this.state.otp}
                            class="otp_login"
                            onChange={this.handleOtpChange}
                            numInputs={6}
                            separator={<span>&nbsp;</span>}
                            inputmode="numeric"

                          />

                        </div>
                        </div>
                        {/* 
                        <div className="remaning-time">
                          <p>
                            Remaning time<span className="time">6:35</span>
                          </p>
                        </div> */}

                        
                        <div className="remaning-time">
                          <p>
                            {countdown === 0 ? (
                              <>
                              Didn’t receive?
                              <span className="white-text text-send" style={{ cursor: "pointer" }} onClick={() => this.handleGetResendOtp(localStorage.getItem('verifyType'))}>Send again</span>
                              </>
                            ) : (
                              <p>Remaning time {countdown} seconds</p>
                            )}
                          </p>
                        </div>

                        <div className="btn-submit">
                          <div class="submit-btn-container">
                            <button class="btnc btnc-primary clear-btn" onClick={() => this.setState({ otp: '' })}>
                              Clear
                            </button>
                            <button class="btnc btnc-primary" onClick={this.handleUserVerify}> Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* -------------------------confirm email-------------------------------------------------------- */}

                    {/* ---------------update email -------------------------------------------------------------------*/}
                  

                    {/* ------------------------- update email-------------------------------------------------------- */}
                  </div>
                </div>

              </> : ''}
              {this.state.showupdateVerifyType ?
                   <div className="login-box  ">
                   {/* <div className="login-overlay"></div> */}
 
                   <div className="login-up update-email">
                      <div className="verify-user ">
                        <h3>UPDATE {localStorage.getItem('verifyType') == 'MOBILE' ? 'EMAIL' : 'Mobile number'}</h3>
                        <p>Please confirm or update your {localStorage.getItem('verifyType') == 'MOBILE' ? 'email address' : 'mobile number'}</p>
                        <div className="otp-profile">
                          <div class="otp_login">
                            <div className="confirm-email">
                              <p>
                                {" "}
                                <span onClick={() => this.setState({ 'showupdateVerifyType': false, showConfirmVerifyType: true })}>
                                  Confirm {localStorage.getItem('verifyType') == 'MOBILE' ? 'Email' : 'Mobile number'}</span>
                                <span className="white-text-email" >
                                  Update  {localStorage.getItem('verifyType') == 'MOBILE' ? 'Email' : 'Mobile number'}
                                </span>
                              </p>
                            </div>
                            {localStorage.getItem('verifyType') == 'MOBILE' ?
                              <div className="email-id-login">
                                <div class="profile-input">
                                  <input
                                    type="text"
                                    placeholder="Email Id"
                                    value={this.state.email}
                                    onChange={this.emailHandleChange}
                                  ></input>
                                  
                                </div>
                                {!this.state.isEmailValid && <span className="error-message">Please enter valid email</span>}
                                <div class="profile-input">
                                  <input
                                    type="text"
                                    placeholder="Confirm Email ID"
                                    value={this.state.confirmemail}
                                    onChange={this.confirmemailHandleChange}
                                  ></input>
                                  
                                </div>
                                {!this.state.isConfirmEmailValid && <span className="error-message">Please enter valid confirm email</span>}
                              </div> : <div className="email-id-login">
                                <div className="country-code country-code-login">
                                  <div className="drop-mobile">
                                    <Select
                                      styles={style}
                                      options={options}
                                      onChange={this.ccHandleChange}
                                      value={this.state.ccode}
                                      placeholder={"+965"}
                                      defaultValue={options[0]}
                                    />

                                    <input
                                      type="number"
                                      name="mobile"
                                      value={this.state.mobile}
                                      onChange={this.numberhandleChange}
                                    />
                                  </div>
                                </div>
                              </div>}
                          </div>
                          <div className="btn-submit">
                            <div class="submit-btn-container">
                              <button class="btnc btnc-primary" disabled={!this.state.isEmailValid || !this.state.isConfirmEmailValid} onClick={this.handleUpdateEM}> Submit</button>
                            </div>
                          </div>
                        </div>
                      </div> 
                      </div>
                      </div>
                      : ''}

            
            {this.state.showLogin ? (
              <>
                <div className="login-box  ">
                  {/* <div className="login-overlay"></div> */}

                  <div className="login-up">

                    <h3>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Sign in"
                      ) : (
                        <Text> تسجيل الدخول</Text>
                      )}
                    </h3>
                    <div className="login-flex">
                      <div class="login-sec">
                        <div className="login-input-box">
                          <label>
                            {localStorage.getItem("language") !== "ar" ? (
                              "Email*"
                            ) : (
                              <Text>البريد الإلكتروني / اسم المستخدم</Text>
                            )}
                          </label>
                          <input
                            type="text"
                            name="email"
                            className="spaces"
                            value={this.state.userName}
                            onChange={this.usernameHandleChange}
                          />
                          {this.validator.message(
                            "User Name",
                            this.state.userName,
                            "required"
                          )}

                          {localStorage.getItem("language") !== "ar" ? (
                            <label>Password *</label>
                          ) : (
                            <label>
                              {" "}
                              <Text>كلمة المرور</Text>
                            </label>
                          )}
                          <input
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.passwordhandleChange}
                          />
                          {this.validator.message(
                            "Password",
                            this.state.password,
                            "required"
                          )}
                         
                         <div className="gust-info-section">
                         <div className="gust-info">
                          <p>Continue as &nbsp;
                              <b>Guest</b></p>
                         </div>
                           
                          <div
                            className="forget-password"

                          >
                            <a href="#" onClick={() =>
                              this.setState({
                                showForgot: true,
                                showLogin: false,
                              })
                            }>
                              {" "}
                              {localStorage.getItem("language") !== "ar" ? (
                                "Forgot Password?"
                              ) : (
                                <Text>هل نسيت كلمة المرور ؟</Text>
                              )}
                            </a>
                          </div>
                          </div>
                        </div>
                        <div class="mix-btn">
                          <div className="submit-btn-container">
                            <button
                              onClick={this.handleLoginSubmit}
                              className="btnc btnc-primary"
                            >
                              {localStorage.getItem("language") !== "ar" ? (
                                "Sign in"
                              ) : (
                                <Text> تسجيل الدخول</Text>
                              )}
                            </button>






                          </div>
                          {(
                            localStorage.getItem("seatLayoutJson") != null
                           
                          ) ?
                          <div className="hidden-desk d-none">
                            <div class="guest-continue gust-as-continue">Continue as &nbsp;<b>Guest</b></div>
                          </div>:''}
                          {(
                            localStorage.getItem("seatLayoutJson") != null
                           
                          ) ?
                            <div
                              className="guest-continue gust-as-continue"
                              onClick={() =>
                                this.setState({
                                  showGuest: true,
                                  showLogin: false,
                                })
                              }
                            >
                              {localStorage.getItem("language") !== "ar" ? (
                                <>
                                  Continue as &nbsp;
                                  <b>Guest</b>
                                </>
                              ) : (
                                <Text>متابعة كـ ضيف</Text>
                              )}
                            </div>
                            : ''}
                        </div>
                      </div>
                    </div>
                    <div className="login-bottom">
                      <p>
                        {localStorage.getItem("language") !== "ar" ? (
                          " Not Registered? "
                        ) : (
                          <Text>لديك بطاقة عضوية ؟ تفعيل</Text>
                        )}
                        <a
                          href="#"
                          onClick={() =>
                            this.setState({
                              showRegister: true,
                              showLogin: false,
                              showForgot: false,
                            })
                          }
                        >
                          {localStorage.getItem("language") !== "ar" ? (
                            <b>SIGN UP</b>
                          ) : (
                            <b>
                              {" "}
                              <Text> اشتراك</Text>
                            </b>
                          )}
                        </a>
                      </p>
                      <div className="login-through">
                      {/* <FacebookLogin
                        appId="525724582401182"
                        callback={responseFacebook}
                        render={(renderProps) => (
                          <a
                            href="#"
                            onClick={() =>
                              this.setState(
                                {
                                  showGuest: true,
                                  showLogin: false,
                                  showRegister: false,
                                  showForgot: false,
                                },
                                renderProps.onClick
                              )
                            }
                            disabled={renderProps.disabled}
                          >
                            <img
                              src={
                                require("../assets/Icon awesome-facebook-f.png")
                                  .default
                              }
                            />
                          </a>
                        )}
                      /> */}
                    {/* <GoogleLogin
                        clientId="728594967582-s7scrd69psa6r35ucb5oo12a519t3tru.apps.googleusercontent.com"
                        render={(renderProps) => (
                          <a
                            href="#"
                            onClick={() =>
                              this.setState(
                                {
                                  showGoogle: true,
                                  showGoogleInside: true,
                                  showLogin: false,
                                  showRegister: false,
                                  showForgot: false,
                                },
                                renderProps.onClick
                              )
                            }
                          >
                            <img
                              src={
                                require("../assets/Icon awesome-google.png")
                                  .default
                              }
                            />
                          </a>
                        )}
                        buttonText="Login"
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      />  */}

                      {/* {<a href="#">
                        <img
                          src={
                            require("../assets/Icon awesome-apple.png").default
                          }
                        />
                      </a>} */}
                    </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
              {this.state.showLoginwihtoutUserid ? (
              <>
                <div className="login-box  ">
                  {/* <div className="login-overlay"></div> */}

                  <div className="login-up">

                    <h3>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Enter Cinescape Password"
                      ) : (
                        <Text> أدخل كلمة المرور الخاصة بسينسكيب</Text>
                      )}
                    </h3><br/>
                    {localStorage.getItem("username")}<br/>
                    {localStorage.getItem("useremail")}<br/>
                    { localStorage.getItem("mobilePhone")?.slice(-2)}<br/>
                    <div className="login-flex">
                      <div class="login-sec">
                        <div className="login-input-box">
                       
                          <input
                            type="hidden"
                            name="email"
                            className="spaces"
                            value=  {localStorage.getItem("useremail")}
            
                          />
                         

                          {localStorage.getItem("language") !== "ar" ? (
                            <label>Password *</label>
                          ) : (
                            <label>
                              {" "}
                              <Text>كلمة المرور</Text>
                            </label>
                          )}
                          <input
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.passwordhandleChange}
                          />
                          {this.validator.message(
                            "Password",
                            this.state.password,
                            "required|min:8|strongPassword"
                          )}
                         
                    
                        </div>
                        <div class="mix-btn">
                          <div className="submit-btn-container">
                            <button
                              onClick={this.handleLoginSubmit}
                              className="btnc btnc-primary"
                            >
                              {localStorage.getItem("language") !== "ar" ? (
                                "Continue"
                              ) : (
                                <Text> تسجيل الدخول</Text>
                              )}
                            </button>






                          </div>
                      
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {this.state.showForgot && !this.state.showGuest ? (
              <>
                <div className="login-box">
                  {/* <div className="login-overlay"></div> */}
                  <div className="login-up forgot-username gust-login">
                    {/* <h3>FORGOT USERNAME <br></br> OR PASSWORD?</h3> */}

                    <h3>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Forgot Password"
                      ) : (
                        <Text>   تسجيل الدخول</Text>
                      )}
                    </h3>
                    <div className="login-flex">
                      <div className="login-input-box">
                        <label>
                          {localStorage.getItem("language") !== "ar" ? (
                            "Email *"
                          ) : (
                            <Text> اسم المستخدم / البريد الإلكتروني *</Text>
                          )}
                        </label>
                        {/* <label>Username / Email *</label> */}
                        <input
                          type="text"
                          name="userName"
                          value={this.state.userName}
                          onChange={this.usernameHandleChange}
                        />
                        {this.forgotvalidator.message(
                          "Email",
                          this.state.userName,
                          "required"
                        )}
                      </div>

                      {/* <div className="Required-fields required-new">
                      <p>{localStorage.getItem("language") !==
                        "ar" ? "* Required fields" : <Text>الحقول المطلوبة</Text>}</p>
                    </div> */}
                    </div>
                    <div className="required  required-new">
                      <p>
                        {localStorage.getItem("language") !== "ar" ? (
                          "* Required fields"
                        ) : (
                          <Text>الحقول المطلوبة</Text>
                        )}{" "}
                      </p>
                    </div>

                    <div class="mix-btn new-mix-btn">
                      <div className="submit-btn-container">
                        <button
                          onClick={this.handleForgotSubmit}
                          className="btnc btnc-primary"
                        >
                          Continue
                        </button>
                      </div>

                      <div
                        className="guest-continue"
                        onClick={() =>
                          this.setState({
                            showGuest: false,
                            showLogin: true,
                            showForgot: false,
                          })
                        }
                      >
                        <div className="hidden-desk">
                          {localStorage.getItem("language") !== "ar" ? (
                            "Already a member?"
                          ) : (
                            <Text>عضوا فعلا؟</Text>
                          )}
                          &nbsp;&nbsp;
                          <b>
                            {" "}
                            {localStorage.getItem("language") !== "ar" ? (
                              "Login"
                            ) : (
                              <Text>تسجيل الدخول</Text>
                            )}
                          </b>
                        </div>
                      </div>
                    </div>

                    <div className="login-bottom">
                      <p>
                        {localStorage.getItem("language") !== "ar" ? (
                          " Not Registered?"
                        ) : (
                          <Text>لديك بطاقة عضوية ؟ تفعيل</Text>
                        )}{" "}
                        <a
                          href="#"
                          onClick={() =>
                            this.setState({
                              showRegister: true,
                              showForgot: false,
                              showGuest: false,
                            })
                          }
                        >
                          {localStorage.getItem("language") !== "ar" ? (
                            <b>SIGN UP</b>
                          ) : (
                            <b>
                              {" "}
                              <Text> اشتراك</Text>
                            </b>
                          )}
                        </a>
                      </p>

                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {this.state.otpshow ? (
              <>
                <div className="login-box">
                  {/* <div className="login-overlay"></div> */}
                  <div className="login-up otp-received">
                    <h3>
                      {localStorage.getItem("language") !== "ar" ? (
                        "Enter OTP received on Email"
                      ) : (
                        <Text>أدخل OTP المستلم على البريد الإلكتروني</Text>
                      )}
                    </h3>
                    <div className="login-flex">
                      <div className="login-input-box">
                        <div class="otp_login_white">
                          <label>OTP</label>
                          
     <div className="otp-login bg-otp">
                          <OtpInput
                            value={this.state.otp}
                            class="otp_login_white"
                            onChange={this.handleOtpChange}
                            numInputs={6}
                            separator={<span>&nbsp;</span>}
                            inputmode="numeric"
                            type="number"

                          />
                          {this.resetpassvalidator.message(
                            "OTP",
                            this.state.otp,
                            "required"
                          )}
                          </div>
                        </div>
                        {this.state.otp.length == 6 ?
                          <>
                            <label>
                              {localStorage.getItem("language") !== "ar" ? (
                                "New Password"
                              ) : (
                                <Text>كلمة المرور الجديدة</Text>
                              )}
                            </label>
                            <input
                              type="password"
                              name="password"
                              value={this.state.password}
                              onChange={this.passwordhandleChange}
                            />
                            {this.resetpassvalidator.message(
                              "Password",
                              this.state.password,
                              "required|min:8|strongPasswordreset"
                              )}

                            <label>
                              {localStorage.getItem("language") !== "ar" ? (
                                "Confirm Password"
                              ) : (
                                <Text>تأكيد كلمة المرور</Text>
                              )}
                            </label>
                            <input
                              type="password"
                              name="confirmpassword"
                              value={this.state.confirmpassword}
                              onChange={this.confirmpasswordhandleChange}
                            />
                            {this.resetpassvalidator.message(
                              "Confirm Password",
                              this.state.confirmpassword,
                              "required|min:8|strongPasswordreset"
                              )}
                               {this.state.password !== this.state.confirmpassword && (
    <span style={{ color: 'red' }}>Passwords do not match</span>
  )}
                          </>
                          : ''}
                        <div className="submit-btn-container">
                          <button
                            onClick={this.updatePassword}
                            className="btnc btnc-primary reset-btn"
                          >
                            {localStorage.getItem("language") !== "ar" ? (
                              "Reset Password"
                            ) : (
                              <Text>إعادة تعيين كلمة المرور</Text>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {this.state.showGuest ? (
              <>
                <div className="login-box guest-new-login">
                  {/* <div className="login-overlay"></div> */}
                  <div className="login-up gust-login">
                    <h3>
                      {" "}
                      {localStorage.getItem("language") !== "ar" ? (
                        "Guest Login"
                      ) : (
                        <Text>زائر الرجاء تسجيل الدخول</Text>
                      )}
                    </h3>
                    <div className="login-flex">
                      <div className="login-sec">
                        <div className="login-input-box field">
                          <label>
                            {localStorage.getItem("language") !== "ar" ? (
                              "Full Name *"
                            ) : (
                              <Text>الاسم الكامل *</Text>
                            )}
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="spaces"
                            value={this.state.firstName}
                            onChange={this.firstnameHandleChange}
                          />
                          {this.guestValidator.message(
                            "Name",
                            this.state.firstName,
                            "required"
                          )}
                          <label>
                            {" "}
                            {localStorage.getItem("language") !== "ar" ? (
                              <label>Email *</label>
                            ) : (
                              <label>
                                {" "}
                                <Text>بريد إلكتروني *</Text>
                              </label>
                            )}
                          </label>
                          <input
                            type="text"
                            name="email"
                            className="spaces"
                            value={this.state.email}
                            onChange={this.emailHandleChange}
                          />
                          {this.guestValidator.message(
                            "Email",
                            this.state.email,
                            "required|email"
                          )}

                          <label>
                            {" "}
                            {localStorage.getItem("language") !== "ar" ? (
                              <label>Mobile Number *</label>
                            ) : (
                              <label>
                                {" "}
                                <Text>رقم الهاتف المحمول *</Text>
                              </label>
                            )}
                          </label>
                          <div className="drop-mobile">
                            <Select
                              styles={style}
                              options={options}
                              onChange={this.ccHandleChange}
                              value={this.state.ccode}
                              placeholder={"+965"}
                              defaultValue={options[0]}
                            />

                            <input
                              type="number"
                              name="mobile"
                              value={this.state.mobile}
                              onChange={this.numberhandleChange}
                            />
                          </div>
                          {this.guestValidator.message(
                            "mobile",
                            this.state.mobile,
                            "required|max:8"
                          )}

                          {/* <div className="submit-btn-container">
                        <button
                          onClick={this.handleGuestLogin}
                          className="btnc btnc-primary login"
                        >
                          Login{" "}
                        </button>
                      </div> */}

                          <div class="Required-fields">
                            <p>
                              {" "}
                              {localStorage.getItem("language") !== "ar" ? (
                                "* Required fields"
                              ) : (
                                <Text>الحقول المطلوبة</Text>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mix-btn">
                      <div className="submit-btn-container">
                        <button
                          onClick={this.handleGuestLogin}
                          className="btnc btnc-primary"
                        >
                          {localStorage.getItem("language") !== "ar" ? (
                            "Continue"
                          ) : (
                            <Text>يكمل</Text>
                          )}
                        </button>
                      </div>

                      <div
                        className="guest-continue"
                        onClick={() =>
                          this.setState({ showGuest: false, showLogin: true })
                        }
                      >
                        {localStorage.getItem("language") !== "ar" ? (
                          "Already a member?"
                        ) : (
                          <Text>عضوا فعلا؟</Text>
                        )}
                        &nbsp;&nbsp;
                        <b>
                          {" "}
                          {localStorage.getItem("language") !== "ar" ? (
                            "Login"
                          ) : (
                            <Text>تسجيل الدخول</Text>
                          )}
                          &nbsp;&nbsp;
                        </b>
                      </div>
                    </div>
                    <div class="Required-fields">
                      <p>
                        {" "}
                        {localStorage.getItem("language") !== "ar" ? (
                          "* Required fields"
                        ) : (
                          <Text>الحقول المطلوبة</Text>
                        )}
                      </p>
                    </div>
                    <div className="login-bottom">
                      <p>
                        {localStorage.getItem("language") !== "ar" ? (
                          " Not Registered?"
                        ) : (
                          <Text>لديك بطاقة عضوية ؟ تفعيل</Text>
                        )}{" "}
                        <a
                          href="#"
                          onClick={() =>
                            this.setState({
                              showRegister: true,
                              showForgot: false,
                              showGuest: false,
                            })
                          }
                        >
                          {localStorage.getItem("language") !== "ar" ? (
                            <b>SIGN UP</b>
                          ) : (
                            <b>
                              {" "}
                              <Text> اشتراك</Text>
                            </b>
                          )}
                        </a>
                      </p>

                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {this.state.afterAccountVerified ? (
              <div className="login-box  ">
                <div className="login-up">
                  <div className="verify-user">
                    <h3>ACCOUNT VERIFIED</h3>

                    <div className="otp-profile">
                      <div class="successfully">
                        <p>
                          Your account is successfully verified! <br />
                          Your<b> Email Address</b> will be your <b>Login ID</b>
                        </p>
                      </div>

                      <div className="btn-submit">
                        <div class="submit-btn-container">
                          <button class="btnc btnc-primary" onClick={this.handleAfterAccountVerified}> Proceed</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
            : ("")}

       {this.state.bothotpshow ? (
              <>
                <div className="login-box">
               
                  <div className="login-up otp-received">
                    <h3>
                      Enter OTP received on Email <br></br>& Mobile
                    </h3>
                    <div className="login-flex">
                      <div className="login-input-box">
                        <div class="otp_login">
                          <label>Email OTP</label>
                       
 <div className="otp-login bg-otp">
                          <OtpInput
                            value={this.state.eotp}
                            class="otp_login_white"
                            onChange={this.handleEmailOtpChange}
                            numInputs={6}
                            separator={<span>&nbsp;</span>}
                            inputmode="numeric"
                          />
                          {this.bothotppassvalidator.message(
                            "Email OTP",
                            this.state.eotp,
                            "required"
                          )}
                        </div>
                        </div>
                        <div class="otp_login">
                          <label>Mobile OTP</label>
                          
 <div className="otp-login bg-otp">
                          <OtpInput
                            value={this.state.motp}
                            class="otp_login"
                            onChange={this.handleMobileOtpChange}
                            numInputs={6}
                            separator={<span>&nbsp;</span>}
                            inputmode="numeric"
                          />
                          {this.bothotppassvalidator.message(
                            "Mobile OTP",
                            this.state.motp,
                            "required"
                          )}
                        </div>
                        </div>
                        <div className="submit-btn-container">
                          <button
                            onClick={this.mverifyboth}
                            className="btnc btnc-primary reset-btn"
                          >
                            Submit OTP
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {this.state.emailotpshow ? (
              <>
                <div className="login-box">
             
                  <div className="login-up otp-received">
                    <h3>Enter OTP received on Email</h3>
                    <div className="login-flex">
                      <div className="login-input-box">
                        <div class="otp_login">
                          <label>OTP</label>
                            
 <div className="otp-login bg-otp">
                          <OtpInput
                            value={this.state.eotp}
                            class="otp_login_white"
                            onChange={this.handleEmailOtpChange}
                            numInputs={6}
                            separator={<span>&nbsp;</span>}
                            inputmode="numeric"

                          />
                          {this.emailotppassvalidator.message(
                            "Email OTP",
                            this.state.eotp,
                            "required"
                          )}
                        </div>
                        </div>

                        <div className="submit-btn-container">
                          <button
                            onClick={this.mverifyemail}
                            className="btnc btnc-primary reset-btn"
                          >
                            Submit OTP
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {this.state.mobileotpshow ? (
              <>
                <div className="login-box">
    
                  <div className="login-up otp-received">
                    <h3>Enter OTP received on Mobile</h3>
                    <div className="login-flex">
                      <div className="login-input-box">
                        <div class="otp_login_white">
                          <label> OTP</label>
                          
 <div className="otp-login bg-otp">
                          <OtpInput
                            value={this.state.motp}
                            class="otp_login"
                            onChange={this.handleMobileOtpChange}
                            numInputs={6}
                            separator={<span>&nbsp;</span>}
                            inputmode="numeric"

                          />
                          {this.mobileotppassvalidator.message(
                            "mobile OTP",
                            this.state.motp,
                            "required"
                          )}
                        </div>
                        </div>

                        <div className="submit-btn-container">
                          <button
                            onClick={this.mverifymobile}
                            className="btnc btnc-primary reset-btn"
                          >
                            Submit OTP
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              "" 
            )}
          </div>
        </div>
        {/* <div className="black-bottom"></div> */}
        <div class="mobile-border-bot"></div>
        <div className="mobile-bg"></div>
        {/* <div className="black-gradient"></div> */}
      </>
    );
  }
}
export default Login;
