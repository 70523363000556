import React, { Component } from "react";
import Text from "react-text";
import SimpleReactValidator from "simple-react-validator";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import * as Constants from "../../Constants/index";
import moment from "moment";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Loading } from "../header/loader.component";
import Select from "react-select";
import { Link } from "react-router-dom";

import { PaymentInputsContainer } from "react-payment-inputs";
const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};
class MyAccount extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      showModal: false,
      paymentMethod: "",
      experienceSelect: "",
      profile: {},
      bookings: [],
      detailsId: "",
      upcomingdetailsId: "",
      dobdate: new Date(),
      amounts: [],
      choosenamount: 0,
      upcoming: [],
      ctransid: "",
      cbooktype: "",
      cbookingid: "",
      loading: false,
      editprofile: false,
      prefcinema: "",
      cinema: [],
      prefexperience: [],
      prefrating: [],
      prefseatType: "",
      prefseatCategory: "",
      prefcinema: "",
      showcreditcard: false,
      result: new URLSearchParams(window.location.search).get("result"),
      expirationMonth: "",
      expirationYear: "",
      expiryDate: "",
      cardNumber: "",
      cvNumber: "",
      orgId: "",
      jwtToken: "",
      deviceSessionId: "",
      merchantId: "",
      cc: "",
      countryCode: [],
      minYear: new Date().getFullYear(),
      exactMinDate: new Date(),
      oldpassword: "",
      pickUpNumber:"",
      tabActive: new URLSearchParams(window.location.search).get("tab"),

    };
    this.validator = new SimpleReactValidator();
    this.changevalidator = new SimpleReactValidator({
      messages: {
        required: 'The :attribute field is required.',
        min: 'The :attribute must be at least 8 characters long.',
        regex: 'The :attribute must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
      },
      validators: {
        strongPassword: {
          message: 'The :attribute must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
          rule: (val, params, validator) => {
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(val);
          },
        },
      },
    });
  }
  ccHandleChange = (cc) => {
    this.setState({ cc: cc.value, ccode: cc });
  };

  // componentDidMount() {
  //   const currentDate = new Date(this.state.exactMinDate);
  //   const exactMinDate = new Date(currentDate);
  //   exactMinDate.setFullYear(exactMinDate.getFullYear() - 10);
  //   this.setState({ exactMinDate });
  // }
  updateProfile = () => {
    if (this.validator.allValid()) {
        let token = localStorage.getItem("token");

        axios
          .post(
            Constants.api + "customer/action/updateprofile",
            {
              city: this.state.profile.city,
              dob: this.state.profile.dob,
              mobilePhone: this.state.profile.mobilePhone,
              password: "",
              email: this.state.profile.email,
              userName: this.state.profile.userName,
              firstName: this.state.profile.firstName,
              gender: this.state.profile.gender,
              lastName: this.state.profile.lastName,
              promoEmail: this.state.profile.promoEmail,
              promoMobile: this.state.profile.promoMobile,
              reserveNotification: true,
              userId: this.state.profile.userId,
            },
            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                Authorization: `Bearer ${token}`,
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.data.result == "success") {
              swal("", res.data.msg);
              this.setState({ editprofile: false });

              if (res.data.output.user.needLogin) {
                localStorage.setItem('needLogin',true)
                localStorage.clear();

                window.location = "/";
              }
              //  setTimeout(window.location.reload(false), 1000);
            } else {
              // this.setState({alert:res.data.msg});
              swal(res.data.msg);
            }
          })
          .catch((error) => {
            this.refreshtoken();
            // window.location = "/login";
            // alert("dsjkd")
          });
  
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  changePassword = () => {
    if (this.changevalidator.allValid()) {
      if (this.state.password == this.state.confirmpassword) {
        let token = localStorage.getItem("token");

        axios
          .post(
            Constants.api + "customer/action/changepassword",
            {
              password: this.state.password,
              oldPassword: this.state.oldpassword,

              userId: localStorage.getItem("userid"),
            },
            {
              headers: {
                "Accept-Language": localStorage.getItem("language"),
                Authorization: `Bearer ${token}`,
                appversion: Constants.av,
                platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.data.result == "success") {
              swal("", res.data.msg);
              localStorage.setItem('needLogin',true)

              localStorage.clear();

              window.location = "/";
              //   }
              //  setTimeout(window.location.reload(false), 1000);
            } else {
              // this.setState({alert:res.data.msg});
              swal(res.data.msg);
            }
          })
          .catch((error) => {
            this.refreshtoken();
            // window.location = "/login";
            // alert("dsjkd")
          });
      } else swal("Passwords do not match");
    } else this.changevalidator.showMessages();
    this.forceUpdate();
  };
  payClubCard = () => {
    const token = localStorage.getItem("token");

    axios
      .post(
        Constants.api + "clubcard/addRechargeCard",
        {
          amount: this.state.choosenamount,
          userid: localStorage.getItem("userid"),
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            Authorization: `Bearer ${token}`,
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.result == "success") {
          this.setState({
            ctransid: res.data.output.transid,
            cbooktype: res.data.output.booktype,
            cbookingid: res.data.output.bookingid,
          });
          localStorage.setItem('bookingid',res.data.output.bookingid);
          if (this.state.showcreditcard) this.cybersourcePayment();
        
          else this.knetPayment();
          //  setTimeout(window.location.reload(false), 1000);
        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });
  };
  cybersourcePayment = () => {
    let token = localStorage.getItem("token");
    this.setState({ loading: true });
    axios
      .post(
        Constants.api + "payment/cybersource/initiate",
        {
          bookingid: this.state.cbookingid,
          userid: localStorage.getItem("userid"),
          transid: this.state.ctransid,
          booktype: this.state.cbooktype,
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            Authorization: `Bearer ${token}`,

            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
          },
        }
      )
      .then((res) => {
        this.setState({ loading: true });
        console.log(res);
        if (res.data.result == "success") {
          this.setState({
            jwtToken: res.data.output.jwtToken,
            deviceSessionId: res.data.output.deviceSessionId,
            merchantId: res.data.output.merchantId,
            orgId: res.data.output.orgId,
          });
          const script = document.createElement("script");
          script.src =
            "https://h.online-metrix.net/fp/tags.js?org_id=" +
            this.state.orgId +
            "&session_id=" +
            this.state.merchantId +
            this.state.deviceSessionId;
          script.async = false;
          document.body.appendChild(script);
          window.Cardinal.configure({
            logging: {
              debug: "verbose",
            },
          });
          window.Cardinal.setup("init", {
            jwt: res.data.output.jwtToken,
          });
          let session_Id = "";
          window.Cardinal.on(
            "payments.setupComplete",
            function (setupCompleteData) {
              console.log("setup complete");
              console.log(setupCompleteData.sessionId);
              session_Id = setupCompleteData.sessionId;
            }
          );
          //  await sleep(10000); //wait 5 seconds
          setTimeout(
            function () {
              this.payerauth(session_Id);
            }.bind(this),
            10000
          );
          // this.payerauth(session_Id);
        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });
  };
  payerauth = (
    session_Id,
    jwt,
    cardNumber,
    cvNumber,
    expirationMonth,
    expirationYear
  ) => {
    this.setState({ loading: true });
    // alert(session_Id);
    let token = localStorage.getItem("token");

    axios
      .post(
        Constants.api + "payment/cybersource/payerauth",
        {
          bookingid: this.state.cbookingid,
          userid: localStorage.getItem("userid"),
          transid: this.state.ctransid,
          cardNumber: this.state.cardNumber.replaceAll(" ", ""),
          cvNumber: this.state.cvNumber,
          expirationMonth: this.state.expiryDate
            .split("/")[0]
            .replaceAll(" ", ""),
          expirationYear: this.state.expiryDate
            .split("/")[1]
            .replaceAll(" ", ""),
          referenceId: session_Id,
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        console.log(res);
        if (res.data.result == "success") {
          if (res.data.output.redirect == "1") {
            //show_alert(data.errorDescription);
            window.location = res.data.output.responseURL;
          } else {
            //alert(data);
            let jwt = this.state.jwtToken;
            let cardNumber = this.state.cardNumber.replaceAll(" ", "");
            let cvNumber = this.state.cvNumber;
            let expirationMonth = this.state.expiryDate
              .split("/")[0]
              .replaceAll(" ", "");
            let expirationYear = this.state.expiryDate
              .split("/")[1]
              .replaceAll(" ", "");
            let sessionid = this.state.deviceSessionId;
            let bookingId = this.state.cbookingid;
            this.cardinalCall(
              res.data.output,
              sessionid,
              jwt,
              cardNumber,
              cvNumber,
              expirationMonth,
              expirationYear,
              bookingId
            );
          }
        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });
  };
  cardinalCall = (data, sessionid, jwtToken, cn, cv, em, ey, bookingId) => {
    let token = localStorage.getItem("token");

    this.setState({ loading: true });
    let xid = data.xid;
    console.log(xid);
    let jwt = jwtToken;
    const session_Id = sessionid;
    const cbookingid = bookingId;
    const cardNumber = cn;
    const cvNumber = cv;
    const expirationMonth = em;
    const expirationYear = ey;
    console.log("cardinal call");
    window.Cardinal.continue(
      "cca",
      {
        AcsUrl: data.acsURL,
        Payload: data.pares,
      },
      {
        OrderDetails: {
          TransactionId: data.authTransId,
          // Add the rest of the Order object
        },
      }
    );
    window.Cardinal.on("payments.validated", function (data, jwt) {
      console.log(data);
      console.log(jwt);
      // alert("cardNumber:" + cardNumber);
      // alert("cvNumber:" + cvNumber);
      // alert("session_Id:" + session_Id);
      axios
        .post(
          Constants.api + "payment/cybersource/payerresponse",
          {
            bookingid: cbookingid,
            cardNumber: cardNumber,
            cvNumber: cvNumber,
            expirationMonth: expirationMonth,
            expirationYear: expirationYear,
            payerAuth: jwt,
            sessionId: session_Id,
            xid: xid == null ? "" : xid,
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            window.location =
              "/walletconfirm?result=success&bookingid=" +
              localStorage.getItem("bookingid") +
              "&type=BOOKING";
          } else {
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);
            window.location =
              "/walletconfirm?result=fail&bookingid=" +
              localStorage.getItem("bookingid") +
              "&type=BOOKING";
          }
        });
      // var exp = document.getElementById('expmonth').value;
      // exp = exp.split('/');
      // $.post("/Payment/CyberSourceJWTResponse",
      //   {
      //     cardNumber: this.state.cardNumber,
      //     cvNumber: this.state.cvNumber,
      //     expirationMonth: this.state.expiryDate.split('/')[0],
      //     expirationYear: this.state.expiryDate.split('/')[1],
      //     payerauth: this.state.jwt,
      //     xid: xid,
      //     session_Id: document.getElementById("devicesession").value
      //   },
      //   function (data) {
      //     if (data.reasonCode == "100" && data.responseURL != 'Failure') {
      //       location.href = data.responseURL;
      //     } else {
      //       show_alert(data.errorDescription);
      //       location.href = data.responseURL;
      //     }
      //   }, 'JSON');
      //function (data) {
      //    //var obj = jQuery.parseJSON(data);
      //    console.log(data);
      //    window.location.href = "http://localhost:60886/HOME";
      //});
    });
  };
  knetPayment = () => {
    this.setState({ loading: true });
    localStorage.setItem('bookType',this.state.cbooktype);

    const token = localStorage.getItem("token");

    axios
      .post(
        Constants.api + "payment/knet/hmac",
        {
          bookingid: this.state.cbookingid,
          booktype: this.state.cbooktype,
          transid: this.state.ctransid,
          userid: this.state.profile.userId,
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            Authorization: `Bearer ${token}`,
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        console.log(res);
        if (res.data.result == "success") {
          window.location = res.data.output.callingUrl;
          //  setTimeout(window.location.reload(false), 1000);
        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      });
  };
  updatePref = () => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.api + "customer/action/setpref",
        {
          arabic: localStorage.getItem("language") !== "ar" ? "false" : "true",
          cinema: this.state.prefcinema,
          experience: this.state.prefexperience.join(","),
          rating: this.state.prefrating.join(","),
          seatCategory: this.state.prefseatCategory,
          seatType: this.state.prefseatType,
          userId: this.state.profile.userId,
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            Authorization: `Bearer ${token}`,
            appversion: Constants.av,
            platform: Constants.pt,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.result == "success") {
          swal("", res.data.msg);
          //  setTimeout(window.location.reload(false), 1000);
        } else {
          // this.setState({alert:res.data.msg});
          swal(res.data.msg);
        }
      })
      .catch((error) => {
        this.refreshtoken();
        // window.location = "/login";
        // alert("dsjkd")
      });
  };
  cardNumberChange = (event) => {
    this.setState({
      cardNumber: event.target.value,
    });
  };
  cvNumberChange = (event) => {
    this.setState({ cvNumber: event.target.value });
  };
  expirationMonthChange = (event) => {
    this.setState({ expirationMonth: event.target.value });
  };
  expirationDateChange = (event) => {
    this.setState({ expiryDate: event.target.value });
  };
  expirationYearChange = (event) => {
    this.setState({ expirationYear: event.target.value });
  };
  emailHandleChange = (event) => {
    let profile = this.state.profile;
    profile.email = event.target.value;
    this.setState({ profile });
  };
  mobilePhoneHandleChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (inputValue.length <= 8 && regex.test(inputValue)) {
      this.setState({ mobile: inputValue });
    }

    let profile = this.state.profile;
    profile.mobilePhone = event.target.value;
    this.setState({ profile });
  };
  userNameHandleChange = (event) => {
    let profile = this.state.profile;
    profile.userName = event.target.value;
    this.setState({ profile });
  };
  firstNameHandleChange = (event) => {
    let profile = this.state.profile;
    profile.firstName = event.target.value;
    this.setState({ profile });
  };
  lastNameHandleChange = (event) => {
    let profile = this.state.profile;
    profile.lastName = event.target.value;
    this.setState({ profile });
  };
  passwordHandleChange = (event) => {
    let password = event.target.value;
    this.setState({ password });
  };
  oldpasswordHandleChange = (event) => {
    let oldpassword = event.target.value;
    this.setState({ oldpassword });
  };
  confirmpasswordHandleChange = (event) => {
    let confirmpassword = event.target.value;
    this.setState({ confirmpassword });
  };
  cityHandleChange = (event) => {
    let profile = this.state.profile;
    profile.city = event.target.value;
    this.setState({ profile });
  };
  seatTypeHandleChange = (seatType) => {
    let profile = this.state.profile;
    profile.seatType = seatType;
    this.setState({ profile });
  };
  seatCategoryHandleChange = (seatCategory) => {
    let profile = this.state.profile;
    profile.seatCategory = seatCategory;
    this.setState({ profile });
  };
  choosenAmountHandleChange = (event) => {
    this.setState({ choosenamount: event.target.value });
  };
  dobHandleChange = (date) => {
    // let fromnow = new Date();
    // fromnow.setDate(fromnow.getDate() - 1826);
    console.log(date);
    // if (date < fromnow) {
    // let dob = date;
    this.state.profile.dob = moment(date).format("DD-MM-yyyy");
    this.setState({
      dobdate: date,
      // profile.dob: moment(date).format("yyyy-MM-DD")
    });
    // } else swal("dob incorrect");
  };
  experienceSelect = (exp) => {
    let prefexperience = this.state.prefexperience;
    if (!prefexperience.includes(exp)) prefexperience.push(exp);
    else prefexperience.splice(prefexperience.indexOf(exp), 1);
    this.setState({ prefexperience });
  };
  ratingSelect = (exp) => {
    let prefrating = this.state.prefrating;
    if (!prefrating.includes(exp)) prefrating.push(exp);
    else prefrating.splice(prefrating.indexOf(exp), 1);
    this.setState({ prefrating });
  };
  seatTypeSelect = (prefseatType) => {
    if (this.state.prefseatType == prefseatType)
      this.setState({ prefseatType: "" });
    else this.setState({ prefseatType });
  };
  seatCategorySelect = (prefseatCategory) => {
    if (this.state.prefseatCategory == prefseatCategory)
      this.setState({ prefseatCategory: "" });
    else this.setState({ prefseatCategory });
  };
  cinemaSelect = (event) => {
    this.setState({ prefcinema: event.target.value });
  };
  genderHandleChange = (gender) => {
    let profile = this.state.profile;
    profile.gender = gender;
    this.setState({ profile });
  };
  promoEmailHandleChange = (checked) => {
    let profile = this.state.profile;
    profile.promoEmail = checked;
    this.setState({ profile });
  };
  promoMobileHandleChange = (checked) => {
    let profile = this.state.profile;
    profile.promoMobile = checked;
    this.setState({ profile });
  };
  detailsHandler = (bookingId) => {
    this.setState({ detailsId: bookingId });
  };
  upcomingdetailsHandler = (bookingId) => {
    if (this.state.upcomingdetailsId == bookingId)
      this.setState({ upcomingdetailsId: "" });
    else this.setState({ upcomingdetailsId: bookingId });
  };
  componentDidMount() {
    let token = localStorage.getItem("token");
    const secondTab = document.querySelectorAll('.account-tablist #react-tabs-2')[0];
    if (secondTab && this.state.tabActive=="bookings") {
      secondTab.click();
    }
    if (localStorage.getItem("guest")) {
      localStorage.clear();
      window.location = "/";
    }
    axios
      .get(Constants.api + "customer/getcountry?id=", "", {
        headers: {
          "Accept-Language": localStorage.getItem("language"),
          Authorization: `Bearer ${token}`,
          appversion: Constants.av,
          platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
        },
      })
      .then((res) => {
        let countryCode = [];
        console.log(res.data.output);

        // res.data.output.map((country, i) => {
        //   const { countryCode, isdCode } = country;
        //   let obj = { countryCode, isdCode };
        //   console.log(obj);
        // });
        res.data.output.map((country) => {
          countryCode.push(country);
        });
        this.setState({
          countryCode: countryCode,
        });
      });

    if (
      localStorage.getItem("userid") == "" ||
      localStorage.getItem("userid") == "undefined" ||
      !localStorage.getItem("userid")
    ) {
      localStorage.clear();
      window.location = "/";
    }
    // if (this.state.result == "success")
    //   swal(res.data.msg);

    // if (this.state.result == "fail")
    //   swal(res.data.msg);

    axios
      .get(Constants.api + "clubcard/getamounts", {
        headers: {
          "Accept-Language": localStorage.getItem("language"),
          Authorization: `Bearer ${token}`,
          appversion: Constants.av,
          platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
        },
      })
      .then((res) => {
        if (res.data.result == "success")
          this.setState({ amounts: res.data.output.amounts });
      });
    const getprofile = async () => {
      let token = localStorage.getItem("token");

      axios
        .post(
          Constants.api + "customer/action/getprofile",
          {
            lat: "string",
            lng: "string",
            userId: localStorage.getItem("userid"),
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              Authorization: `Bearer ${token}`,
              appversion: Constants.av,
              platform: Constants.pt,
            },
          }
        )
        .then((res) => {
          console.log(res);

          if (res.data.result == "success") {
            this.setState({ profile: res.data.output.user });
            //  alert(res.data.output.dob);
            let prefexperience = this.state.prefexperience;
            let prefrating = this.state.prefrating;
            Object.keys(res.data.output.user.experience).forEach(function (
              key2
            ) {
              prefexperience.push(res.data.output.user.experience[key2].name);
            });
            Object.keys(res.data.output.user.rating).forEach(function (key2) {
              prefrating.push(res.data.output.user.rating[key2].name);
            });
            this.setState({ prefexperience });
            this.setState({ prefrating });
            this.setState({
              prefseatCategory: res.data.output.user.seatCategory,
              prefseatType: res.data.output.user.seatType,
              prefcinema: res.data.output.user.cinema,
            });
            if (res.data.output.dob)
              this.setState({
                dobdate: moment(res.data.output.user.dob, "DD-MM-YYYY").format(
                  "YYYY-MM-DD"
                ),
              });
            //  alert(moment(res.data.output.dob, 'DD-MM-YYYY').format('YYYY-MM-DD'))
          }
          // else window.location = "/login";
          // console.log(this.state.shows[0]["showTime"]);
        })
        .catch((error) => {
          refreshtoken();
          // window.location = "/login";
          // alert("dsjkd")
        });
    };
    const refreshtoken = async () => {
      axios
        .post(
          Constants.api + "customer/refresh-tokens",
          {
            refreshToken: localStorage.getItem("refreshtoken"),
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              // Authorization: `Bearer ${token}`,
              appversion: Constants.av,
              platform: Constants.pt,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            localStorage.setItem("token", res.data.output.access.token);
            localStorage.setItem("refreshtoken", res.data.output.refresh.token);
            // getprofile();
          } else {
            swal("Session Expired");
            window.location = "/";
          }
          // else window.location = "/login";
          // console.log(this.state.shows[0]["showTime"]);
        })
        .catch((error) => {
          //refreshtoken();
          localStorage.clear();

          window.location = "/";
          // alert("dsjkd")
        });
    };
    getprofile();

    axios
      .post(
        Constants.api + "history/mybookings",
        {
          bookingid: "string",
          booktype: "string",
          transid: 0,
          upcoming: false,
          userid: localStorage.getItem("userid"),
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("my bookings");
        console.log(res);
        if (res.data.output != null)
          this.setState({ bookings: res.data.output });
        // console.log(this.state.shows[0]["showTime"]);
        console.log(this.state.bookings);
      });
    axios
      .get(Constants.api + "content/cinemas", "", {
        headers: {
          "Accept-Language": localStorage.getItem("language"),
          Authorization: `Bearer ${token}`,
          appversion: Constants.av,
          platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
        },
      })
      .then((res) => {
        console.log(res);
        console.log("cinemas");
        this.setState({
          cinema: res.data.output.cinemas,
        });
      });
    axios
      .post(
        Constants.api + "history/nextbookings",
        {
          bookingid: "string",
          booktype: "string",
          transid: 0,
          upcoming: true,
          userid: localStorage.getItem("userid"),
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("next bookings");
        console.log(res);
        if (res.data.result == "success") {
          this.setState({ upcoming: res.data.output });
        }
        // console.log(this.state.shows[0]["showTime"]);
      });
  }
  refreshtoken = async () => {
    axios
      .post(
        Constants.api + "customer/refresh-tokens",
        {
          refreshToken: localStorage.getItem("refreshtoken"),
        },
        {
          headers: {
            "Accept-Language": localStorage.getItem("language"),
            appversion: Constants.av,
            platform: Constants.pt,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.result == "success") {
          localStorage.setItem("token", res.data.output.access.token);
          localStorage.setItem("refreshtoken", res.data.output.refresh.token);
        } else {
          swal("Session Expired");
          window.location = "/";
          // getprofile();
        }
        // else window.location = "/login";
        // console.log(this.state.shows[0]["showTime"]);
      })
      .catch((error) => {
        //refreshtoken();
        localStorage.clear();

        window.location = "/login";
        // alert("dsjkd")
      });
  };

  render() {
    const { selectedDate } = this.state;
    const minYear = new Date().getFullYear() - 90; // Set a minimum year (e.g., 5 years ago)
    const maxYear = new Date().getFullYear() - 10;
    const maxMonth = new Date().getMonth();
    const maxDay = new Date().getDate();
    let profile = this.state.profile;
    let prefcinema = this.state.prefcinema;
    let bookings = this.state.bookings;
    let totalFood = 0;
    let dob = this.state.dob;
    let detailsHandler = (bookingId) => {
      if (this.state.detailsId == bookingId) this.setState({ detailsId: "" });
      else this.setState({ detailsId: bookingId });
    };
    let upcomingdetailsId = this.state.upcomingdetailsId;
    let detailsId = this.state.detailsId;

    let upcomingdetailsHandler = (bookingId) => {
      if (this.state.upcomingdetailsId == bookingId)
        this.setState({ upcomingdetailsId: "" });
      else this.setState({ upcomingdetailsId: bookingId });
    };
    let addFood = (bookingid, transid, ccode) => {
      localStorage.setItem("bookingid", bookingid);
      localStorage.setItem("transid", transid);
      localStorage.setItem("onlyFood", true);
      window.location = "/food/" + ccode;
    };
    let prepareFood = (bookingid, transid, booktype) => {
      let message = localStorage.getItem("language") !== "ar"
        ? "Are You Sure?\nBy Clicking Yes, your order will be prepared now and it must be pickup as soon as it's ready from the Pick up counter."
        : "متأكد؟\nعند الضغط على زر نعم سيتم البدء بتجهيز طلبك وعليك استلامه فور جهوزية الطلب.";
    
      swal({
        title: "Confirmation",
        text: message,
        icon: "warning",
        buttons: {
          no: {
            text: localStorage.getItem("language") !== "ar" ? "No" : "لا",
            value: false,
            className: "swal-button--no",
            visible: true,
            closeModal: true
          },
          yes: {
            text: localStorage.getItem("language") !== "ar" ? "Yes" : "نعم",
            value: true,
            visible: true,
            className: "swal-button--no",
            closeModal: true
          }
        }
      }).then((confirmed) => {
        if (confirmed) {
          axios
            .post(
              Constants.api + "content/food/pickupfood",
              {
                userid: localStorage.getItem("userid"),
                bookingid: bookingid,
                transid: transid,
                booktype: booktype,
                payFull: true,
              },
              {
                headers: {
                  "Accept-Language": localStorage.getItem("language"),
                  appversion: Constants.av,
                  platform: Constants.pt,
                },
              }
            )
            .then((res) => {
              console.log(res);
              if (res.data.result == "success") {
                window.location.reload();
              } else {
                swal(res.msg);
                // getprofile();
              }
            });
        }
      });
    };
    

    const handleConfirmation = (bookingid, transid) => {
      swal2
        .fire({
          title: "Cancel Booking",
          text: "Are you sure you want to cancel the booking?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, I'm Sure",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.isConfirmed) {
            // User confirmed, perform your action here
            cancelbooking(bookingid, transid);
          } else if (result.dismiss === swal2.DismissReason.cancel) {
            // User clicked Cancel or closed the confirmation dialog
            // Handle this case as needed
          }
        });
    };
    let token = localStorage.getItem("token");

    let cancelbooking = (bookingid, transid) => {
      axios
        .post(
          Constants.api + "content/trans/cancelbooking",
          {
            userid: localStorage.getItem("userid"),
            bookingId: bookingid,
            transid: transid,
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              Authorization: `Bearer ${token}`,
              platform: Constants.pt,
            },
          }
        )
        .then((res) => {
          if (res.data.result == "success") {
            swal(res.data.msg);
            window.location = "/";
          } else {
            swal(res.data.msg);
            // getprofile();
          }
          // else window.location = "/login";
          // console.log(this.state.shows[0]["showTime"]);
        });
    };
    let resendbooking = (bookingid, transid) => {
      axios
        .post(
          Constants.api + "history/resend",
          {
            userid: localStorage.getItem("userid"),
            bookingid: bookingid,
            transid: transid,
            booktype: "BOOKING",
            payFull: true,
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appversion: Constants.av,
              platform: Constants.pt,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.result == "success") {
            swal(res.data.msg);
            window.location.reload();
          } else {
            swal(res.msg);
            // getprofile();
          }
          // else window.location = "/login";
          // console.log(this.state.shows[0]["showTime"]);
        });
    };
    console.log(this.state.detailsId);
    let options = [];
    this.state.countryCode.map(function (item, i) {
      options.push({
        value: item.isdCode,
        label: (
          <div>
            <img src={item.flag} height="15px" width="15px" />
            &nbsp;{item.isdCode}
          </div>
        ),
      });
    });
    console.log(options[0]);
    let defaultValue = options[0];
    return (
      <>
        <div className="myaccount-banner seat-banner">
          <img src={require("../../assets/my-account.png").default} alt="" />
          <h3>
            {localStorage.getItem("language") !== "ar" ? (
              "MY ACCOUNT"
            ) : (
              <Text>حسابي</Text>
            )}
          </h3>
          <div className="account-name">
            <div className="account-left">
              {localStorage.getItem("language") !== "ar" ? (
                "Welcome back"
              ) : (
                <Text>مرحبا بعودتك</Text>
              )}
            </div>
            <div className="account-right">
              <p>
                <b>{localStorage.getItem("username")}</b>
              </p>
              <p>
                {localStorage.getItem("language") !== "ar" ? (
                  "Wallet Balance"
                ) : (
                  <Text>رصيد المحفظة</Text>
                )}
                &nbsp; {profile.balance ? profile.balance : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="black-width"></div>
        <div className="home-content-container">
          <div className="myaccount-name-tabs">
            <div className="account-tabs">
              <Tabs>
                <div className="border-bot justify-flex name-tabs-container">
                  <div className="account-name">
                    <div className="account-left">
                      {localStorage.getItem("language") !== "ar" ? (
                        " Welcome back"
                      ) : (
                        <Text>مرحبا بعودتك</Text>
                      )}
                    </div>
                    <div className="account-right">
                      <p>
                        <b>{localStorage.getItem("username")}</b>
                      </p>
                      <p>
                        {localStorage.getItem("language") !== "ar" ? (
                          "Wallet Balance"
                        ) : (
                          <Text>رصيد المحفظة</Text>
                        )}
                        &nbsp; {profile.balance ? profile.balance : ""}
                      </p>
                    </div>
                  </div>
                  <div className="accounts-tab">
                    <TabList className="account-tablist"  >
                      {!localStorage.getItem("guest") ? (
                        <Tab>
                          <div className="acc-tab">
                            <img
                              src={
                                require("../../assets/user-dark.png").default
                              }
                            />
                            <p>
                              {localStorage.getItem("language") !== "ar" ? (
                                "PROFILE"
                              ) : (
                                <Text>الملف الشخصي</Text>
                              )}
                            </p>
                          </div>
                        </Tab>
                      ) : (
                        ""
                      )}
                      <Tab>
                        <div className="acc-tab">
                          <img src={require("../../assets/film.png").default} />
                          <p>
                            {localStorage.getItem("language") !== "ar" ? (
                              "BOOKINGS"
                            ) : (
                              <Text>الحجوزات</Text>
                            )}
                          </p>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="acc-tab">
                          <img
                            src={
                              require("../../assets/icons-light-setting.png")
                                .default
                            }
                          />
                          <p>
                            {localStorage.getItem("language") !== "ar" ? (
                              "PREFERENCES"
                            ) : (
                              <Text>التفضيلات</Text>
                            )}
                          </p>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="acc-tab">
                          <img
                            src={
                              require("../../assets/Outline_Icons.png").default
                            }
                          />
                          <p>
                            {localStorage.getItem("language") !== "ar" ? (
                              "RECHARGE WALLET"
                            ) : (
                              <Text>إعادة شحن المحفظة</Text>
                            )}
                          </p>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="acc-tab">
                          <img
                            src={
                              require("../../assets/Icon awesome-history.png")
                                .default
                            }
                          />
                          <p>
                            {localStorage.getItem("language") !== "ar" ? (
                              "HISTORY"
                            ) : (
                              <Text>التاريخ</Text>
                            )}
                          </p>
                        </div>
                      </Tab>
                    </TabList>
                  </div>
                </div>
                <div className="accounts-panel">
                  {!localStorage.getItem("guest") ? (
                    <TabPanel>
                      <div className="my-profile-container">
                        <div className="profile-section">
                          <div className="my-profile-head">
                            <h3>
                              {" "}
                              {localStorage.getItem("language") !== "ar" ? (
                                "PROFILE"
                              ) : (
                                <Text>الملف الشخصي</Text>
                              )}
                            </h3>
                            {!this.state.editprofile ? (
                              <div className="profile-edit">
                                <a
                                  href="#"
                                  onClick={() =>
                                    this.setState({ editprofile: true })
                                  }
                                >
                                  {localStorage.getItem("language") !== "ar" ? (
                                    "Edit"
                                  ) : (
                                    <Text>يحرر</Text>
                                  )}
                                  <i
                                    class="fa fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="edit-password save-cancel-container">
                            <button
                              onClick={() => this.setState({ showModal: true })}
                              class="btnc btnc-primary"
                            >
                              Edit Password{" "}
                            </button>
                          </div>
                        </div>

                        {this.state.editprofile ? (
                          <div className="profile-edit-info">
                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "User Name"
                                ) : (
                                  <Text>الاسم الأول</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input
                                  type="text"
                                  value={
                                    profile.userName ? profile.userName : ""
                                  }
                                />
                              </div>
                            </div>
                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "First Name"
                                ) : (
                                  <Text>الاسم الأول</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input
                                  type="text"
                                  value={
                                    profile.firstName ? profile.firstName : ""
                                  }
                                  onChange={this.firstNameHandleChange}
                                />
                              </div>
                              {this.validator.message(
                                "First Name",
                                this.state.profile.firstName,
                                "required"
                              )}
                            </div>
                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Last Name"
                                ) : (
                                  <Text>الكنية</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input
                                  type="text"
                                  value={
                                    profile.lastName ? profile.lastName : ""
                                  }
                                  onChange={this.lastNameHandleChange}
                                />
                              </div>
                              {this.validator.message(
                                "Last Name",
                                this.state.profile.lastName,
                                "required"
                              )}
                            </div>

                            {/* <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Password"
                                ) : (
                                  <Text>كلمة المرور</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input type="password" value={profile.password ? profile.password : ""} onChange={this.passwordHandleChange} />
                              </div>
                            </div>


                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Confirm Password"
                                ) : (
                                  <Text>كلمة المرور</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input type="password" value={profile.password ? profile.password : ""} onChange={this.confirmpasswordHandleChange} />
                              </div>
                            </div>
 */}

                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Email"
                                ) : (
                                  <Text>بريد الالكتروني</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input
                                  type="email"
                                  value={profile.email ? profile.email : ""}
                                  onChange={this.emailHandleChange}
                                />
                              </div>
                              {this.validator.message(
                                "Email",
                                this.state.profile.email,
                                "required|email"
                              )}
                            </div>
                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Mobile"
                                ) : (
                                  <Text>متحرك</Text>
                                )}
                              </div>
                              <div className="profile-input profile-account">
                                <Select
                                  styles={style}
                                  options={options}
                                  onChange={this.ccHandleChange}
                                  placeholder={" +965"}
                                  defaultValue={options[0]}
                                />
                                <input
                                  type="text"
                                  maxLength={8}
                                  value={
                                    profile.mobilePhone
                                      ? profile.mobilePhone
                                      : ""
                                  }
                                  onChange={this.mobilePhoneHandleChange}
                                />
                              </div>
                              {this.validator.message(
                                "mobile Phone",
                                this.state.profile.mobilePhone,
                                "required|numeric"
                              )}
                            </div>

                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Date Of Birth"
                                ) : (
                                  <Text>تاريخ الولادة</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                {profile.dob ? (
                                  <DatePicker
                                    value={profile.dob}
                                    onChange={this.dobHandleChange}
                                    // showTimeSelect={false}
                                    closeOnSelect={true}
                                    closeOnClickOutside={true}
                                    timeFormat={false}
                                    placeholderText="Date"
                                    shouldCloseOnSelect={true}
                                    dateFormat="YYYY-MM-DD"
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    minDate={new Date(minYear, 0, 1)} // January 1 of the minimum year
                                    maxDate={
                                      new Date(maxYear, maxMonth, maxDay)
                                    }
                                  />
                                ) : (
                                  <DatePicker
                                    value={this.state.dobdate}
                                    onChange={this.dobHandleChange}
                                    // showTimeSelect={false}
                                    closeOnSelect={true}
                                    closeOnClickOutside={true}
                                    timeFormat={false}
                                    placeholderText="Date"
                                    shouldCloseOnSelect={true}
                                    dateFormat="YYYY-MM-DD"
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    minDate={new Date(minYear, 0, 1)} // January 1 of the minimum year
                                    maxDate={
                                      new Date(maxYear, maxMonth, maxDay)
                                    }
                                  />
                                )}
                              </div>
                              {this.validator.message(
                                "DOB",
                                profile.dob,
                                "required"
                              )}
                            </div>

                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Gender"
                                ) : (
                                  <Text>جنس</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <div className="radio-inline">
                                  <label
                                    className="radio-containers"
                                    onClick={() =>
                                      this.genderHandleChange("male")
                                    }
                                  >
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "Male"
                                    ) : (
                                      <Text>ذكر</Text>
                                    )}
                                    <input
                                      type="radio"
                                      name="sex"
                                      id=""
                                      checked={
                                        profile.gender == "male" ? true : false
                                      }
                                    />
                                    {/* <span class="checkmark option option-one"></span> */}
                                  </label>
                                </div>

                                {/* 
                                <div className="radio-btn">
                              <label for="yes" className="m-l-45">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Yes"
                                ) : (
                                  <Text>نعم</Text>
                                )}
                              </label>
                              <input
                                type="radio"
                                className="m-l-11"
                                id="yes"
                                name="fav_language"
                                value="yes"
                              />
                              <label for="No" className="m-l-45">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "No"
                                ) : (
                                  <Text>رقم</Text>
                                )}
                              </label>
                              <input
                                type="radio"
                                className="m-l-11"
                                id="No"
                                name="fav_language"
                                value="no"
                              />{" "}
                            </div> */}

                                <div className="radio-inline">
                                  <label
                                    className="radio-containers"
                                    onClick={() =>
                                      this.genderHandleChange("female")
                                    }
                                  >
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "Female"
                                    ) : (
                                      <Text>أنثى</Text>
                                    )}
                                    <input
                                      type="radio"
                                      name="sex"
                                      id=""
                                      checked={
                                        profile.gender == "female"
                                          ? true
                                          : false
                                      }
                                    />
                                    {/* <span class="checkmark option option-two"></span> */}
                                  </label>
                                </div>
                              </div>
                            </div>
                            {/* <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "City"
                                ) : (
                                  <Text>مدينة</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input
                                  type="text"
                                  value={profile.city ? profile.city : ""}
                                  onChange={this.cityHandleChange}
                                />
                              </div>
                            </div> */}

                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Receive Promotional Emails"
                                ) : (
                                  <Text>
                                    تلقي رسائل البريد الإلكتروني الترويجية
                                  </Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <div className="radio-inline">
                                  <label className="radio-containers">
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "Yes"
                                    ) : (
                                      <Text>نعم</Text>
                                    )}
                                    <input
                                      type="radio"
                                      name="promoEmail"
                                      id="ans"
                                      checked={profile.promoEmail}
                                      onClick={() =>
                                        this.promoEmailHandleChange(true)
                                      }
                                    />
                                    {/* <span class="checkmark option"></span> */}
                                  </label>
                                </div>
                                <div className="radio-inline">
                                  <label className="radio-containers">
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "No"
                                    ) : (
                                      <Text>رقم</Text>
                                    )}
                                    <input
                                      type="radio"
                                      name="promoEmail"
                                      id="ans"
                                      checked={!profile.promoEmail}
                                      onClick={() =>
                                        this.promoEmailHandleChange(false)
                                      }
                                    />
                                    {/* <span class="checkmark option"></span> */}
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Receive Mobile Notifications"
                                ) : (
                                  <Text>تلقي إخطارات المحمول</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <div className="radio-inline">
                                  <label className="radio-containers">
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "Yes"
                                    ) : (
                                      <Text>نعم</Text>
                                    )}
                                    <input
                                      type="radio"
                                      name="promoMobile"
                                      id="ans2"
                                      checked={profile.promoMobile}
                                      onClick={() =>
                                        this.promoMobileHandleChange(true)
                                      }
                                    />
                                    {/* <span class="checkmark option"></span> */}
                                  </label>
                                </div>
                                <div className="radio-inline">
                                  <label className="radio-containers">
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "No"
                                    ) : (
                                      <Text>رقم</Text>
                                    )}
                                    <input
                                      type="radio"
                                      name="promoMobile"
                                      id="ans2"
                                      checked={!profile.promoMobile}
                                      onClick={() =>
                                        this.promoMobileHandleChange(false)
                                      }
                                    />
                                    {/* <span class="checkmark option"></span> */}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="pro-info">
                              {localStorage.getItem("language") !== "ar" ? (
                                <Text>
                                  We recommend to keep the notifications ON{" "}
                                  <br />
                                  to receive your reservation notifications and
                                  reminders.
                                </Text>
                              ) : (
                                <Text>
                                  نوصي بإبقاء الإخطارات قيد التشغيل <br /> لتلقي
                                  إشعارات الحجز والتذكيرات.
                                </Text>
                              )}
                            </div>
                            <div className="save-cancel-container">
                              <button
                                className="btnc btnc-gray"
                                onClick={() => window.location.reload()}
                              >
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Cancel"
                                ) : (
                                  <Text>يلغي</Text>
                                )}
                              </button>
                              <button
                                className="btnc btnc-primary"
                                onClick={this.updateProfile}
                              >
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Save"
                                ) : (
                                  <Text>يحفظ</Text>
                                )}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="profile-edit-info">
                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "User Name"
                                ) : (
                                  <Text>الاسم الأول</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input
                                  type="text"
                                  value={
                                    profile.userName ? profile.userName : ""
                                  }
                                // onChange={this.firstNameHandleChange}
                                />
                              </div>
                            </div>
                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "First Name"
                                ) : (
                                  <Text>الاسم الأول</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input
                                  type="text"
                                  value={
                                    profile.firstName ? profile.firstName : ""
                                  }
                                // onChange={this.firstNameHandleChange}
                                />
                              </div>
                            </div>
                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Last Name"
                                ) : (
                                  <Text>الكنية</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input
                                  type="text"
                                  value={
                                    profile.lastName ? profile.lastName : ""
                                  }
                                // onChange={this.lastNameHandleChange}
                                />
                              </div>
                              {this.validator.message(
                                "Last Name",
                                this.state.profile.lastName,
                                "required"
                              )}
                            </div>
                            {/* <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Password"
                                ) : (
                                  <Text>كلمة المرور</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input type="password" readOnly value="password" />
                              </div>
                            </div>

                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Confirm Password "
                                ) : (
                                  <Text>كلمة المرور</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input type="password" readOnly value="password" />
                              </div>
                            </div> */}

                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Email"
                                ) : (
                                  <Text>بريد الالكتروني</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input
                                  type="email"
                                  value={profile.email ? profile.email : ""}
                                // onChange={this.emailHandleChange}
                                />
                              </div>
                              {this.validator.message(
                                "Email",
                                this.state.profile.email,
                                "required|email"
                              )}
                            </div>
                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Mobile"
                                ) : (
                                  <Text>متحرك</Text>
                                )}
                              </div>

                              <div className="profile-input profile-account profile-input-one">
                                <Select
                                  styles={style}
                                  options={options[0]}
                                  onChange={this.ccHandleChange}
                                  placeholder={"+965"}
                                  defaultValue={options[0]}
                                />

                                <input
                                  type="text"
                                  value={
                                    profile.mobilePhone
                                      ? profile.mobilePhone
                                      : ""
                                  }
                                // onChange={this.mobilePhoneHandleChange}
                                />
                              </div>
                              {this.validator.message(
                                "mobile Phone",
                                this.state.profile.mobilePhone,
                                "required|numeric"
                              )}
                            </div>
                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Date Of Birth"
                                ) : (
                                  <Text>تاريخ الولادة</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input
                                  type="text"
                                  value={profile.dob ? profile.dob : ""}
                                />
                              </div>
                              {/* {this.validator.message(
                                "DOB",
                                this.state.dobdate,
                                "required"
                              )} */}
                            </div>
                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Gender"
                                ) : (
                                  <Text>جنس</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <div className="radio-inline">
                                  <label className="radio-containers">
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "Male"
                                    ) : (
                                      <Text>ذكر</Text>
                                    )}
                                    <input
                                      type="radio"
                                      name="sex"
                                      id=""
                                      checked={
                                        profile.gender == "male" ? true : false
                                      }
                                    />
                                    {/* <span class="checkmark option option-one"></span> */}
                                  </label>
                                </div>
                                <div className="radio-inline">
                                  <label className="radio-containers">
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "Female"
                                    ) : (
                                      <Text>أنثى</Text>
                                    )}
                                    <input
                                      type="radio"
                                      name="sex"
                                      id=""
                                      checked={
                                        profile.gender == "female"
                                          ? true
                                          : false
                                      }
                                    />
                                    {/* <span class="checkmark option option-two"></span> */}
                                  </label>
                                </div>
                              </div>
                            </div>
                            {/* <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "City"
                                ) : (
                                  <Text>مدينة</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <input
                                  type="text"
                                  value={profile.city ? profile.city : ""}
                                />
                              </div>
                            </div> */}

                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Receive Promotional Emails"
                                ) : (
                                  <Text>
                                    تلقي رسائل البريد الإلكتروني الترويجية
                                  </Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <div className="radio-inline">
                                  <label className="radio-containers">
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "Yes"
                                    ) : (
                                      <Text>نعم</Text>
                                    )}
                                    <input
                                      type="radio"
                                      name="ans"
                                      id="ans"
                                      checked={profile.promoEmail}
                                    />
                                    {/* <span class="checkmark option"></span> */}
                                  </label>
                                </div>
                                <div className="radio-inline">
                                  <label className="radio-containers">
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "No"
                                    ) : (
                                      <Text>رقم</Text>
                                    )}
                                    <input
                                      type="radio"
                                      name="ans"
                                      id="ans"
                                      checked={!profile.promoEmail}
                                    />
                                    {/* <span class="checkmark option"></span> */}
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="profile-edit-inline">
                              <div className="profile-label">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Receive Mobile Notifications"
                                ) : (
                                  <Text>تلقي إخطارات المحمول</Text>
                                )}
                              </div>
                              <div className="profile-input">
                                <div className="radio-inline">
                                  <label className="radio-containers">
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "Yes"
                                    ) : (
                                      <Text>نعم</Text>
                                    )}
                                    <input
                                      type="radio"
                                      name="ans2"
                                      id="ans2"
                                      checked={profile.promoMobile}
                                    />
                                    {/* <span class="checkmark option"></span> */}
                                  </label>
                                </div>
                                <div className="radio-inline">
                                  <label className="radio-containers">
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "No"
                                    ) : (
                                      <Text>رقم</Text>
                                    )}
                                    <input
                                      type="radio"
                                      name="ans2"
                                      id="ans2"
                                      checked={!profile.promoMobile}
                                    />
                                    {/* <span class="checkmark option"></span> */}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="pro-info">
                              {localStorage.getItem("language") !== "ar" ? (
                                <Text>
                                  We recommend to keep the notifications ON{" "}
                                  <br />
                                  to receive your reservation notifications and
                                  reminders.
                                </Text>
                              ) : (
                                <Text>
                                  نوصي بإبقاء الإخطارات قيد التشغيل <br /> لتلقي
                                  إشعارات الحجز والتذكيرات.
                                </Text>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </TabPanel>
                  ) : (
                    ""
                  )}
                  <TabPanel>
                    <div className="upcoming-bookings myaccount-upcoming">
                      <div className="upcoming-booking-head upcoming-booking">
                        <h3>
                          {localStorage.getItem("language") !== "ar" ? (
                            "UPCOMING BOOKINGS"
                          ) : (
                            <Text>الحجوزات القادمة</Text>
                          )}
                        </h3>
                      </div>

                      {this.state.upcoming.map(function (item, i) {
                        return (
                          <>
                            {item.bookingType == "BOOKING" ? (
                              <>
                                {/* web-design */}
                                <div className="upcoming-booking-box upcoming-booking-box-web justify-flex">
                                  <div className="upcoming-booking-left">
                                    <div className="upcoming-movie-left-img">
                                      <img src={item.poster} />
                                    </div>
                                  </div>
                                  <div className="upcoming-booking-right">
                                    <div className="booking-info">
                                      <div className="boocking-name desktop-history">
                                        <div class="booking_text">
                                          <h4>{item.moviename} </h4>
                                          <button type="button" class="age_btn">
                                            {item.mcensor}
                                          </button>
                                        </div>
                                        <p> {item.language}</p>
                                      </div>
                                    </div>
                                    {/*---------------- mobile-view ------------------------------*/}
                                    <div className="movie-bookings-mobile">
                                      <div className="booking-id-container">
                                        <div class="booking_location">
                                          <p>
                                            {" "}
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Location"
                                            ) : (
                                              <Text>موقع</Text>
                                            )}
                                          </p>
                                          <h5> {item.cinemaname}</h5>
                                        </div>
                                        <div className="booking-id booking_location">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Booking ID"
                                            ) : (
                                              <Text>معرف الحجز</Text>
                                            )}
                                          </p>
                                          <h5> {item.kioskId}</h5>
                                        </div>
                                      </div>
                                      <div className="qr-contain mobile-qr">
                                        <img
                                          src={`data:image/png;base64,  ${item.qr}`}
                                        />
                                      </div>
                                      {/* mobile phone */}
                                      <div className="movie_history movie-history-mobile">
                                        <span className="movie-title-name">
                                          {item.moviename}
                                        </span>
                                        <span
                                          type="button"
                                          className="age_btn_history"
                                          style={{
                                            backgroundColor: item.ratingColor,
                                          }}
                                        >
                                          {item.mcensor}
                                        </span>
                                      </div>
                                      {/* mobile phone */}
                                    </div>

                                    {/*---------------- mobile-view ------------------------------*/}
                                    <div class="booking_location_section">
                                      <div class="booking_ticket ">
                                        <div class="booking_info">
                                          <div className="booking-id-container desktop-history">
                                            <div class="booking_location">
                                              <p>
                                                {" "}
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Location"
                                                ) : (
                                                  <Text>موقع</Text>
                                                )}
                                              </p>
                                              <h5> {item.cinemaname}</h5>
                                            </div>
                                            <div className="booking-id booking_location">
                                              <p>
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Booking ID"
                                                ) : (
                                                  <Text>معرف الحجز</Text>
                                                )}
                                              </p>
                                              <h5> {item.kioskId}</h5>
                                            </div>
                                          </div>
                                          <div className="qr-code-container">
                                            <div className="booking-time-contain">
                                              <div class="booking_time">
                                                <p>
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "Date & Time"
                                                  ) : (
                                                    <Text>التاريخ والوقت</Text>
                                                  )}
                                                </p>
                                                <h5>
                                                  {item.showDate} &nbsp;| &nbsp;
                                                  {item.showTime}{" "}
                                                </h5>
                                              </div>
                                              <div class="screen">
                                                <p>
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "Screen"
                                                  ) : (
                                                    <Text>شاشة</Text>
                                                  )}
                                                </p>
                                                <p>
                                                  {item.screenId}{" "}
                                                  <span>
                                                    <img
                                                      src={item.experienceIcon}
                                                    />
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                            <div className="qr-contain desktop-history">
                                              <img
                                                src={`data:image/png;base64,  ${item.qr}`}
                                              />
                                            </div>
                                          </div>

                                        



                                          <div className="cancel-booking-container">
                                            <div class="seats">
                                              <p>
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Seats"
                                                ) : (
                                                  <Text>المقاعد</Text>
                                                )}
                                              </p>
                                              {item.seatsArr
                                                ? item.seatsArr.map(function (
                                                  it,
                                                  i
                                                ) {
                                                  return <h2>{it}</h2>;
                                                })
                                                : ""}

                                                
                                            </div>

                                            <div class="screen screen-payment mt-24 d-none-desktop">
                                 <p>  {localStorage.getItem("language") !== "ar" ? (
                                    "Category"
                                 ) : (
                                    <Text>فئة</Text>
                                 )}</p>
                                 <h5>
                                    <b>{item.category ? item.category : ''}</b> 
                                 </h5>
                              </div>


                                           

                                         
                                            <div className="cancel-button-container cancel-profile">
                                              {/*....................... mobile view btn.................. */}

                                              <div className="view-close-section">
                                                <button
                                                  className="btnc btnc-primary"
                                                  onClick={() =>
                                                    resendbooking(
                                                      item.bookingId,
                                                      item.transId
                                                    )
                                                  }
                                                >
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    " Resend Email"
                                                  ) : (
                                                    <Text>
                                                      إعادة إرسال البريد
                                                      الإلكتروني
                                                    </Text>
                                                  )}
                                                </button>

                                                {item.cancelReserve ? (
                                                  <button
                                                    className="btnc btnc-primary"
                                                    onClick={() =>
                                                      handleConfirmation(
                                                        item.bookingId,
                                                        item.transId
                                                      )
                                                    }
                                                  >
                                                    {localStorage.getItem(
                                                      "language"
                                                    ) !== "ar" ? (
                                                      "Cancel Booking"
                                                    ) : (
                                                      <Text>إلغاء الحجز</Text>
                                                    )}
                                                  </button>
                                                ) : (
                                                  ""
                                                )}

                                                <button
                                                  className="btnc btnc-primary"
                                                  onClick={() =>
                                                    upcomingdetailsHandler(
                                                      item.bookingId
                                                    )
                                                  }
                                                >
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "View Details"
                                                  ) : (
                                                    <Text>عرض التفاصيل</Text>
                                                  )}
                                                </button>
                                                {item.food == 1 ? (
                                                  <button
                                                    className="btnc btnc-primary"
                                                    onClick={() =>
                                                      addFood(
                                                        item.bookingId,
                                                        item.transId,
                                                        item.cinemacode
                                                      )
                                                    }
                                                  >
                                                    Add Food
                                                  </button>
                                                ) : (
                                                  ""
                                                )}
                                                {item.food == 2 ? (
                                                  <button
                                                    className="btnc btnc-primary"
                                                    disabled
                                                  >
                                                     {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "Prepare Food"
                                                  ) : (
                                                    <Text>حضر الطعام</Text>
                                                  )}
                                                  </button>
                                                ) : (
                                                  ""
                                                )}
                                                {item.food == 3 ? (
                                                  <button
                                                    className="btnc btnc-primary"
                                                    onClick={() =>
                                                      prepareFood(
                                                        item.bookingId,
                                                        item.transId,
                                                        item.bookingType
                                                      )
                                                    }
                                                  >
                                                     {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "Prepare Food"
                                                  ) : (
                                                    <Text>حضر الطعام</Text>
                                                  )}
                                                  </button>
                                                ) : (
                                                  ""
                                                )}
                                              </div>

                                              {/*....................... mobile view btn.................. */}

                                              <div className="upcoming-bookings-btn upcoming-booking-profile">
                                                {item.food == 1 ? (
                                                  <button
                                                    className="btnc btnc-primary"
                                                    onClick={() =>
                                                      addFood(
                                                        item.bookingId,
                                                        item.transId,
                                                        item.cinemacode
                                                      )
                                                    }
                                                  >
                                                    Add Food
                                                  </button>
                                                ) : (
                                                  ""
                                                )}
                                                {item.food == 2 ? (
                                                  <button
                                                    className="btnc btnc-primary"
                                                    disabled
                                                  >
                                                     {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "Prepare Food"
                                                  ) : (
                                                    <Text>حضر الطعام</Text>
                                                  )}
                                                  </button>
                                                ) : (
                                                  ""
                                                )}
                                                {item.food == 3 ? (
                                                  <button
                                                    className="btnc btnc-primary"
                                                    onClick={() =>
                                                      prepareFood(
                                                        item.bookingId,
                                                        item.transId,
                                                        item.bookingType
                                                      )
                                                    }
                                                  >
                                                     {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "Prepare Food"
                                                  ) : (
                                                    <Text>حضر الطعام</Text>
                                                  )}
                                                  </button>
                                                ) : (
                                                  ""
                                                )}
                                                <button
                                                  className="btnc btnc-primary "
                                                  onClick={() =>
                                                    resendbooking(
                                                      item.bookingId,
                                                      item.transid
                                                    )
                                                  }
                                                >
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    " Resend Email"
                                                  ) : (
                                                    <Text>
                                                      إعادة إرسال البريد
                                                      الإلكتروني
                                                    </Text>
                                                  )}
                                                </button>

                                                {item.cancelReserve ? (
                                                  <button
                                                    className="btnc btnc-primary "
                                                    onClick={() =>
                                                      handleConfirmation(
                                                        item.bookingId,
                                                        item.transid
                                                      )
                                                    }
                                                  >
                                                    {localStorage.getItem(
                                                      "language"
                                                    ) !== "ar" ? (
                                                      "Cancel Booking"
                                                    ) : (
                                                      <Text>إلغاء الحجز</Text>
                                                    )}
                                                  </button>
                                                ) : (
                                                  ""
                                                )}
                                                <button
                                                  className="btnc btnc-primary "
                                                  onClick={() =>
                                                    upcomingdetailsHandler(
                                                      item.bookingId
                                                    )
                                                  }
                                                >
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    upcomingdetailsId ==
                                                      item.bookingId ? (
                                                      "Close Details"
                                                    ) : (
                                                      "View Details"
                                                    )
                                                  ) : (
                                                    <Text>عرض التفاصيل</Text>
                                                  )}
                                                </button>
                                              </div>
                                            </div>




                                          </div>

                                          
                             <div class="screen screen-payment mt-24 d-none-category booking_time">
                                 <p>  {localStorage.getItem("language") !== "ar" ? (
                                    "Category"
                                 ) : (
                                    <Text>فئة</Text>
                                 )}</p>
                                 <h5>
                                    {item.category ? item.category : ''} 
                                 </h5>
                              </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* -----------upcoming mobile view details----------- */}
                                  {upcomingdetailsId == item.bookingId ? (
                                    <div className="view-details-contain">
                                      <div class="ticket_details d-none-mobile">
                                        <div class="ticket_detail_info">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Ticket Details"
                                            ) : (
                                              <Text>تفاصيل التذكرة</Text>
                                            )}
                                          </p>
                                        </div>
                                        <div class="ticket_kd">
                                          <p>{item.totalTicketPrice}</p>
                                        </div>
                                      </div>
                                      <div class="location_infos ticket_details d-none-mobile myaccount-ticket">
                                        <div class="location_date">
                                          <div class="cinescape_location">
                                            <p>
                                              {" "}
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Location"
                                              ) : (
                                                <Text>موقع</Text>
                                              )}
                                            </p>
                                            <h5>{item.cinemaname}</h5>
                                          </div>
                                          <div class="cinescape_location upcoming-boocking">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Screen"
                                              ) : (
                                                <Text>شاشة</Text>
                                              )}
                                            </p>
                                            <h5>
                                              {item.screenId}{" "}
                                              <span>
                                                {" "}
                                                <img
                                                  src={item.experienceIcon}
                                                />
                                              </span>
                                            </h5>
                                          </div>

                                          <div class="screen screen-payment cinescape_location category-info">
                                 <p>  {localStorage.getItem("language") !== "ar" ? (
                                    "Category"
                                 ) : (
                                    <Text>فئة</Text>
                                 )}</p>
                                 <h5>
                                 
                                 {item.category ? item.category : ''} 
                                 </h5>
                              </div>


                                          
                                        </div>
                                        <div class="date_seats">
                                          <div class="cinescape_location">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Date & Time"
                                              ) : (
                                                <Text>التاريخ والوقت</Text>
                                              )}
                                            </p>
                                            <h5>
                                              {item.showDate}
                                              &nbsp;&nbsp;|&nbsp;&nbsp;
                                              {item.showTime}
                                            </h5>
                                          </div>
                                          <div class="cinescape_location screen_m screen-myaccount">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Seats"
                                              ) : (
                                                <Text>المقاعد</Text>
                                              )}
                                            </p>
                                            {item.seatsArr
                                              ? item.seatsArr.map(function (
                                                it,
                                                i
                                              ) {
                                                return <h5>{it}</h5>;
                                              })
                                              : ""}
                                          </div>
                                        </div>
                                      </div>
                                      


                                      {item.concessionFoods.length > 0 ? (
                                        <>
                                          <div class="ticket_details ticket-details-mobile">
                                            <div class="ticket_detail_info">
                                              <p>
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Food Details"
                                                ) : (
                                                  <Text>تفاصيل الغذاء</Text>
                                                )}
                                              </p>
                                            </div>
                                            <div class="ticket_kd d-none-mobile">
                                              <p> {item.foodTotal} </p>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {item.concessionFoods.length > 0 ? (
                                        <div class="food_table">
                                          <table class="item_info">
                                            <tr style={{ textAlign: "left" }}>
                                              <th>
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Item"
                                                ) : (
                                                  <Text>غرض</Text>
                                                )}
                                              </th>
                                              <th
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Qty"
                                                ) : (
                                                  <Text>كمية</Text>
                                                )}
                                              </th>
                                              <th
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Price"
                                                ) : (
                                                  <Text>السعر</Text>
                                                )}
                                              </th>
                                              <th
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Total"
                                                ) : (
                                                  <Text>المجموع</Text>
                                                )}
                                              </th>
                                            </tr>
                                            {item.concessionFoods
                                              ? item.concessionFoods.map(
                                                function (it, i) {
                                                  return (
                                                    <>
                                                      <tr>
                                                        <td width="50%">
                                                          <div class="food_details">
                                                            <div class="food_img">
                                                              <img
                                                                src={
                                                                  require("../../assets/popcorn.png")
                                                                    .default
                                                                }
                                                              />
                                                            </div>
                                                            <div class="food_info">
                                                              {/* 
                                             <h5>Popcorn</h5>
                                             */}
                                                              <p>
                                                                {
                                                                  it.description
                                                                }
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td
                                                          width="16%"
                                                          style={{
                                                            textAlign:
                                                              "center",
                                                          }}
                                                        >
                                                          {it.quantity}
                                                        </td>
                                                        <td
                                                          width="16%"
                                                          style={{
                                                            textAlign:
                                                              "center",
                                                          }}
                                                        >
                                                          KWD{" "}
                                                          {typeof it.priceInCents ===
                                                            "number"
                                                            ? (
                                                              it.priceInCents /
                                                              100
                                                            ).toFixed(3)
                                                            : ""}
                                                        </td>
                                                        <td
                                                          width="16%"
                                                          style={{
                                                            textAlign:
                                                              "center",
                                                          }}
                                                        >
                                                          KWD{" "}
                                                          {(
                                                            (it.priceInCents *
                                                              it.quantity) /
                                                            100
                                                          ).toFixed(3)}
                                                        </td>
                                                      </tr>
                                                    </>
                                                  );
                                                }
                                              )
                                              : ""}
                                          </table>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {/*---------------------------------------- -------mobile view total price----------------------------------------- */}

                                      <div className="mobile-total-price">

                                        
                                      <div className="cinescape_bookings">
                                          <div class="ticket_details">
                                            <div class="ticket_detail_info">
                                              <p>Transaction Details</p>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="location_infos ticket_details">
                                          <div class="location_date">
                                            <div class="cinescape_location">
                                              <p> Track ID</p>
                                              <h5>{item.bookingId}</h5>
                                            </div>
                                            <div class="cinescape_location">
                                              <p> Transaction ID</p>
                                              <h5>{item.vistaNumber}</h5>
                                            </div>
                                            <div class="cinescape_location">
                                              <p> Transaction Date & Time</p>
                                              <h5>{item.bookingTime}</h5>
                                            </div>
                                            {item.payStatus != "" ? (
                                              <div class="cinescape_location">
                                                <p>Result</p>
                                                <h5>{item.payStatus}</h5>
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            <div class="cinescape_location">
                                              <p>Payment Mode</p>
                                              <h5>{item.payDone}</h5>
                                            </div>
                                          </div>
                                          <div class="date_seats">
                                            {item.authCode != "" ? (
                                              <div class="cinescape_location">
                                                <p>Authorization Code </p>
                                                <h5>{item.authCode}</h5>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {item.referenceId != "" ? (
                                              <div class="cinescape_location">
                                                <p>Payment ID</p>
                                                <h5>{item.referenceId}</h5>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>

                                        <div className="ticket-details-section">
                                          <div className="ticket-text-info">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "TICKET PRICE"
                                              ) : (
                                                <Text>تفاصيل التذكرة</Text>
                                              )}
                                            </p>
                                          </div>

                                          <div className="price-text-info">
                                            <p>{item.totalTicketPrice}</p>
                                          </div>
                                        </div>

                                        <div className="ticket-details-section">
                                          <div className="ticket-text-info">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "FOOD PRICE"
                                              ) : (
                                                <Text>تفاصيل التذكرة</Text>
                                              )}
                                            </p>
                                          </div>

                                          <div className="price-text-info">
                                            <p> {item.foodTotal} </p>
                                          </div>
                                        </div>

                                        <div className="ticket-details-section">
                                          <div className="ticket-text-info">
                                            <p>
                                              <b>
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "GRAND TOTAL"
                                                ) : (
                                                  <Text>تفاصيل التذكرة</Text>
                                                )}
                                              </b>
                                            </p>
                                          </div>

                                          <div className="price-text-info">
                                            <p>
                                              <b>
                                                {" "}
                                                <b>{item.totalPrice}</b>
                                              </b>{" "}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="ticket-details-section payment-info-section">
                                          <div className="ticket-text-info">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Payment Mode"
                                              ) : (
                                                <Text>تفاصيل التذكرة</Text>
                                              )}
                                            </p>
                                          </div>

                                          <div className="price-text-info">
                                            <p>
                                              <b>{item.payDone}</b>
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      {/*---------------------------------------- -------  Upcoming  mobile view total price end----------------------------------------- */}

                                      <div className="cinescape_bookings-infos d-none-mobile">
                                        <div class="ticket_details">
                                          <div class="ticket_detail_info">
                                            <p>Transaction Details</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="location_infos ticket_details d-none-mobile">
                                        <div class="location_date">
                                          <div class="cinescape_location">
                                            <p> Track ID</p>
                                            <h5>{item.bookingId}</h5>
                                          </div>
                                          <div class="cinescape_location">
                                            <p> Transaction ID</p>
                                            <h5>{item.vistaNumber}</h5>
                                          </div>
                                          <div class="cinescape_location">
                                            <p> Transaction Date & Time</p>
                                            <h5>{item.bookingTime}</h5>
                                          </div>
                                          {item.payStatus != "" ? (
                                            <div class="cinescape_location">
                                              <p>Result</p>
                                              <h5>{item.payStatus}</h5>
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          <div class="cinescape_location">
                                            <p>Payment Mode</p>
                                            <h5>{item.payDone}</h5>
                                          </div>
                                        </div>
                                        <div class="date_seats">
                                          {item.authCode != "" ? (
                                            <div class="cinescape_location">
                                              <p>Authorization Code </p>
                                              <h5>{item.authCode}</h5>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {item.referenceId != "" ? (
                                            <div class="cinescape_location">
                                              <p>Payment ID</p>
                                              <h5>{item.referenceId}</h5>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      {/* TICKET DETAILS */}

                                      <div class="ticket_price d-none-mobile">
                                        <table width="80%">
                                          <tr style={{ textAlign: "left" }}>
                                            <th width="20%">
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Ticket Price"
                                              ) : (
                                                <Text>سعر التذكرة</Text>
                                              )}
                                            </th>
                                            <th width="20%">
                                              {item.ticketPrice}{" "}
                                            </th>
                                            <th width="30%">
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Payment Mode"
                                              ) : (
                                                <Text>طريقة الدفع</Text>
                                              )}
                                            </th>
                                            <th width="35%">
                                              <b>
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Grand Total"
                                                ) : (
                                                  <Text>المبلغ الإجمالي</Text>
                                                )}
                                              </b>
                                            </th>
                                          </tr>

                                          <tr>
                                            {item.concessionFoods.length > 0 ? (
                                              <>
                                                <td width="10%">
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "Food Price"
                                                  ) : (
                                                    <Text>سعر الغذاء</Text>
                                                  )}
                                                </td>
                                                <td width="35%">
                                                  {item.foodTotal}
                                                </td>
                                              </>
                                            ) : (
                                              <>
                                                <td></td>
                                                <td></td>
                                              </>
                                            )}
                                            <td width="20%">
                                              <b>{item.payDone}</b>
                                            </td>
                                            <td width="35%">
                                              <b>{item.totalPrice}</b>
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                {/* mobile-design */}
                                <div className="upcoming-booking-box-mobile d-none">
                                  <div className="upcoming-booking-box-preview">
                                    <div className="upcoming-movie-border">
                                      <div className="upcoming-movie-poster">
                                        <img src={item.poster} />
                                      </div>
                                      <div className="upcoming-booking-details">
                                        <div className="booking-info-qr">
                                          <div className="booking-id booking_location">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Booking ID"
                                              ) : (
                                                <Text>معرف الحجز</Text>
                                              )}{" "}
                                            </p>
                                            <h5>{item.kioskId}</h5>
                                          </div>
                                          {item.pickUpNumber &&
                                            item.pickUpNumber.length > 0 ? (
                                            <div className="booking-id booking_location">
                                              <p>
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "FOOD PICK-UP NUMBER"
                                                ) : (
                                                  <Text>رقم استلام الطعام</Text>
                                                )}
                                              </p>
                                              <h5>{item.pickUpNumber}</h5>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <div className="qr-contain">
                                            <img
                                              src={`data:image/png;base64,  ${item.qr}`}
                                            />
                                          </div>
                                        </div>
                                        <div class="booking-upcoming-head">
                                          <h4>
                                            {item.moviename}
                                            <span
                                              className="age-highlight"
                                              style={{
                                                backgroundColor:
                                                  item.ratingColor,
                                              }}
                                            >
                                              {item.mcensor}
                                            </span>
                                          </h4>
                                        </div>
                                        <p className="upcoming-lang">
                                          {" "}
                                          {item.language}
                                        </p>
                                        <div class="booking_location_section">
                                          <div class="booking_ticket ">
                                            <div class="booking_info">
                                              <div className="booking-id-container">
                                                <div class="booking_location">
                                                  <p>
                                                    {" "}
                                                    {localStorage.getItem(
                                                      "language"
                                                    ) !== "ar" ? (
                                                      "LOCATION"
                                                    ) : (
                                                      <Text>موقع</Text>
                                                    )}
                                                  </p>
                                                  <h5> {item.cinemaname}</h5>
                                                </div>
                                              </div>
                                              <div className="qr-code-container">
                                                <div className="booking-time-contain">
                                                  <div class="booking_time">
                                                    <p>
                                                      {localStorage.getItem(
                                                        "language"
                                                      ) !== "ar" ? (
                                                        "DATE & TIME"
                                                      ) : (
                                                        <Text>
                                                          التاريخ والوقت
                                                        </Text>
                                                      )}
                                                    </p>
                                                    <h5>
                                                      {item.showDate} |{" "}
                                                      {item.showTime}{" "}
                                                    </h5>
                                                  </div>
                                                  <div class="screen">
                                                    <p>
                                                      {localStorage.getItem(
                                                        "language"
                                                      ) !== "ar" ? (
                                                        "SCREEN"
                                                      ) : (
                                                        <Text>شاشة</Text>
                                                      )}
                                                    </p>
                                                    <p>
                                                      {item.screenId}
                                                      <span>
                                                        {" "}
                                                        <img
                                                          src={
                                                            item.experienceIcon
                                                          }
                                                        />
                                                      </span>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="cancel-booking-container ">
                                                <div class="seats">
                                                  <p>
                                                    {" "}
                                                    {localStorage.getItem(
                                                      "language"
                                                    ) !== "ar" ? (
                                                      "SEATS"
                                                    ) : (
                                                      <Text>المقاعد</Text>
                                                    )}
                                                  </p>
                                                  {item.seatsArr
                                                    ? item.seatsArr.map(
                                                      function (it, i) {
                                                        return <h2>{it}</h2>;
                                                      }
                                                    )
                                                    : ""}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="details-btn-toggle">
                                        <div className="toggle-contain">
                                          <div className="details-container">
                                            {/* 
                                       <button className="btnc btnc-primary">
                                          {localStorage.getItem("language") !== "ar" ? (
                                          "View Details"
                                          ) : (
                                          <Text>عرض التفاصيل</Text>
                                          )}
                                       </button>
                                       */}
                                            <button
                                              className="btnc btnc-primary"
                                              onClick={() =>
                                                upcomingdetailsHandler(
                                                  item.bookingId
                                                )
                                              }
                                            >
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                item.bookingid ==
                                                  upcomingdetailsId ? (
                                                  "Close Details"
                                                ) : (
                                                  "View Details"
                                                )
                                              ) : (
                                                <Text>عرض التفاصيل</Text>
                                              )}
                                            </button>
                                          </div>
                                          <div className="cancel-button-container ">
                                            <button
                                              className="btnc btnc-primary"
                                              onClick={() =>
                                                resendbooking(
                                                  item.bookingId,
                                                  item.transid
                                                )
                                              }
                                            >
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                " Resend Email"
                                              ) : (
                                                <Text>
                                                  إعادة إرسال البريد الإلكتروني
                                                </Text>
                                              )}
                                            </button>

                                            {item.cancelReserve ? (
                                              <button
                                                className="btnc btnc-primary"
                                                onClick={() =>
                                                  handleConfirmation(
                                                    item.bookingId,
                                                    item.transid
                                                  )
                                                }
                                              >
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Cancel Booking"
                                                ) : (
                                                  <Text>إلغاء الحجز</Text>
                                                )}
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                        {/* -----------details----------- */}
                                        {upcomingdetailsId == item.bookingId ? (
                                          <div className="view-details-contain">
                                            {item.concessionFoods.length > 0 ? (
                                              <>
                                                <div class="ticket_details">
                                                  <div class="ticket_detail_info">
                                                    <p>
                                                      {" "}
                                                      {localStorage.getItem(
                                                        "language"
                                                      ) !== "ar" ? (
                                                        "Food Details"
                                                      ) : (
                                                        <Text>
                                                          تفاصيل الغذاء
                                                        </Text>
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="food_table">
                                                  <table class="item_info">
                                                    <tr
                                                      style={{
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      <th>
                                                        {" "}
                                                        {localStorage.getItem(
                                                          "language"
                                                        ) !== "ar" ? (
                                                          "Item"
                                                        ) : (
                                                          <Text>غرض</Text>
                                                        )}
                                                      </th>
                                                      <th>
                                                        {" "}
                                                        {localStorage.getItem(
                                                          "language"
                                                        ) !== "ar" ? (
                                                          "Qty"
                                                        ) : (
                                                          <Text>كمية</Text>
                                                        )}{" "}
                                                      </th>
                                                      <th>
                                                        {" "}
                                                        {localStorage.getItem(
                                                          "language"
                                                        ) !== "ar" ? (
                                                          "Price"
                                                        ) : (
                                                          <Text>السعر</Text>
                                                        )}
                                                      </th>
                                                      <th>
                                                        {localStorage.getItem(
                                                          "language"
                                                        ) !== "ar" ? (
                                                          "Total"
                                                        ) : (
                                                          <Text>المجموع</Text>
                                                        )}
                                                      </th>
                                                    </tr>
                                                    <tr>
                                                      <td width="50%">
                                                        <div class="food_details">
                                                          <div class="food_img">
                                                            <img
                                                              src={
                                                                require("../../assets/popcorn.png")
                                                                  .default
                                                              }
                                                            />
                                                          </div>
                                                          <div class="food_info">
                                                            <h5>Popcorn</h5>
                                                            <p>Medium Salt</p>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td width="10%">2</td>
                                                      <td width="10%">1800</td>
                                                      <td width="10%">4500</td>
                                                    </tr>
                                                    <tr>
                                                      <td width="50%">
                                                        <div class="food_details">
                                                          <div class="food_img">
                                                            <img
                                                              src={
                                                                require("../../assets/slush.png")
                                                                  .default
                                                              }
                                                            />
                                                          </div>
                                                          <div class="food_info">
                                                            <h5>Slush</h5>
                                                            <p>
                                                              Medium Mountain
                                                              Dew
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td width="10%">3</td>
                                                      <td width="10%">3000</td>
                                                      <td width="10%">3000</td>
                                                    </tr>
                                                    <tr>
                                                      <td width="50%">
                                                        <div class="food_details">
                                                          <div class="food_img">
                                                            <img
                                                              src={
                                                                require("../../assets/popcorn.png")
                                                                  .default
                                                              }
                                                            />
                                                          </div>
                                                          <div class="food_info">
                                                            <h5>Nachos</h5>
                                                            <p>
                                                              Extra Cheese +
                                                              Chillies
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td width="10%">2</td>
                                                      <td width="10%">1800</td>
                                                      <td width="10%">4500</td>
                                                    </tr>
                                                    <tr>
                                                      <td width="50%">
                                                        <div class="food_details">
                                                          <div class="food_img">
                                                            <img
                                                              src={
                                                                require("../../assets/soft_drink.png")
                                                                  .default
                                                              }
                                                            />
                                                          </div>
                                                          <div class="food_info">
                                                            <h5>Soft Drink</h5>
                                                            <p>Medium Pepsi </p>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td width="10%">2</td>
                                                      <td width="10%">1750</td>
                                                      <td width="10%">4500</td>
                                                    </tr>
                                                  </table>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            <div class="ticket_price">
                                              <table width="100%">
                                                <tr>
                                                  <td width="50%">
                                                    {localStorage.getItem(
                                                      "language"
                                                    ) !== "ar" ? (
                                                      "Ticket Price"
                                                    ) : (
                                                      <Text>سعر التذكرة</Text>
                                                    )}
                                                  </td>
                                                  <td width="50%"> 22.500 </td>
                                                </tr>
                                                <tr>
                                                  {item.concessionFoods.length >
                                                    0 ? (
                                                    <>
                                                      <td width="10%">
                                                        {localStorage.getItem(
                                                          "language"
                                                        ) !== "ar" ? (
                                                          "Food Price"
                                                        ) : (
                                                          <Text>
                                                            سعر الغذاء
                                                          </Text>
                                                        )}
                                                      </td>
                                                      <td width="35%">
                                                        {item.foodTotal}
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <td></td>
                                                      <td></td>
                                                    </>
                                                  )}
                                                </tr>
                                                <tr>
                                                  <td width="50%">
                                                    <b>
                                                      {localStorage.getItem(
                                                        "language"
                                                      ) !== "ar" ? (
                                                        "Grand Total"
                                                      ) : (
                                                        <Text>
                                                          المبلغ الإجمالي
                                                        </Text>
                                                      )}
                                                    </b>
                                                  </td>
                                                  <td width="50%">
                                                    <b> 43.000</b>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td width="50%">
                                                    {localStorage.getItem(
                                                      "language"
                                                    ) !== "ar" ? (
                                                      "Payment Mode"
                                                    ) : (
                                                      <Text>طريقة الدفع</Text>
                                                    )}
                                                  </td>
                                                  <td width="50%"></td>
                                                </tr>
                                                <tr>
                                                  <td width="50%">
                                                    <b>{item.payDone}</b>
                                                  </td>
                                                  <td width="50%">
                                                    <b></b>
                                                  </td>
                                                </tr>
                                              </table>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {item.bookingType == "CLUBRECHARGE" ? (
                              <div className="booking_history_box boocking-wallet">
                                <div className="booking_history">
                                  <div className="booking_img">
                                    <div className="movie_img wallet-img">
                                      <img
                                        src={
                                          require("../../assets/Wallet Big.png")
                                            .default
                                        }
                                        alt="img"
                                      />
                                    </div>
                                  </div>
                                  <div className="movie_section movie-food">
                                    <div className="movie_history desktop-history">
                                      <h2>WALLET RECHARGE</h2>
                                    </div>

                                    <div className="history_info wallet-history wallet-card">
                                      <div className="location-section wallet-location">
                                        <p>
                                          {localStorage.getItem("language") !==
                                            "ar" ? (
                                            "DATE & TIME"
                                          ) : (
                                            <Text>التاريخ والوقت</Text>
                                          )}
                                        </p>
                                        <div className="history_date">
                                          <h3 className="history_months">
                                            {item.showDate}
                                            &nbsp;&nbsp;|&nbsp;&nbsp;
                                            {item.showTime}{" "}
                                          </h3>
                                        </div>
                                      </div>
                                      <div className="booking_id wallet-location">
                                        <p>
                                          {localStorage.getItem("language") !==
                                            "ar" ? (
                                            "Booking ID"
                                          ) : (
                                            <Text>معرف الحجز</Text>
                                          )}
                                        </p>
                                        <h3>{item.kioskId}</h3>
                                      </div>
                                    </div>

                                    {upcomingdetailsId == item.bookingId ? (
                                      <>
                                        <div className="history_info wallet-history">
                                          <div className="location-section wallet-history">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Payment Mode"
                                              ) : (
                                                <Text>طريقة الدفع</Text>
                                              )}
                                            </p>
                                            <div className="history_date">
                                              <h3 className="history_months">
                                                {item.payDone}
                                              </h3>
                                            </div>
                                          </div>
                                          <div className="booking_id">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Amount"
                                              ) : (
                                                <Text>كمية</Text>
                                              )}
                                            </p>
                                            <h3>{item.totalPrice}</h3>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    <div className="screen_imax">
                                      <div className="screen_section">
                                        <p></p>
                                      </div>
                                      <div className="view_details">
                                        <button
                                          type="button"
                                          className="btnc btnc-primary"
                                          onClick={() =>
                                            upcomingdetailsHandler(
                                              item.bookingId
                                            )
                                          }
                                        >
                                          {localStorage.getItem("language") !==
                                            "ar" ? (
                                            detailsId == item.bookingId ? (
                                              "Close Details"
                                            ) : (
                                              "View Details"
                                            )
                                          ) : (
                                            <Text>عرض التفاصيل</Text>
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {item.bookingType == "FOOD" ? (
                              <div className="booking_history_box boocking-wallet">
                                <div className="booking_history">
                                  <div className="booking_img">
                                    <div className="movie_img wallet-img">
                                      <img
                                        src={
                                          require("../../assets/Food Big.png")
                                            .default
                                        }
                                        alt="img"
                                      />
                                    </div>
                                  </div>
                                  <div className="movie_section movie-food">
                                    <div className="movie_history desktop-history">
                                      <h2>FOOD ORDER</h2>
                                    </div>
                                    <div className="history_info">
                                      <div className="location-section">
                                        <p>
                                          {localStorage.getItem("language") !==
                                            "ar" ? (
                                            "DATE & TIME"
                                          ) : (
                                            <Text>التاريخ والوقت</Text>
                                          )}
                                        </p>
                                        <div className="history_date">
                                          <h3 className="history_months">
                                            {item.showDate}
                                            &nbsp;&nbsp;|&nbsp;&nbsp;
                                            {item.showTime}
                                          </h3>
                                        </div>
                                      </div>
                                      <div className="booking_id">
                                        <p>
                                          {localStorage.getItem("language") !==
                                            "ar" ? (
                                            "Food Pickup ID"
                                          ) : (
                                            <Text>معرف الحجز</Text>
                                          )}
                                        </p>
                                        <h3>{item.kioskId}</h3>
                                      </div>
                                    </div>
                                    <div className="screen_imax">
                                      <div className="screen_section">
                                        <p></p>
                                      </div>
                                      <div className="view_details">
                                        <button
                                          type="button"
                                          className="btnc btnc-primary"
                                          onClick={() =>
                                            upcomingdetailsHandler(
                                              item.bookingId
                                            )
                                          }
                                        >
                                          {localStorage.getItem("language") !==
                                            "ar" ? (
                                            detailsId == item.bookingId ? (
                                              "Close Details"
                                            ) : (
                                              "View Details"
                                            )
                                          ) : (
                                            <Text>عرض التفاصيل</Text>
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {upcomingdetailsId == item.bookingId ? (
                                  <>
                                    {/* Food Details */}

                                    {/* <div class="ticket_details"><div class="ticket_detail_info"><p>Food Details</p></div><div class="ticket_kd"><p> KWD 1.650     </p></div></div> */}

                                    <div class="food_table">
                                      <table class="item_info">
                                        <tr style={{ textAlign: "left" }}>
                                          <th>
                                            {" "}
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Item"
                                            ) : (
                                              <Text>غرض</Text>
                                            )}
                                          </th>
                                          <th style={{ textAlign: "center" }}>
                                            {" "}
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Qty"
                                            ) : (
                                              <Text>كمية</Text>
                                            )}{" "}
                                          </th>
                                          <th style={{ textAlign: "center" }}>
                                            {" "}
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Price"
                                            ) : (
                                              <Text>السعر</Text>
                                            )}
                                          </th>
                                          <th style={{ textAlign: "center" }}>
                                            {" "}
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Total"
                                            ) : (
                                              <Text>المجموع</Text>
                                            )}
                                          </th>
                                        </tr>

                                        {item.concessionFoods.map((food) => {
                                          totalFood =
                                            (food.priceInCents / 100) *
                                            food.quantity;
                                          console.log(totalFood);
                                          return (
                                            <tr>
                                              <td width="50%">
                                                <div className="food_details">
                                                  <div className="food_img ">
                                                    <img
                                                      src={
                                                        require("../../assets/popcorn.png")
                                                          .default
                                                      }
                                                      alt="img"
                                                    />
                                                    <div className="food_info">
                                                      <h5>
                                                        {food.description}
                                                      </h5>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                              <td
                                                width="16%"
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {food.quantity}
                                              </td>
                                              <td
                                                width="16%"
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                KWD{" "}
                                                {(
                                                  food.priceInCents / 100
                                                ).toFixed(3)}
                                              </td>
                                              <td
                                                width="16%"
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                KWD{" "}
                                                {(
                                                  (food.priceInCents / 100) *
                                                  food.quantity
                                                ).toFixed(3)}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </table>
                                    </div>

                                    {/* Food Details */}

                                    {/* trans DETAILS */}

                                    <div class="location_infos ticket_details d-none-myaccount">
                                      <div class="location_date">
                                        <div class="cinescape_location">
                                          <p> Track ID</p>
                                          <h5>{item.bookingId}</h5>
                                        </div>
                                        {item.authCode != "" ? (
                                          <div class="cinescape_location">
                                            <p>Authorization Code </p>
                                            <h5>{item.authCode}</h5>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {item.referenceId != "" ? (
                                          <div class="cinescape_location">
                                            <p>Payment ID</p>
                                            <h5>{item.referenceId}</h5>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div class="cinescape_location">
                                          <p> Transaction Date & Time</p>
                                          <h5>{item.bookingTime}</h5>
                                        </div>
                                        {item.payStatus != "" ? (
                                          <div class="cinescape_location">
                                            <p>Result</p>
                                            <h5>{item.payStatus}</h5>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div class="date_seats">
                                        <div class="cinescape_location">
                                          <p>Payment Mode</p>
                                          <h5>{item.payDone}</h5>
                                        </div>
                                      </div>
                                    </div>

                                    {/* trans DETAILS */}

                                    {/* TICKET PRICE	 */}

                                    <div class="ticket_price d-none-myaccount">
                                      <table width="80%">
                                        <tr>
                                          <td width="10%">
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Food Price"
                                            ) : (
                                              <Text>سعر الغذاء</Text>
                                            )}
                                          </td>
                                          <td width="35%">{item.foodTotal}</td>
                                          {/* <td width="20%">
                                              <b>
                                                KWD 1.750
                                              </b>
                                            </td> */}
                                          <td width="35%">
                                            <b>{item.payDone} </b>
                                          </td>
                                        </tr>
                                      </table>
                                    </div>

                                    {/*---------------------------------------- -------mobile view total price----------------------------------------- */}

                                    <div className="mobile-total-price">
                                      <div className="ticket-details-section">
                                        <div className="ticket-text-info">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "TICKET PRICE"
                                            ) : (
                                              <Text>تفاصيل التذكرة</Text>
                                            )}
                                          </p>
                                        </div>

                                        <div className="price-text-info">
                                          <p>{item.totalTicketPrice}</p>
                                        </div>
                                      </div>

                                      <div className="ticket-details-section">
                                        <div className="ticket-text-info">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "FOOD PRICE"
                                            ) : (
                                              <Text>تفاصيل التذكرة</Text>
                                            )}
                                          </p>
                                        </div>

                                        <div className="price-text-info">
                                          <p> {item.foodTotal} </p>
                                        </div>
                                      </div>

                                      <div className="ticket-details-section">
                                        <div className="ticket-text-info">
                                          <p>
                                            <b>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "GRAND TOTAL"
                                              ) : (
                                                <Text>تفاصيل التذكرة</Text>
                                              )}
                                            </b>
                                          </p>
                                        </div>

                                        <div className="price-text-info">
                                          <p>
                                            <b>
                                              {" "}
                                              <b>{item.totalPrice}</b>
                                            </b>{" "}
                                          </p>
                                        </div>
                                      </div>

                                      <div className="ticket-details-section payment-info-section">
                                        <div className="ticket-text-info">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Payment Mode"
                                            ) : (
                                              <Text>تفاصيل التذكرة</Text>
                                            )}
                                          </p>
                                        </div>

                                        <div className="price-text-info">
                                          <p>
                                            <b>{item.payDone}</b>
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    {/* -----------------------------------------------------mobile view total price---------------------------------------- */}

                                    {/* TICKET PRICE	 */}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="Preferences-section cinema_text_section">
                      <div className="preferences-head">
                        <h3>
                          {" "}
                          {localStorage.getItem("language") !== "ar" ? (
                            "Preferences"
                          ) : (
                            <Text>التفضيلات</Text>
                          )}
                        </h3>
                        <p className="pref-section-info">
                          {localStorage.getItem("language") !== "ar" ? (
                            "Choose your preference from the below options to help us personalize your experience"
                          ) : (
                            <Text>
                              اختر ما تفضله من الخيارات أدناه لمساعدتنا في تخصيص
                              تجربتك
                            </Text>
                          )}
                        </p>
                      </div>
                      <div className="select-section-info">
                        <div className="select-section select-location">
                          <label for="location">
                            <h4>
                              {" "}
                              {localStorage.getItem("language") !== "ar" ? (
                                "LOCATION"
                              ) : (
                                <Text>موقع</Text>
                              )}
                            </h4>
                          </label>
                          <select
                            name="cars"
                            id="cars"
                            className="select-kd select-location-info"
                            onChange={this.cinemaSelect}
                          >
                            {this.state.cinema.map(function (item, i) {
                              let sel = "";
                              if (item.name == prefcinema) sel = "selected";
                              return (
                                <option value={item.name} selected={sel}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="seat-type-info">
                        <div className="seat-category">
                          <h4>
                            {localStorage.getItem("language") !== "ar" ? (
                              "SEAT CATEGORY"
                            ) : (
                              <Text>فئة المقاعد</Text>
                            )}
                          </h4>
                          <div className="family-img-contain">
                            <div className="family-contain">
                              <div
                                className={`family-img ${this.state.prefseatCategory == "Family"
                                    ? "active"
                                    : ""
                                  }`}
                                onClick={() =>
                                  this.seatCategorySelect("Family")
                                }
                              >
                                {" "}
                                <img
                                  src={
                                    require("../../assets/family.png").default
                                  }
                                  alt="img"
                                  className="fam-img"
                                />{" "}
                                <img
                                  src={
                                    require("../../assets/family-red.png")
                                      .default
                                  }
                                  alt="img"
                                  className="fam-img"
                                />{" "}
                              </div>
                              <p>Family</p>
                            </div>

                            <div className="family-contain">
                              <div
                                className={`family-img ${this.state.prefseatCategory == "Bachelor"
                                    ? "active"
                                    : ""
                                  }`}
                                onClick={() =>
                                  this.seatCategorySelect("Bachelor")
                                }
                              >
                                {" "}
                                <img
                                  src={
                                    require("../../assets/bachelors.png")
                                      .default
                                  }
                                  alt="img"
                                />{" "}
                                <img
                                  src={
                                    require("../../assets/bachelors-red.png")
                                      .default
                                  }
                                  alt="img"
                                  className="fam-img"
                                />{" "}
                              </div>
                              <p>Bachelor</p>
                            </div>
                          </div>
                        </div>

                        <div className="seat-category">
                          <h4>
                            {localStorage.getItem("language") !== "ar" ? (
                              "SEAT TYPE"
                            ) : (
                              <Text>نوع المقعد </Text>
                            )}
                          </h4>
                          <div className="family-img-contain">
                            <div className="family-contain">
                              <div
                                className={`family-img ${this.state.prefseatType == "Standard"
                                    ? "active"
                                    : ""
                                  }`}
                                onClick={() => this.seatTypeSelect("Standard")}
                              >
                                {" "}
                                <img
                                  src={require("../../assets/gold.png").default}
                                  alt="img"
                                />{" "}
                                <img
                                  src={
                                    require("../../assets/gold-red.png").default
                                  }
                                  alt="img"
                                />{" "}
                              </div>
                              <p>Standard</p>
                            </div>

                            <div className="family-contain">
                              <div
                                className={`family-img ${this.state.prefseatType == "Premium"
                                    ? "active"
                                    : ""
                                  }`}
                                onClick={() => this.seatTypeSelect("Premium")}
                              >
                                {" "}
                                <img
                                  src={
                                    require("../../assets/premium.png").default
                                  }
                                  alt="img"
                                />{" "}
                                <img
                                  src={
                                    require("../../assets/premium-red.png")
                                      .default
                                  }
                                  alt="img"
                                />{" "}
                              </div>
                              <p>Premium</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="experience-section">
                        <h4>
                          {" "}
                          {localStorage.getItem("language") !== "ar" ? (
                            "EXPERIENCE"
                          ) : (
                            <Text>خبرة</Text>
                          )}
                        </h4>
                        <div className="img-experience">
                          {" "}
                          <div
                            className={`img-exp`}
                            onClick={() => {
                              this.experienceSelect("STANDARD");
                            }}
                          >
                            {this.state.prefexperience.includes("STANDARD") ? (
                              <img
                                src={
                                  require("../../assets/Standard Normal@3x.png")
                                    .default
                                }
                                alt="img"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../assets/Standard Active@3x.png")
                                    .default
                                }
                                alt="img"
                              />
                            )}{" "}
                          </div>
                          <div
                            className={`img-exp `}
                            onClick={() => {
                              this.experienceSelect("VIP");
                            }}
                          >
                            {this.state.prefexperience.includes("VIP") ? (
                              <img
                                src={
                                  require("../../assets/VIP Normal@3x.png")
                                    .default
                                }
                                alt="img"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../assets/VIP Active@3x - Copy.png")
                                    .default
                                }
                                alt="img"
                              />
                            )}{" "}
                          </div>
                          <div
                            className={`img-exp `}
                            onClick={() => {
                              this.experienceSelect("PREMIUM");
                            }}
                          >
                            {this.state.prefexperience.includes("PREMIUM") ? (
                              <img
                                src={
                                  require("../../assets/Premium Normal@3x.png")
                                    .default
                                }
                                alt="img"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../assets/Premium Active@3x.png")
                                    .default
                                }
                                alt="img"
                              />
                            )}{" "}
                          </div>
                          <div
                            className={`img-exp `}
                            onClick={() => {
                              this.experienceSelect("DOLBY");
                            }}
                          >
                            {this.state.prefexperience.includes("DOLBY") ? (
                              <img
                                src={
                                  require("../../assets/Dolby Cinema Normal@3x.png")
                                    .default
                                }
                                alt="img"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../assets/Dolby Cinema Active@3x.png")
                                    .default
                                }
                                alt="img"
                              />
                            )}{" "}
                          </div>
                          <div
                            className={`img-exp `}
                            onClick={() => {
                              this.experienceSelect("IMAX");
                            }}
                          >
                            {this.state.prefexperience.includes("IMAX") ? (
                              <img
                                src={
                                  require("../../assets/IMAX Normal@3x.png")
                                    .default
                                }
                                alt="img"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../assets/IMAX Active@3x.png")
                                    .default
                                }
                                alt="img"
                              />
                            )}{" "}
                          </div>
                          <div
                            className={`img-exp `}
                            onClick={() => {
                              this.experienceSelect("4DX");
                            }}
                          >
                            {this.state.prefexperience.includes("4DX") ? (
                              <img
                                src={
                                  require("../../assets/4DX Normal@3x.png")
                                    .default
                                }
                                alt="img"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../assets/4dx-img.png").default
                                }
                                alt="img"
                              />
                            )}{" "}
                          </div>
                        </div>
                      </div>
                      <div className="experience-section">
                        <h4>
                          {localStorage.getItem("language") !== "ar" ? (
                            "AGE RATING"
                          ) : (
                            <Text>التصنيف العمري</Text>
                          )}
                        </h4>
                        <div className="input-btn">
                          <form
                            action="/action_page.php"
                            className="flex-input"
                          >
                            <div className="input-age-info input-info-mobile">
                              <div className="input-age">
                                <label for="vehicle1" className="">
                                  <input
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={
                                      this.state.prefrating.includes("G")
                                        ? true
                                        : false
                                    }
                                    onClick={() => this.ratingSelect("G")}
                                    className="m-r-11"
                                    name="vehicle1"
                                    value="Bike"
                                  />{" "}
                                  <span> G</span>
                                </label>
                              </div>
                              <div className="input-age">
                                <label for="vehicle2" className="">
                                  <input
                                    type="checkbox"
                                    id="vehicle2"
                                    checked={
                                      this.state.prefrating.includes("PG")
                                        ? true
                                        : false
                                    }
                                    onClick={() => this.ratingSelect("PG")}
                                    className="m-r-11"
                                    name="vehicle2"
                                    value="Car"
                                  />{" "}
                                  <span> PG</span>
                                </label>
                              </div>
                              <div className="input-age">
                                <label for="vehicle3" className="">
                                  <input
                                    type="checkbox"
                                    id="vehicle3"
                                    className="m-r-11"
                                    name="vehicle2"
                                    checked={
                                      this.state.prefrating.includes("13+")
                                        ? true
                                        : false
                                    }
                                    onClick={() => this.ratingSelect("13+")}
                                    value="Car"
                                  />{" "}
                                  <span> 13+</span>
                                </label>
                              </div>
                            </div>
                            <div className="input-age-info">
                              {/* <div className="input-age">
                                <label for="vehicle4" className="">
                                  <input
                                    type="checkbox"
                                    id="vehicle4"
                                    className="m-r-11"
                                    name="vehicle2"
                                    value="Car"
                                    checked={this.state.prefrating.includes("15+") ? true : false}
                                    onClick={() => this.ratingSelect("15+")}
                                  />
                                  {" "}
                                  <span> 15+</span>
                                </label>
                              </div> */}
                              <div className="input-age">
                                <label for="vehicle5" className="">
                                  <input
                                    type="checkbox"
                                    id="vehicle5"
                                    className="m-r-11"
                                    name="vehicle2"
                                    value="Car"
                                    checked={
                                      this.state.prefrating.includes("18+")
                                        ? true
                                        : false
                                    }
                                    onClick={() => this.ratingSelect("18+")}
                                  />{" "}
                                  <span>18+</span>
                                </label>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="notification-section experience-section">
                        <h4>
                          {localStorage.getItem("language") !== "ar" ? (
                            "NOTIFICATIONS"
                          ) : (
                            <Text>إشعارات</Text>
                          )}
                        </h4>

                        <form action="/action_page.php">
                          <div className="receive-email">
                            <p>
                              {localStorage.getItem("language") !== "ar" ? (
                                "Receive Promotional Emails"
                              ) : (
                                <Text>
                                  تلقي رسائل البريد الإلكتروني الترويجية
                                </Text>
                              )}
                            </p>

                            <div className="radio-btn">
                              <label for="yes" className="m-l-45">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Yes"
                                ) : (
                                  <Text>نعم</Text>
                                )}
                              </label>
                              <input
                                type="radio"
                                className="m-l-11"
                                id="yes"
                                name="fav_language"
                                value="yes"
                              />
                              <label for="No" className="m-l-45">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "No"
                                ) : (
                                  <Text>رقم</Text>
                                )}
                              </label>
                              <input
                                type="radio"
                                className="m-l-11"
                                id="No"
                                name="fav_language"
                                value="no"
                              />{" "}
                            </div>
                          </div>
                          <div className="receive-email">
                            <p>
                              {localStorage.getItem("language") !== "ar" ? (
                                "Receive Mobile Notifications"
                              ) : (
                                <Text>
                                  تلقي رسائل البريد الإلكتروني الترويجية
                                </Text>
                              )}
                            </p>
                            <div className="radio-btn">
                              <label for="yes" className="m-l-45">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "Yes"
                                ) : (
                                  <Text>نعم</Text>
                                )}
                              </label>
                              <input
                                type="radio"
                                className="m-l-11"
                                id="yes"
                                name="fav_language2"
                                value="yes"
                              />
                              <label for="No" className="m-l-45">
                                {localStorage.getItem("language") !== "ar" ? (
                                  "No"
                                ) : (
                                  <Text>رقم</Text>
                                )}
                              </label>
                              <input
                                type="radio"
                                className="m-l-11"
                                id="No"
                                name="fav_language2"
                                value="no"
                              />{" "}
                            </div>
                          </div>
                          <p className="pref-bot-line">
                            {localStorage.getItem("language") !== "ar" ? (
                              "We recommend to keep the notifications ON to receive your reservation notifications and reminders."
                            ) : (
                              <Text>
                                نوصي بإبقاء الإخطارات قيد التشغيل لتلقي إشعارات
                                الحجز والتذكيرات.
                              </Text>
                            )}
                          </p>
                        </form>
                      </div>
                      <div className="btn-save save-cancel-container">
                        <center>
                          <button type="button" className="btnc btnc-gray">
                            {localStorage.getItem("language") !== "ar" ? (
                              "Cancel"
                            ) : (
                              <Text>يلغي</Text>
                            )}
                          </button>
                          <button
                            type="button"
                            className="btnc btnc-primary"
                            onClick={this.updatePref}
                          >
                            {localStorage.getItem("language") !== "ar" ? (
                              "Save"
                            ) : (
                              <Text>حفظ</Text>
                            )}
                          </button>
                        </center>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="recharge_wallet">
                      <h2>
                        {" "}
                        {localStorage.getItem("language") !== "ar" ? (
                          "RECHARGE WALLET"
                        ) : (
                          <Text>إعادة شحن المحفظة</Text>
                        )}
                      </h2>
                      <div className="payment_section">
                        <div className="payment_method">
                          <div className="choose_amounts">
                            <form action="/action_page.php">
                              <div className="select-info">
                                <div className="choose_amount">
                                  <label for="cars" className="choose_text">
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "Choose Amount"
                                    ) : (
                                      <Text>اختيار مبلغ</Text>
                                    )}
                                  </label>
                                </div>
                                <div className="select-section">
                                  <select
                                    name="choosenamounts"
                                    id="choosenamounts"
                                    className="select-kd"
                                    onChange={this.choosenAmountHandleChange}
                                  >
                                    <option value="">
                                      {localStorage.getItem("language") !== "ar"
                                        ? "Choose Amount"
                                        : "اختيار مبلغ"}{" "}
                                    </option>
                                    {this.state.amounts.map(function (item, i) {
                                      return (
                                        <option value={item.amount}>
                                           {item.amountStr}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="choose_amounts">
                            <h4>
                              {localStorage.getItem("language") !== "ar" ? (
                                "PAYMENT METHOD"
                              ) : (
                                <Text>طريقة الدفع او السداد</Text>
                              )}
                            </h4>
                            <div className="card-payment">
                              <div className="card-section">
                                <div
                                  className={`credit-card ${this.state.paymentMethod === "by-wallet"
                                      ? "active"
                                      : ""
                                    }`}
                                  onClick={() => {
                                    this.setState({
                                      paymentMethod: "by-wallet",
                                      showcreditcard: false,
                                    });
                                  }}
                                >
                                  {this.state.paymentMethod === "by-wallet" ? (
                                    <img
                                      src={
                                        require("../../assets/KNET Active.png")
                                          .default
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../../assets/KNET Normal.png")
                                          .default
                                      }
                                    />
                                  )}
                                  <p>
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "KNET"
                                    ) : (
                                      <Text>كي نت </Text>
                                    )}
                                  </p>
                                </div>

                                <div
                                  className={`credit-card ${this.state.paymentMethod === "by-credit"
                                      ? "active"
                                      : ""
                                    }`}
                                  onClick={() => {
                                    this.setState({
                                      paymentMethod: "by-credit",
                                      showcreditcard: true,
                                    });
                                  }}
                                >
                                  {this.state.paymentMethod === "by-credit" ? (
                                    <img
                                      src={
                                        require("../../assets/Credit Card Active.png")
                                          .default
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../../assets/Credit Card Normal.png")
                                          .default
                                      }
                                    />
                                  )}
                                  <p>
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "Credit Card"
                                    ) : (
                                      <Text>بطاقة ائتمان</Text>
                                    )}
                                  </p>
                                </div>
                              </div>
                              {this.state.showcreditcard ? (
                                <div class="card-payment-recharge">
                                  <div className="card-details-container">
                                    <div className="input-box-card input-ccv">
                                      <img
                                        src={
                                          require("../../assets/Credit Card Name Normal@2x.png")
                                            .default
                                        }
                                      />
                                      <input
                                        type="text"
                                        name=""
                                        placeholder="Cardholder Name"
                                      />
                                    </div>
                                    <div className="input-box-card input-ccv">
                                      <img
                                        src={
                                          require("../../assets/Credit Card Number Normal@2x.png")
                                            .default
                                        }
                                      />
                                      <PaymentInputsContainer>
                                        {({
                                          meta,
                                          getCardNumberProps,
                                          getExpiryDateProps,
                                          getCVCProps,
                                        }) => (
                                          <>
                                            <input
                                              {...getCardNumberProps({
                                                onChange: this.cardNumberChange,
                                              })}
                                              value={this.state.cardNumber}
                                              maxLength={19}
                                            />
                                            {/* <input {...getExpiryDateProps({ onChange: handleChangeExpiryDate })} value={expiryDate} />
<input {...getCVCProps({ onChange: handleChangeCVC })} value={cvc} />
{meta.isTouched && meta.error && <span>Error: {meta.error}</span>} */}
                                          </>
                                        )}
                                      </PaymentInputsContainer>
                                      {/* <input onChange={this.cardNumberChange} maxLength="16" value={this.state.cardNumber} /> */}
                                    </div>
                                    <div className="card-types">
                                      {/* <img
                                        src={
                                          require("../../assets/express-icon (1).png").default
                                        }
                                      /> */}
                                      &nbsp;
                                      <img
                                        src={
                                          require("../../assets/express-icon (2).png")
                                            .default
                                        }
                                      />
                                      &nbsp;
                                      <img
                                        src={
                                          require("../../assets/express-icon (4).png")
                                            .default
                                        }
                                      />
                                    </div>
                                    <div className="input-box-card  input-ccv input-date">
                                      <div className="input-box-part">
                                        <img
                                          src={
                                            require("../../assets/Credit Card Expiry Date  Normal – 1@2x.png")
                                              .default
                                          }
                                        />
                                        <PaymentInputsContainer>
                                          {({
                                            meta,
                                            getCardNumberProps,
                                            getExpiryDateProps,
                                            getCVCProps,
                                          }) => (
                                            <>
                                              <input
                                                {...getExpiryDateProps({
                                                  onChange:
                                                    this.expirationDateChange,
                                                })}
                                                value={this.state.expiryDate}
                                              />
                                              {/*  <input {...getCVCProps({ onChange: handleChangeCVC })} value={cvc} />
{meta.isTouched && meta.error && <span>Error: {meta.error}</span>} */}
                                            </>
                                          )}
                                        </PaymentInputsContainer>
                                        {/* <CreditCardInput
cardExpiryInputProps={{ value: this.state.expirationMonth, onChange: this.expirationMonthChange }}
fieldClassName="input"
/> */}
                                      </div>
                                      <div className="input-box-part input-ccv input-date">
                                        <div className="input-box-part">
                                          <img
                                            src={
                                              require("../../assets/Credit Card Lock Normal.png")
                                                .default
                                            }
                                          />
                                          <PaymentInputsContainer>
                                            {({
                                              meta,
                                              getCardNumberProps,
                                              getExpiryDateProps,
                                              getCVCProps,
                                            }) => (
                                              <>
                                                <input
                                                  {...getCVCProps({
                                                    onChange:
                                                      this.cvNumberChange,
                                                  })}
                                                  value={this.state.cvNumber}
                                                />
                                                {/* {meta.isTouched && meta.error && <span>Error: {meta.error}</span>} */}
                                              </>
                                            )}
                                          </PaymentInputsContainer>
                                          {/* <CreditCardInput
cardCVCInputProps={{ value: this.state.cvNumber, onChange: this.cvNumberChange }}
fieldClassName="input"
/> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {this.state.loading ? (
                                <Loading />
                              ) : (
                                <div className="btn-pay save-cancel-container btn-recharge">
                                  <button
                                    type="button"
                                    className="btnc btnc-gray"
                                  >
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "Cancel"
                                    ) : (
                                      <Text>يلغي</Text>
                                    )}
                                  </button>
                                  <button
                                    type="button"
                                    className="btnc btnc-primary"
                                    onClick={this.payClubCard}
                                  >
                                    {localStorage.getItem("language") !==
                                      "ar" ? (
                                      "Pay"
                                    ) : (
                                      <Text>يدفع</Text>
                                    )}
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="pop-corn-img">
                          <div className="pop-corn">
                            <img
                              src={require("../../assets/pop-corn.png").default}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="upcoming-booking-box-web">
                      <div className="upcoming-booking-head">
                        <h3>
                          {localStorage.getItem("language") !== "ar" ? (
                            "BOOKINGS HISTORY"
                          ) : (
                            <Text>الحجوزات القادمة</Text>
                          )}
                        </h3>
                      </div>
                      <div className="booking_section booking_history-section">
                        {bookings.map((item, i) => {
                          return (
                            <>
                              {item.bookingType == "BOOKING" ? (
                                <div className="booking_history_box">
                                  <div className="booking_history">
                                    <div className="booking_img">
                                      <div className="movie_img">
                                        <img
                                          src={item.poster}
                                          alt={item.moviename}
                                        />
                                      </div>
                                    </div>
                                    <div className="movie_section ">
                                      <div className="movie_history desktop-history">
                                        <div className="movie-name">
                                          <div className="movie-history">
                                            <h2>{item.moviename}</h2>
                                            <button
                                              type="button"
                                              className="age_btn_history"
                                              style={{
                                                backgroundColor:
                                                  item.ratingColor,
                                              }}
                                            >
                                              {item.mcensor}
                                            </button>
                                          </div>

                                          <div className="bookings-status">
                                            <h3 className={item.status}>
                                              {item.status}
                                            </h3>
                                          </div>
                                        </div>

                                        <div className="movie_history">
                                          <p>
                                            {item.language}&nbsp;{item.format}
                                          </p>
                                        </div>
                                      </div>

                                      {/* mobile phone */}
                                      <div className="movie_history movie-history-mobile">
                                        <div className="movie-mobile-title">
                                          <div className="movie-myaccount">
                                            <span className="movie-title-name">
                                              {item.moviename}
                                            </span>
                                            <span
                                              type="button"
                                              className="age_btn_history"
                                              style={{
                                                backgroundColor:
                                                  item.ratingColor,
                                              }}
                                            >
                                              {item.mcensor}
                                            </span>

                                            <div className="movie_history">
                                              <p>
                                                {item.language}&nbsp;
                                                {item.format}
                                              </p>
                                            </div>
                                          </div>

                                          <div className="bookings-status">
                                            <h3 className={item.status}>
                                              {item.status}
                                            </h3>
                                          </div>
                                        </div>
                                      </div>

                                      {/* mobile phone */}

                                      <div className="history_info">
                                        <div className="location-section">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Location"
                                            ) : (
                                              <Text>موقع</Text>
                                            )}
                                          </p>
                                          <h3>{item.cinemaname}</h3>
                                        </div>
                                        <div className="booking_id">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Booking ID"
                                            ) : (
                                              <Text>معرف الحجز</Text>
                                            )}
                                          </p>
                                          <h3>{item.kioskId}</h3>
                                        </div>
                                      </div>
                                      <div className="history_info">
                                        <div className="location-section">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Date & Time"
                                            ) : (
                                              <Text>التاريخ والوقت</Text>
                                            )}
                                          </p>
                                          <div className="history_date">
                                            <h3 className="history_months">
                                              {item.showDate}{" "}
                                              &nbsp;&nbsp;|&nbsp;&nbsp;
                                            </h3>
                                            <h3>{item.showTime}</h3>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="screen_imax">
                                        <div className="screen_section screen-bookings">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Screen"
                                            ) : (
                                              <Text>شاشة</Text>
                                            )}
                                          </p>
                                          <h4>
                                            {item.screenId}{" "}
                                            <img src={item.experienceIcon} />
                                          </h4>
                                        </div>
                                      </div>

                                      <div className="screen_imax">
                                        <div class="seats">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Seats"
                                            ) : (
                                              <Text>المقاعد</Text>
                                            )}
                                          </p>
                                          {item.seatsArr
                                            ? item.seatsArr.map(function (
                                              it,
                                              i
                                            ) {
                                              return <h2>{it}</h2>;
                                            })
                                            : ""}
                                        </div>


                                        
 <div class="screen screen-payment mt-24 d-none-desktop location-section">
                                 <p>  {localStorage.getItem("language") !== "ar" ? (
                                    "Category"
                                 ) : (
                                    <Text>فئة</Text>
                                 )}</p>
                                 <h3>
                                    {item.category ? item.category : ''} 
                                 </h3>
                              </div>
                              

                                        <div className="view_details">
                                          <button
                                            type="button"
                                            className="btnc btnc-primary"
                                            onClick={() =>
                                              detailsHandler(item.bookingId)
                                            }
                                          >
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              detailsId == item.bookingId ? (
                                                "Close Details"
                                              ) : (
                                                "View Details"
                                              )
                                            ) : (
                                              <Text>عرض التفاصيل</Text>
                                            )}
                                          </button>
                                        </div>
                                        

 

                                      </div>
                                      <div class="screen screen-payment  d-none-category">
                                 <p>  {localStorage.getItem("language") !== "ar" ? (
                                    "Category"
                                 ) : (
                                    <Text>فئة</Text>
                                 )}</p>
                                 <h3>
                                    {item.category ? item.category : ''} 
                                 </h3>
                              </div>
                                    </div>

                                    
                                  </div>
                                  {this.state.detailsId == item.bookingId ? (
                                    <>
                                      <div className="cinescape_bookings ">
                                        <div className="ticket_details d-none-myaccount">
                                          <div className="ticket_detail_info">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Ticket Details"
                                              ) : (
                                                <Text>تفاصيل التذكرة</Text>
                                              )}
                                            </p>
                                          </div>
                                          <div className="ticket_kd">
                                            <p>{item.ticketPrice}</p>
                                          </div>
                                        </div>
                                        <div className="location_infos ticket_details d-none-myaccount">
                                          <div className="location_date">
                                            <div className="cinescape_location">
                                              <p>
                                                {localStorage.getItem(
                                                  "language"
                                                ) != "ar" ? (
                                                  "Location"
                                                ) : (
                                                  <Text>موقع</Text>
                                                )}
                                              </p>
                                              <h5>{item.cinemaname}</h5>
                                            </div>
                                            <div className="cinescape_location history-screen">
                                              <p>
                                                {" "}
                                                {localStorage.getItem(
                                                  "language"
                                                ) != "ar" ? (
                                                  "Screen"
                                                ) : (
                                                  <Text>شاشة</Text>
                                                )}
                                              </p>
                                              <h5>
                                                {item.screenId}{" "}
                                                <span>
                                                  {" "}
                                                  <img
                                                    src={item.experienceIcon}
                                                  />
                                                </span>
                                              </h5>
                                            </div>

                                            <div class="cinescape_location screen screen-payment  d-none-category">
                                 <p>  {localStorage.getItem("language") !== "ar" ? (
                                    "Category"
                                 ) : (
                                    <Text>فئة</Text>
                                 )}</p>
                                 <h3>
                                    {item.category ? item.category : ''} 
                                 </h3>
                              </div>

                                          </div>
                                          <div className="date_seats">
                                            <div className="cinescape_location">
                                              <p>
                                                {" "}
                                                {localStorage.getItem(
                                                  "language"
                                                ) != "ar" ? (
                                                  "Date & Time"
                                                ) : (
                                                  <Text>التاريخ والوقت</Text>
                                                )}
                                              </p>
                                              <h5>
                                                {item.showDate}{" "}
                                                &nbsp;|&nbsp;&nbsp;
                                                {item.showTime}
                                              </h5>
                                            </div>
                                            <div className="cinescape_location screen_m">
                                              <p>
                                                {localStorage.getItem(
                                                  "language"
                                                ) != "ar" ? (
                                                  "Seats"
                                                ) : (
                                                  <Text>المقاعد</Text>
                                                )}
                                              </p>
                                              {item.seatsArr
                                                ? item.seatsArr.map(function (
                                                  it,
                                                  i
                                                ) {
                                                  return <h2>{it}</h2>;
                                                })
                                                : ""}
                                            </div>
                                          </div>
                                        </div>
                                        {item.concessionFoods.length > 0 ? (
                                          <>
                                            <div className="ticket_details">
                                              <div className="ticket_detail_info ticket-mobile">
                                                <p>
                                                  {" "}
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) != "ar" ? (
                                                    "Food Details"
                                                  ) : (
                                                    <Text>تفاصيل الغذاء</Text>
                                                  )}
                                                </p>
                                              </div>
                                              <div className="ticket_kd d-none-myaccount">
                                                <p>
                                                  {item.foodTotal}{" "}
                                                  {/* {item.concessionFoods.reduce((previousValue,currentValue) => previousValue + currentValue, 0)} */}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="food_table">
                                              <table className="item_info">
                                                <tbody>
                                                  <tr>
                                                    <th className="item">
                                                      {" "}
                                                      {localStorage.getItem(
                                                        "language"
                                                      ) != "ar" ? (
                                                        "Item"
                                                      ) : (
                                                        <Text>غرض</Text>
                                                      )}
                                                    </th>
                                                    <th
                                                      className="qty"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {" "}
                                                      {localStorage.getItem(
                                                        "language"
                                                      ) != "ar" ? (
                                                        "Qty"
                                                      ) : (
                                                        <Text>كمية</Text>
                                                      )}
                                                    </th>
                                                    <th
                                                      className="price"
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {" "}
                                                      {localStorage.getItem(
                                                        "language"
                                                      ) != "ar" ? (
                                                        "Price"
                                                      ) : (
                                                        <Text>السعر</Text>
                                                      )}
                                                    </th>
                                                    <th
                                                      className="total "
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {" "}
                                                      {localStorage.getItem(
                                                        "language"
                                                      ) != "ar" ? (
                                                        "Total"
                                                      ) : (
                                                        <Text>المجموع</Text>
                                                      )}
                                                    </th>
                                                  </tr>
                                                  {item.concessionFoods.map(
                                                    (food) => {
                                                      totalFood =
                                                        (food.priceInCents /
                                                          100) *
                                                        food.quantity;
                                                      console.log(totalFood);
                                                      return (
                                                        <tr>
                                                          <td width="50%">
                                                            <div className="food_details">
                                                              <div className="food_img">
                                                                <img
                                                                  src={
                                                                    require("../../assets/popcorn.png")
                                                                      .default
                                                                  }
                                                                  alt="img"
                                                                />
                                                              </div>
                                                              <div className="food_info">
                                                                <h5>
                                                                  {
                                                                    food.description
                                                                  }
                                                                </h5>
                                                              </div>
                                                            </div>
                                                          </td>
                                                          <td
                                                            width="16%"
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {food.quantity}
                                                          </td>
                                                          <td
                                                            width="16%"
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            KWD{" "}
                                                            {(
                                                              food.priceInCents /
                                                              100
                                                            ).toFixed(3)}
                                                          </td>
                                                          <td
                                                            width="16%"
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            KWD{" "}
                                                            {(
                                                              (food.priceInCents /
                                                                100) *
                                                              food.quantity
                                                            ).toFixed(3)}
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {/* Bookig history TICKET DETAILS */}

                                        <div className="cinescape_bookings d-none-myaccount">
                                          <div class="ticket_details">
                                            <div class="ticket_detail_info">
                                              <p>Transaction Details</p>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="location_infos ticket_details d-none-myaccount">
                                          <div class="location_date">
                                            <div class="cinescape_location">
                                              <p> Track ID</p>
                                              <h5>{item.bookingId}</h5>
                                            </div>
                                            <div class="cinescape_location">
                                              <p> Transaction ID</p>
                                              <h5>{item.vistaNumber}</h5>
                                            </div>
                                            <div class="cinescape_location">
                                              <p> Transaction Date & Time</p>
                                              <h5>{item.bookingTime}</h5>
                                            </div>
                                            {item.payStatus != "" ? (
                                              <div class="cinescape_location">
                                                <p>Result</p>
                                                <h5>{item.payStatus}</h5>
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            <div class="cinescape_location">
                                              <p>Payment Mode</p>
                                              <h5>{item.payDone}</h5>
                                            </div>
                                          </div>
                                          <div class="date_seats">
                                            {item.authCode != "" ? (
                                              <div class="cinescape_location">
                                                <p>Authorization Code </p>
                                                <h5>{item.authCode}</h5>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {item.referenceId != "" ? (
                                              <div class="cinescape_location">
                                                <p>Payment ID</p>
                                                <h5>{item.referenceId}</h5>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>

                                        {/* TICKET DETAILS */}

                                        <div class="ticket_price d-none-myaccount">
                                          <table width="80%">
                                            <tr style={{ textAlign: "left" }}>
                                              <th width="20%">
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Ticket Price"
                                                ) : (
                                                  <Text>سعر التذكرة</Text>
                                                )}
                                              </th>
                                              <th width="20%">
                                                {item.ticketPrice}{" "}
                                              </th>
                                              <th width="30%">
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Payment Mode"
                                                ) : (
                                                  <Text>طريقة الدفع</Text>
                                                )}
                                              </th>
                                              <th width="35%">
                                                <b>
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "Grand Total"
                                                  ) : (
                                                    <Text>المبلغ الإجمالي</Text>
                                                  )}
                                                </b>
                                              </th>
                                            </tr>
                                            <tr>
                                              <td width="10%">
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Food Price"
                                                ) : (
                                                  <Text>سعر الغذاء</Text>
                                                )}
                                              </td>
                                              <td width="35%">
                                                {item.foodTotal}
                                              </td>
                                              <td width="20%">
                                                <b>{item.payDone}</b>
                                              </td>
                                              <td width="35%">
                                                <b>{item.totalPrice}</b>
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                      </div>

                                      {/*---------------------------------------- -------mobile view total price----------------------------------------- */}

                                      <div className="mobile-total-price">

                                        
                                      <div className="cinescape_bookings">
                                          <div class="ticket_details">
                                            <div class="ticket_detail_info">
                                              <p>Transaction Details</p>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="location_infos ticket_details">
                                          <div class="location_date">
                                            <div class="cinescape_location">
                                              <p> Track ID</p>
                                              <h5>{item.bookingId}</h5>
                                            </div>
                                            <div class="cinescape_location">
                                              <p> Transaction ID</p>
                                              <h5>{item.vistaNumber}</h5>
                                            </div>
                                            <div class="cinescape_location">
                                              <p> Transaction Date & Time</p>
                                              <h5>{item.bookingTime}</h5>
                                            </div>
                                            {item.payStatus != "" ? (
                                              <div class="cinescape_location">
                                                <p>Result</p>
                                                <h5>{item.payStatus}</h5>
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            <div class="cinescape_location">
                                              <p>Payment Mode</p>
                                              <h5>{item.payDone}</h5>
                                            </div>
                                          </div>
                                          <div class="date_seats">
                                            {item.authCode != "" ? (
                                              <div class="cinescape_location">
                                                <p>Authorization Code </p>
                                                <h5>{item.authCode}</h5>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {item.referenceId != "" ? (
                                              <div class="cinescape_location">
                                                <p>Payment ID</p>
                                                <h5>{item.referenceId}</h5>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>



                                        <div className="ticket-details-section">
                                          <div className="ticket-text-info">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "TICKET PRICE"
                                              ) : (
                                                <Text>تفاصيل التذكرة</Text>
                                              )}
                                            </p>
                                          </div>

                                          <div className="price-text-info">
                                            <p>{item.totalTicketPrice}</p>
                                          </div>
                                        </div>

                                        <div className="ticket-details-section">
                                          <div className="ticket-text-info">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "FOOD PRICE"
                                              ) : (
                                                <Text>تفاصيل التذكرة</Text>
                                              )}
                                            </p>
                                          </div>

                                          <div className="price-text-info">
                                            <p> {item.foodTotal} </p>
                                          </div>
                                        </div>

                                        <div className="ticket-details-section">
                                          <div className="ticket-text-info">
                                            <p>
                                              <b>
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "GRAND TOTAL"
                                                ) : (
                                                  <Text>تفاصيل التذكرة</Text>
                                                )}
                                              </b>
                                            </p>
                                          </div>

                                          <div className="price-text-info">
                                            <p>
                                              <b>
                                                {" "}
                                                <b>{item.totalPrice}</b>
                                              </b>{" "}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="ticket-details-section payment-info-section">
                                          <div className="ticket-text-info">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Payment Mode"
                                              ) : (
                                                <Text>تفاصيل التذكرة</Text>
                                              )}
                                            </p>
                                          </div>

                                          <div className="price-text-info">
                                            <p>
                                              <b>{item.payDone}</b>
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      {/* -----------------------------------------------------mobile view total price---------------------------------------- */}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              {item.bookingType == "CLUBRECHARGE" ? (
                                <div
                                  div
                                  className="booking_history_box boocking-wallet"
                                >
                                  <div className="booking_history">
                                    <div className="booking_img">
                                      <div className="movie_img wallet-img">
                                        <img
                                          src={
                                            require("../../assets/Wallet Big.png")
                                              .default
                                          }
                                          alt="img"
                                        />
                                      </div>
                                    </div>
                                    <div className="movie_section movie-food">
                                      <div className="movie_history desktop-history">
                                        <h2>WALLET RECHARGE</h2>
                                      </div>

                                      <div className="history_info wallet-history wallet-card">
                                        <div className="location-wallet">
                                          <div className="location-section wallet-location">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Date & Time"
                                              ) : (
                                                <Text>التاريخ والوقت</Text>
                                              )}
                                            </p>
                                            <div className="history_date">
                                              <h3 className="history_months">
                                                {item.showDate}
                                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                                {item.showTime}{" "}
                                              </h3>
                                            </div>
                                          </div>
                                          {this.state.detailsId ==
                                            item.bookingId ? (
                                            <div className="location-section wallet-history">
                                              <p>
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Payment Mode"
                                                ) : (
                                                  <Text>طريقة الدفع</Text>
                                                )}
                                              </p>
                                              <div className="history_date">
                                                <h3 className="history_months">
                                                  {item.payDone}
                                                </h3>
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="booking-wallet">
                                          <div className="booking_id wallet-location">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Booking ID"
                                              ) : (
                                                <Text>معرف الحجز</Text>
                                              )}
                                            </p>
                                            <h3>{item.kioskId}</h3>
                                          </div>
                                          {this.state.detailsId ==
                                            item.bookingId ? (
                                            <div className="booking_id">
                                              <p>
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Amount"
                                                ) : (
                                                  <Text>كمية</Text>
                                                )}
                                              </p>
                                              <h3>{item.totalPrice}</h3>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        {/* 
                                      {this.state.detailsId == item.bookingId ? (
                                        <>
                                          <div className="history_info wallet-history">
                                            <div className="location-section wallet-history">
                                              <p>
                                                {localStorage.getItem("language") !==
                                                  "ar" ? (
                                                  "Payment Mode"
                                                ) : (
                                                  <Text>طريقة الدفع</Text>
                                                )}
                                              </p>
                                              <div className="history_date">
                                                <h3 className="history_months">
                                                  {item.payDone}
                                                </h3>
                                              </div>
                                            </div>
                                            <div className="booking_id">
                                              <p>
                                                {localStorage.getItem("language") !==
                                                  "ar" ? (
                                                  "Amount"
                                                ) : (
                                                  <Text>كمية</Text>
                                                )}
                                              </p>
                                              <h3>{item.totalPrice}</h3>
                                            </div>
                                          </div>
                                        </>) : ''} */}

                                        <div className="screen_imax">
                                          <div className="screen_section">
                                            <p></p>
                                          </div>
                                          <div className="view_details">
                                            <button
                                              type="button"
                                              className="btnc btnc-primary"
                                              onClick={() =>
                                                detailsHandler(item.bookingId)
                                              }
                                            >
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                detailsId == item.bookingId ? (
                                                  "Close Details"
                                                ) : (
                                                  "View Details"
                                                )
                                              ) : (
                                                <Text>عرض التفاصيل</Text>
                                              )}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {item.bookingType == "FOOD" ? (
                                <div className="booking_history_box">
                                  <div className="booking_history">
                                    <div className="booking_img">
                                      <div className="movie_img wallet-img">
                                        <img
                                          src={
                                            require("../../assets/Food Big.png")
                                              .default
                                          }
                                          alt="img"
                                        />
                                      </div>
                                    </div>
                                    <div className="movie_section movie-food">
                                      <div className="movie_history desktop-history">
                                        <h2>FOOD ORDER</h2>
                                      </div>
                                      <div className="history_info">
                                        <div className="location-section">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Date & Time"
                                            ) : (
                                              <Text>التاريخ والوقت</Text>
                                            )}
                                          </p>
                                          <div className="history_date">
                                            <h3 className="history_months">
                                              {item.showDate}
                                              &nbsp;&nbsp;|&nbsp;&nbsp;
                                              {item.showTime}
                                            </h3>
                                          </div>
                                        </div>
                                        <div className="booking_id">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Food Pickup ID"
                                            ) : (
                                              <Text>معرف الحجز</Text>
                                            )}
                                          </p>
                                          <h3>{item.kioskId}</h3>
                                        </div>
                                      </div>
                                      <div className="screen_imax">
                                        <div className="screen_section">
                                          <p></p>
                                        </div>
                                        <div className="view_details">
                                          <button
                                            type="button"
                                            className="btnc btnc-primary"
                                            onClick={() =>
                                              detailsHandler(item.bookingId)
                                            }
                                          >
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              detailsId == item.bookingId ? (
                                                "Close Details"
                                              ) : (
                                                "View Details"
                                              )
                                            ) : (
                                              <Text>عرض التفاصيل</Text>
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {this.state.detailsId == item.bookingId ? (
                                    <>
                                      {/* Food Details */}

                                      {/* <div class="ticket_details"><div class="ticket_detail_info"><p>Food Details</p></div><div class="ticket_kd"><p> KWD 1.650     </p></div></div> */}

                                      <div class="food_table">
                                        <table class="item_info">
                                          <tr style={{ textAlign: "left" }}>
                                            <th>
                                              {" "}
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Item"
                                              ) : (
                                                <Text>غرض</Text>
                                              )}
                                            </th>
                                            <th style={{ textAlign: "center" }}>
                                              {" "}
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Qty"
                                              ) : (
                                                <Text>كمية</Text>
                                              )}{" "}
                                            </th>
                                            <th style={{ textAlign: "center" }}>
                                              {" "}
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Price"
                                              ) : (
                                                <Text>السعر</Text>
                                              )}
                                            </th>
                                            <th style={{ textAlign: "center" }}>
                                              {" "}
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Total"
                                              ) : (
                                                <Text>المجموع</Text>
                                              )}
                                            </th>
                                          </tr>

                                          {item.concessionFoods.map((food) => {
                                            totalFood =
                                              (food.priceInCents / 100) *
                                              food.quantity;
                                            console.log(totalFood);
                                            return (
                                              <tr>
                                                <td width="50%">
                                                  <div className="food_details">
                                                    <div className="food_img food-img-one">
                                                      <img
                                                        src={
                                                          require("../../assets/popcorn.png")
                                                            .default
                                                        }
                                                        alt="img"
                                                      />
                                                      <div className="food_info">
                                                        <h5>
                                                          {food.description}
                                                        </h5>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td
                                                  width="16%"
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {food.quantity}
                                                </td>
                                                <td
                                                  width="16%"
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  KWD{" "}
                                                  {(
                                                    food.priceInCents / 100
                                                  ).toFixed(3)}
                                                </td>
                                                <td
                                                  width="16%"
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  KWD{" "}
                                                  {(
                                                    (food.priceInCents / 100) *
                                                    food.quantity
                                                  ).toFixed(3)}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </table>
                                      </div>

                                      {/* Food Details */}

                                      {/* trans DETAILS */}

                                      <div class="location_infos ticket_details d-none-myaccount">
                                        <div class="location_date">
                                          <div class="cinescape_location">
                                            <p> Track ID</p>
                                            <h5>{item.bookingId}</h5>
                                          </div>
                                          {item.authCode != "" ? (
                                            <div class="cinescape_location">
                                              <p>Authorization Code </p>
                                              <h5>{item.authCode}</h5>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {item.referenceId != "" ? (
                                            <div class="cinescape_location">
                                              <p>Payment ID</p>
                                              <h5>{item.referenceId}</h5>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <div class="cinescape_location">
                                            <p> Transaction Date & Time</p>
                                            <h5>{item.bookingTime}</h5>
                                          </div>
                                          {item.payStatus != "" ? (
                                            <div class="cinescape_location">
                                              <p>Result</p>
                                              <h5>{item.payStatus}</h5>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        <div class="date_seats">
                                          <div class="cinescape_location">
                                            <p>Payment Mode</p>
                                            <h5>{item.payDone}</h5>
                                          </div>
                                        </div>
                                      </div>

                                      {/* trans DETAILS */}

                                      {/* TICKET PRICE	 */}

                                      <div class="ticket_price d-none-myaccount">
                                        <table width="80%">
                                          <tr>
                                            <td width="10%">
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Food Price"
                                              ) : (
                                                <Text>سعر الغذاء</Text>
                                              )}
                                            </td>
                                            <td width="35%">
                                              {item.foodTotal}
                                            </td>
                                            {/* <td width="20%">
                                              <b>
                                                KWD 1.750
                                              </b>
                                            </td> */}
                                            <td width="35%">
                                              <b>{item.payDone} </b>
                                            </td>
                                          </tr>
                                        </table>
                                      </div>

                                      {/*---------------------------------------- -------mobile view total price----------------------------------------- */}

                                      <div className="mobile-total-price">
                                        <div className="ticket-details-section">
                                          <div className="ticket-text-info">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "TICKET PRICE"
                                              ) : (
                                                <Text>تفاصيل التذكرة</Text>
                                              )}
                                            </p>
                                          </div>

                                          <div className="price-text-info">
                                            <p>{item.totalTicketPrice}</p>
                                          </div>
                                        </div>

                                        <div className="ticket-details-section">
                                          <div className="ticket-text-info">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "FOOD PRICE"
                                              ) : (
                                                <Text>تفاصيل التذكرة</Text>
                                              )}
                                            </p>
                                          </div>

                                          <div className="price-text-info">
                                            <p> {item.foodTotal} </p>
                                          </div>
                                        </div>

                                        <div className="ticket-details-section">
                                          <div className="ticket-text-info">
                                            <p>
                                              <b>
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "GRAND TOTAL"
                                                ) : (
                                                  <Text>تفاصيل التذكرة</Text>
                                                )}
                                              </b>
                                            </p>
                                          </div>

                                          <div className="price-text-info">
                                            <p>
                                              <b>
                                                {" "}
                                                <b>{item.totalPrice}</b>
                                              </b>{" "}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="ticket-details-section payment-info-section">
                                          <div className="ticket-text-info">
                                            <p>
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Payment Mode"
                                              ) : (
                                                <Text>تفاصيل التذكرة</Text>
                                              )}
                                            </p>
                                          </div>

                                          <div className="price-text-info">
                                            <p>
                                              <b>{item.payDone}</b>
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      {/* -----------------------------------------------------mobile view total price---------------------------------------- */}

                                      {/* TICKET PRICE	 */}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    {/* mobile-design */}
                    <div className="upcoming-booking-box-mobile d-none">
                      <div className="upcoming-booking-head">
                        <h3>
                          {localStorage.getItem("language") !== "ar" ? (
                            "BOOKING HISTORY"
                          ) : (
                            <Text>تاريخ الحجز</Text>
                          )}
                        </h3>
                      </div>
                      <div className="upcoming-booking-box-preview">
                        {this.state.bookings.map(function (item, i) {
                          return (
                            <>
                              {item.bookingType == "BOOKING" ? (
                                <div className="upcoming-movie-border">
                                  <div className="upcoming-movie-poster">
                                    <img src={item.poster} />
                                  </div>
                                  <div className="upcoming-booking-details">
                                    <div class="booking-upcoming-head">
                                      {/* <h4>
{item.moviename}
<span className="age-highlight" style={{ backgroundColor: item.ratingColor }}>{item.ratings}</span>
</h4> */}
                                      <div className="movie_history">
                                        <h2>{item.moviename}</h2>
                                        <button
                                          type="button"
                                          className="age_btn_history"
                                          style={{
                                            backgroundColor: item.ratingColor,
                                          }}
                                        >
                                          {item.ratings}
                                        </button>
                                      </div>
                                    </div>
                                    <p className="upcoming-lang">
                                      {item.language}&nbsp;{item.format}
                                    </p>
                                    <div className="booking-info-qr">
                                      <div className="booking-id booking_location">
                                        <p>
                                          {" "}
                                          {localStorage.getItem("language") !==
                                            "ar" ? (
                                            "Booking ID"
                                          ) : (
                                            <Text>معرف الحجز</Text>
                                          )}
                                        </p>
                                        <h5>{item.kioskId}</h5>
                                      </div>
                                      {item.pickUpNumber &&
                                        item.pickUpNumber.length > 0 ? (
                                        <div className="booking-id booking_location">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "FOOD PICK-UP NUMBER"
                                            ) : (
                                              <Text>رقم استلام الطعام</Text>
                                            )}
                                          </p>
                                          <h5>{item.pickUpNumber}</h5>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {/* <div className="qr-contain">
<img
src={`data:image/png;base64,  ${item.qr}`}
/>
</div> */}
                                    </div>
                                    <div class="booking_location_section">
                                      <div class="booking_ticket ">
                                        <div class="booking_info">
                                          <div className="booking-id-container">
                                            <div class="booking_location">
                                              <p>
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "LOCATION"
                                                ) : (
                                                  <Text>موقع</Text>
                                                )}
                                              </p>
                                              <h5>{item.cinemaname}</h5>
                                            </div>
                                          </div>
                                          <div className="qr-code-container">
                                            <div className="booking-time-contain">
                                              <div class="booking_time">
                                                <p>
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "Date & Time"
                                                  ) : (
                                                    <Text>التاريخ والوقت</Text>
                                                  )}
                                                </p>
                                                <h5>
                                                  {item.showDate}|{" "}
                                                  {item.showTime}{" "}
                                                </h5>
                                              </div>
                                              <div class="screen">
                                                <p>
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "SCREEN"
                                                  ) : (
                                                    <Text>شاشة</Text>
                                                  )}
                                                </p>
                                                <p>{item.screenId}</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="cancel-booking-container">
                                            <div class="seats">
                                              <p>
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Seats"
                                                ) : (
                                                  <Text>المقاعد</Text>
                                                )}
                                              </p>
                                              {item.seatsArr
                                                ? item.seatsArr.map(function (
                                                  it,
                                                  i
                                                ) {
                                                  return <h2>{it}</h2>;
                                                })
                                                : ""}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="details-btn-toggle">
                                    <div className="toggle-contain">
                                      <div className="details-container">
                                        {/* <button className="btnc btnc-primary " >
{localStorage.getItem("language") !== "ar" ? (
"View Details"
) : (
<Text>عرض التفاصيل</Text>
)}
</button> */}
                                        <button
                                          type="button"
                                          className="btnc btnc-primary"
                                          onClick={() =>
                                            this.detailsHandler(item.bookingId)
                                          }
                                        >
                                          {localStorage.getItem("language") !==
                                            "ar" ? (
                                            detailsId == item.bookingId ? (
                                              "Close Details"
                                            ) : (
                                              "View Details"
                                            )
                                          ) : (
                                            <Text>عرض التفاصيل</Text>
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                    {/* -----------details----------- */}
                                    <div className="view-details-contain ">
                                      {item.concessionFoods.length > 0 ? (
                                        <>
                                          <div class="ticket_details">
                                            <div class="ticket_detail_info">
                                              <p>
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Food Details"
                                                ) : (
                                                  <Text>تفاصيل الغذاء</Text>
                                                )}
                                              </p>
                                            </div>
                                          </div>

                                          <div class="food_table">
                                            <table class="item_info">
                                              <tr style={{ textAlign: "left" }}>
                                                <th>
                                                  {" "}
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "Item"
                                                  ) : (
                                                    <Text>غرض</Text>
                                                  )}
                                                </th>
                                                <th>
                                                  {" "}
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "Qty"
                                                  ) : (
                                                    <Text>كمية</Text>
                                                  )}{" "}
                                                </th>
                                                <th>
                                                  {" "}
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "Price"
                                                  ) : (
                                                    <Text>السعر</Text>
                                                  )}
                                                </th>
                                                <th>
                                                  {" "}
                                                  {localStorage.getItem(
                                                    "language"
                                                  ) !== "ar" ? (
                                                    "Total"
                                                  ) : (
                                                    <Text>المجموع</Text>
                                                  )}
                                                </th>
                                              </tr>
                                              {item.concessionFoods.map(
                                                (food) => {
                                                  totalFood =
                                                    (food.priceInCents / 100) *
                                                    food.quantity;
                                                  console.log(totalFood);
                                                  return (
                                                    <tr>
                                                      <td width="50%">
                                                        <div className="food_details">
                                                          <div className="food_img ">
                                                            <img
                                                              src={
                                                                require("../../assets/popcorn.png")
                                                                  .default
                                                              }
                                                              alt="img"
                                                            />
                                                            <div className="food_info">
                                                              <h5>
                                                                {
                                                                  food.description
                                                                }
                                                              </h5>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td
                                                        width="3%"
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {food.quantity}
                                                      </td>
                                                      <td width="3%">
                                                        KWD{" "}
                                                        {food.priceInCents /
                                                          100}
                                                      </td>
                                                      <td width="3%">
                                                        KWD{" "}
                                                        {(food.priceInCents /
                                                          100) *
                                                          food.quantity}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </table>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <div class="ticket_price">
                                        <table width="100%">
                                          <tr>
                                            <td width="50%">
                                              {" "}
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Ticket Price"
                                              ) : (
                                                <Text>سعر التذكرة</Text>
                                              )}
                                            </td>
                                            <td width="50%">
                                              {item.ticketPrice}{" "}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width="50%">Food Price </td>
                                            <td width="50%">
                                              {item.foodTotal}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width="50%">
                                              <b>
                                                {" "}
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Grand Total"
                                                ) : (
                                                  <Text>المبلغ الإجمالي</Text>
                                                )}
                                              </b>
                                            </td>
                                            <td width="50%">
                                              <b>{item.totalPrice}</b>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width="50%">
                                              {" "}
                                              {localStorage.getItem(
                                                "language"
                                              ) !== "ar" ? (
                                                "Payment Mode"
                                              ) : (
                                                <Text>طريقة الدفع</Text>
                                              )}
                                            </td>
                                            <td width="50%"></td>
                                          </tr>
                                          <tr>
                                            <td width="50%">
                                              <b>
                                                {" "}
                                                {localStorage.getItem(
                                                  "language"
                                                ) !== "ar" ? (
                                                  "Wallet"
                                                ) : (
                                                  <Text>محفظة نقود</Text>
                                                )}
                                              </b>
                                            </td>
                                            <td width="50%">
                                              <b>{item.payDone}</b>
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {item.bookingType == "CLUBRECHARGE" ? (
                                <div className="booking_history_box">
                                  <div className="booking_history">
                                    <div className="booking_img">
                                      <div className="movie_img wallet-img">
                                        <img
                                          src={
                                            require("../../assets/Wallet Big.png")
                                              .default
                                          }
                                          alt="img"
                                        />
                                      </div>
                                    </div>
                                    <div className="movie_section movie-food">
                                      <div className="movie_history desktop-history">
                                        <h2>WALLET RECHARGE</h2>
                                      </div>

                                      <div className="history_info">
                                        <div className="location-section">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Date & Time"
                                            ) : (
                                              <Text>التاريخ والوقت</Text>
                                            )}
                                          </p>
                                          <div className="history_date">
                                            <h3 className="history_months">
                                              {item.showDate}| {item.showTime}{" "}
                                            </h3>
                                          </div>
                                        </div>
                                        <div className="booking_id">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Booking ID"
                                            ) : (
                                              <Text>معرف الحجز</Text>
                                            )}
                                          </p>
                                          <h3>{item.kioskId}</h3>
                                        </div>
                                      </div>

                                      <div className="booking-id booking_location">
                                        <p>
                                          {" "}
                                          {localStorage.getItem("language") !==
                                            "ar" ? (
                                            "FOOD PICK-UP NUMBER"
                                          ) : (
                                            <Text>رقم استلام الطعام</Text>
                                          )}
                                        </p>
                                        <h5>{item.pickUpNumber}</h5>
                                      </div>

                                      <div className="history_info">
                                        <div className="location-section">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Payment Mode"
                                            ) : (
                                              <Text>طريقة الدفع</Text>
                                            )}
                                          </p>
                                          <div className="history_date">
                                            <h3 className="history_months">
                                              {item.payDone}
                                            </h3>
                                          </div>
                                        </div>
                                        <div className="booking_id">
                                          <p>
                                            {localStorage.getItem(
                                              "language"
                                            ) !== "ar" ? (
                                              "Amount"
                                            ) : (
                                              <Text>كمية</Text>
                                            )}
                                          </p>
                                          <h3>{item.totalPrice}</h3>
                                        </div>
                                      </div>

                                      <div className="screen_imax">
                                        <div className="screen_section">
                                          <p></p>
                                        </div>
                                        <div className="view_details">
                                          <button
                                            type="button"
                                            className="btnc btnc-primary"
                                          >
                                            View Details
                                            {localStorage.getItem(
                                              "language"
                                            ) !== <Text>عرض التفاصيل</Text>}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </div>
        </div>

        <>
          <Modal
            isOpen={this.state.showModal}
            contentLabel="Example Modal modal-password"
            portalClassName={`trai-modal modal-name modal-login modal-password`}
          >
            {" "}
            <button
              className={`trailer-cross modal-cross`}
              onClick={() => this.setState({ showModal: false })}
            >
              <i class="fas fa-times"></i>
            </button>
            <div className="profile-password">
              <h3>Change Password</h3>

              <div class="profile-input">
                <input
                  type="password"
                  placeholder="Old Password"
                  value={this.state.oldpassword}
                  onChange={this.oldpasswordHandleChange}
                ></input>
              </div>
              {this.changevalidator.message(
                "Old Password",
                this.state.oldpassword,
                "required"
              )}
              <div class="profile-input">
                <input
                  type="password"
                  placeholder="New Password"
                  value={this.state.password}
                  onChange={this.passwordHandleChange}
                ></input>
              </div>
              {this.changevalidator.message(
                " Password",
                this.state.password,
                "required|min:8|max:20|strongPassword"
              )}
              <div class="profile-input">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={this.state.confirmpassword}
                  onChange={this.confirmpasswordHandleChange}
                ></input>
              </div>
              {this.changevalidator.message(
                "Confirm Password",
                this.state.confirmpassword,
                "required|min:8|max:20|strongPassword"
              )}
                  {this.state.password !== this.state.confirmpassword && (
    <span style={{ color: 'red' }}>Passwords do not match</span>
  )}
              <div className="profile-cancel">
                <div className="cancel-text">
                  <Link onClick={() => this.setState({ showModal: false })}>
                    <b>Cancel</b>
                  </Link>
                </div>
                <div className="go-text">
                  &<Link to="#"> go back</Link>
                </div>
                <div className="proceed-text edit-password save-cancel-container">
                  <button
                    class="btnc btnc-primary"
                    onClick={this.changePassword}
                  >
                    Proceed{" "}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      </>
    );
  }
}
export default MyAccount;
