import React, { Component } from "react";
import Text from "react-text"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import * as Constants from "../../Constants/index";

import swal from "sweetalert";
import { Loading } from "../header/loader.component";
class BookingConfirm extends Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         qrdata: '',
         posterhori: '',
         result: new URLSearchParams(window.location.search).get("result"),
         tckDetailsOutput: {}
      };
   }
   componentDidMount() {
      const tckDetailsRequest = async () => {
         this.setState({
            loading: true
         });
         try {
            console.log(this.state.moviename);
            console.log(this.state.mcode);
            // const resp =
            const token = localStorage.getItem("token");

            await axios
               .post(
                  Constants.api + "content/trans/tckbooked/",
                  {
                     bookingId: localStorage.getItem("bookingid"),
                     transid: localStorage.getItem("transid"),
                     userId: localStorage.getItem("userid")
                  },
                  {
                     headers: {
                        "Accept-Language": localStorage.getItem("language"),
                        Authorization: `Bearer ${token}`,
                        appversion: Constants.av,
                        platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
                     },
                  }
               )
               .then((resp) => {
                  console.log(resp);
                  if (resp.data.result === "success") {  
                     if(localStorage.getItem('guest'))
                     {
                        localStorage.removeItem('token')
                        localStorage.removeItem('guest')

                     }
                     this.setState({
                        loading: false
                     });
                     let tckDetailsArray = [];
                     this.setState({
                        tckDetailsOutput: resp.data.output,
                        qrdata: resp.data.output.qr,
                        posterhori: resp.data.output.posterhori,
                     });
                  } else {
                     swal(resp.data.msg);
                     this.setState({
                        loading: false
                     });

                  }
               });
               await axios
               .post(
                  Constants.api + "content/trans/ticketVisible/",
                  {
                     bookingId: localStorage.getItem("bookingid"),
                     transid: localStorage.getItem("transid"),
                     userId: localStorage.getItem("userid")
                  },
                  {
                     headers: {
                        "Accept-Language": localStorage.getItem("language"),
                        Authorization: `Bearer ${token}`,
                        appversion: Constants.av,
                        platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
                     },
                  }
               )
               .then((resp) => {
                  console.log(resp);
                 
               });
         } catch (err) {
            console.error(err);
         }
      };
      const tckFailedRequest = async () => {
         this.setState({
            loading: true
         });
         try {
            console.log(this.state.moviename);
            console.log(this.state.mcode);
            // const resp =
            const token = localStorage.getItem("token");

            await axios
               .post(
                  Constants.api + "content/trans/tckfailed/",
                  {
                     bookingId: localStorage.getItem("bookingid"),
                     transid: localStorage.getItem("transid"),
                     userId: localStorage.getItem("userid")
                  },
                  {
                     headers: {
                        "Accept-Language": localStorage.getItem("language"),
                        Authorization: `Bearer ${token}`,
                        appversion: Constants.av,
                        platform: window.matchMedia('(max-width: 575px)').matches ? "MSITE" : "WEBSITE",
                     },
                  }
               )
               .then((resp) => {
                  console.log(resp);
                  if (resp.data.result === "success") {
                     this.setState({
                        loading: false
                     });
                     let tckDetailsArray = [];
                     this.setState({
                        tckDetailsOutput: resp.data.output,
                        qrdata: resp.data.output.qr,
                        posterhori: resp.data.output.posterhori,
                     });
                     // setTimeout(() => {
                     //    window.location = localStorage.getItem('moviesessionPath') // Replace 'your-desired-page' with the actual page path
                     // }, 30000);
                  } else {
                     swal(resp.data.msg);
                     this.setState({
                        loading: false
                     });
                  }
               });
         } catch (err) {
            console.error(err);
         }
      };
      if (this.state.result == "success")
         tckDetailsRequest();
      else
         tckFailedRequest();
   }
   render() {

      let tckDetailsOutput = this.state.tckDetailsOutput;
      let qrdata = "data:image/png;base64," + this.state.qrdata;
      let divStyle = {
         backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%),  url(' + this.state.posterhori + ')',
         backgroundSize: 'cover'
      };

      return (
         <>
            {this.state.result == "success" ?
               <div class="cinescape_booking_section cinescape-booking-details cinecsape_booking">
                  <div
                     class="cinescape_section"
                     style={divStyle}
                  >
                     <div class="cinescape_booking home-content-container ">

                        <div className="booking-text-mobile booking-payment-mobile">
                           <h4>BOOKING INFORMATION</h4>

                        </div>


                        <div class="cinescape_booking_id cinecsape_booking-info">
                           {localStorage.getItem("onlyFood") ? (
                              ""
                           ) : (
                           <>
                           <div class="cinescape_img">
                              <div class="booking_img">
                                 <img
                                    src={tckDetailsOutput.poster ? tckDetailsOutput.poster : ''}
                                    alt="img"
                                 />
                              </div>
                           </div>
                           <div class="booking_text_section ">
                              <div className="boocking-section booking-desktop">
                                 <div class="booking_text">
                                    <h2>{tckDetailsOutput.moviename ? tckDetailsOutput.moviename : ''} </h2>
                                    <button type="button" class="age_btn">
                                       {tckDetailsOutput.mcensor ? tckDetailsOutput.mcensor : ''}
                                    </button>
                                 </div>
                                 <p>{tckDetailsOutput.language ? tckDetailsOutput.language : ''}</p>
                              </div>

                              {/* .................mobile view........................... */}
                              <div className="boocking-section booking-mobile">
                                 <div class="booking_text">
                                    <span className="movie-title-name">{tckDetailsOutput.moviename?tckDetailsOutput.moviename:''}</span>
                                    <span
                                       type="button"
                                       className="age_btn_history"
                                       style={{ backgroundColor: tckDetailsOutput.ratingColor }}

                                    >
                                       {tckDetailsOutput.mcensor}
                                    </span>
                                 </div>
                                 <p>{tckDetailsOutput.language ? tckDetailsOutput.language : ''}</p>
                              </div>
                              {/* ............................mobile view end........................... */}





                              <div class="booking_location">
                                 <p>   {localStorage.getItem("language") !== "ar" ? (
                                    "Location"
                                 ) : (
                                    <Text>موقع</Text>
                                 )}</p>
                                 <h5>{tckDetailsOutput.cinemaname ? tckDetailsOutput.cinemaname : ''}</h5>
                              </div>
                              <div class="booking_time">
                                 <p>{localStorage.getItem("language") !== "ar" ? (
                                    "Date & Time"
                                 ) : (
                                    <Text>التاريخ والوقت</Text>
                                 )}</p>
                                 <h5>{tckDetailsOutput.showDate ? tckDetailsOutput.showDate : ''} | {tckDetailsOutput.showTime ? tckDetailsOutput.showTime : ''} </h5>
                              </div>
                              <div class="screen screen-payment">
                                 <p>  {localStorage.getItem("language") !== "ar" ? (
                                    "Screen"
                                 ) : (
                                    <Text>شاشة</Text>
                                 )}</p>
                                 <p>
                                    {tckDetailsOutput.screenId ? tckDetailsOutput.screenId : ''}  <span> {tckDetailsOutput.experience ? <img src={tckDetailsOutput.experienceIcon} /> : ''}</span>
                                 </p>
                              </div>
                              <div class="seats">
                                 <p>  {localStorage.getItem("language") !== "ar" ? (
                                    "Seats"
                                 ) : (
                                    <Text>المقاعد</Text>
                                 )}</p>
                                 {tckDetailsOutput.seatsArr ? (
                                    tckDetailsOutput.seatsArr.map(function (item, i) {
                                       return (
                                          <h2>{item}</h2>
                                       );
                                    })
                                 ) : (
                                    ""
                                 )}
                              </div>
                              
                              <div class="screen screen-payment booking_time">
                                 <p>  {localStorage.getItem("language") !== "ar" ? (
                                    "Category"
                                 ) : (
                                    <Text>فئة</Text>
                                 )}</p>
                                 <h5>
                                    {tckDetailsOutput.category ? tckDetailsOutput.category : ''} 
                                 </h5>
                              </div>



                           </div>
                           </>
                           )}

                           
                           <div className={`cinescape_id ${localStorage.getItem("onlyFood") ? 'text-center' : ''}`}>
                           {/* <div class="cinescape_id text-center"> */}
                              <div class="booking_id_text">
                                 <div class="id_info">
                                    <p>   {localStorage.getItem("language") !== "ar" ? (
                                       "Booking ID"
                                    ) : (
                                       <Text>معرف الحجز</Text>
                                    )}</p>
                                    <h5>
                                       <b>{tckDetailsOutput.kioskId ? tckDetailsOutput.kioskId : ''} </b>
                                    </h5>
                                 </div>
                                 {tckDetailsOutput.pickUpNumber ? tckDetailsOutput.pickUpNumber != '' ?
                                    <div class="pick_up">
                                       <p>   {localStorage.getItem("language") !== "ar" ? (
                                          "FOOD PICK-UP NUMBER"
                                       ) : (
                                          <Text>رقم استلام الطعام</Text>
                                       )}</p>
                                       <h5>
                                          <b>{tckDetailsOutput.pickUpNumber ? tckDetailsOutput.pickUpNumber : ''}</b>
                                       </h5>
                                    </div> : ''
                                    : ''}
                              </div>
                              <div class="bar_icon">
                                 <img
                                    src={qrdata}
                                 />
                              </div>
                           </div>
                       


                        </div>
                     </div>
                  </div>
                  <div class="cinescape_booking ticket_section booking-confrm">
                     <div class="cinescape_bookings home-content-container">
                        <h2 class="text-center d-none-mobile-booking">
                           {localStorage.getItem("language") !== "ar" ? (
                              "Booking Details"
                           ) : (
                              <Text>تفاصيل الحجز</Text>
                           )}</h2>
                        
                        {localStorage.getItem("onlyFood") ? (
                           ""
                        ) : (
                        <>
                        <div class="ticket_details booking-ticket-details d-none-mobile-booking">
                           <div class="ticket_detail_info">
                              <p>   {localStorage.getItem("language") !== "ar" ? (
                                 "Ticket Details"
                              ) : (
                                 <Text>تفاصيل التذكرة</Text>
                              )}</p>
                           </div>
                           <div class="ticket_kd">
                              <p>{tckDetailsOutput.totalTicketPrice ? tckDetailsOutput.totalTicketPrice : ''}</p>
                           </div>
                        </div>
                        <div class="location_infos ticket_details d-none-mobile-booking">
                           <div class="location_date">
                              <div class="cinescape_location">
                                 <p> {localStorage.getItem("language") !== "ar" ? (
                                    "Location"
                                 ) : (
                                    <Text>موقع</Text>
                                 )}</p>
                                 <h5>
                                    <b>{tckDetailsOutput.cinemaname ? tckDetailsOutput.cinemaname : ''}</b>
                                 </h5>
                              </div>
                              <div class="cinescape_location booking-location">
                                 <p> {localStorage.getItem("language") !== "ar" ? (
                                    "Screen"
                                 ) : (
                                    <Text>شاشة</Text>
                                 )}</p>
                                 <h5>
                                    <b>   {tckDetailsOutput.screenId ? tckDetailsOutput.screenId : ''}  <span> {tckDetailsOutput.experience ? <img src={tckDetailsOutput.experienceIcon} /> : ''}</span></b>
                                 </h5>
                              </div>
                              <div class="cinescape_location booking-location">
                                 <p>  {localStorage.getItem("language") !== "ar" ? (
                                    "Category"
                                 ) : (
                                    <Text>فئة</Text>
                                 )}</p>
                                 <h5>
                                    <b>{tckDetailsOutput.category ? tckDetailsOutput.category : ''}</b> 
                                 </h5>
                              </div>
                           </div>
                           <div class="date_seats">
                              <div class="cinescape_location">
                                 <p> {localStorage.getItem("language") !== "ar" ? (
                                    "Date & Time"
                                 ) : (
                                    <Text>التاريخ والوقت</Text>
                                 )}</p>
                                 <h5>
                                    <b>{tckDetailsOutput.showDate ? tckDetailsOutput.showDate : ''} | {tckDetailsOutput.showTime ? tckDetailsOutput.showTime : ''}</b>
                                 </h5>
                              </div>
                              <div class="cinescape_location screen_m">
                                 <p> {localStorage.getItem("language") !== "ar" ? (
                                    "Seats"
                                 ) : (
                                    <Text>مقاعد</Text>
                                 )}</p>
                                 {tckDetailsOutput.seatsArr ? tckDetailsOutput.seatsArr.map(function (item, i) {
                                    return (
                                       <h5>
                                          {item}
                                       </h5>
                                    )
                                 }) : ''}
                              </div>
                              

                           </div>
                        </div>
                        </>
                        )}


                        {tckDetailsOutput.concessionFoods &&
                           tckDetailsOutput.concessionFoods.length > 0 ?
                           <>
                              <div class="ticket_details">
                                 <div class="ticket_detail_info">
                                    <p> {localStorage.getItem("language") !== "ar" ? (
                                       "Food Details"
                                    ) : (
                                       <Text>تفاصيل الغذاء</Text>
                                    )}</p>
                                 </div>
                                 <div class="ticket_kd">
                                    {/* 
               <p>{tckDetailsOutput.totalPrice ? tckDetailsOutput.totalPrice : ''}</p>
               */}
                                 </div>
                              </div>
                              <div class="food_table boocking-food">
                                 <table class="item_info">
                                    <tr style={{ textAlign: "left" }}>
                                       <th > {localStorage.getItem("language") !== "ar" ? (
                                          "Item "
                                       ) : (
                                          <Text>غرض</Text>
                                       )}</th>
                                       <th style={{
                                          textAlign: "center",
                                       }}>    {localStorage.getItem("language") !== "ar" ? (
                                          "Qty"
                                       ) : (
                                          <Text>الكمية</Text>
                                       )}</th>
                                       <th style={{
                                          textAlign: "center",
                                       }}>  {localStorage.getItem("language") !== "ar" ? (
                                          "Price"
                                       ) : (
                                          <Text>سعر</Text>
                                       )}</th>
                                       <th style={{
                                          textAlign: "center",
                                       }}>{localStorage.getItem("language") !== "ar" ? (
                                          "Total"
                                       ) : (
                                          <Text>المجموع</Text>
                                       )}</th>
                                    </tr>
                                    {tckDetailsOutput.concessionFoods ? (
                                       tckDetailsOutput.concessionFoods.map(function (item, i) {
                                          return (
                                             <tr>
                                                <td width="50%">
                                                   <div class="food_details">
                                                      <div class="food_img">
                                                         <img
                                                            src={require("../../assets/popcorn.png").default}
                                                         />
                                                      </div>
                                                      <div class="food_info">
                                                         {/* 
                           <h5>Popcorn</h5>
                           */} <p>{item.name}</p>
                                                         <p>{item.description}</p>
                                                      </div>
                                                   </div>
                                                </td>
                                                <td width="10%" style={{
                                                   textAlign: "center",
                                                }}>{item.quantity}</td>
                                                <td width="10%" style={{
                                                   textAlign: "center",
                                                }}>{item.itemPrice}</td>
                                                <td width="10%" style={{
                                                   textAlign: "center",
                                                }}>    {localStorage.getItem("language") !== "ar" ? (
                                                   "KWD"
                                                ) : (
                                                   <Text>دينار كويتي</Text>
                                                )} {((item.priceInCents * item.quantity) / 100).toFixed(3)}</td>
                                             </tr>
                                          )
                                       })
                                    ) : (
                                       ""
                                    )}
                                 </table>
                              </div>





                           </> : ''}


                        <div class="ticket_details booking-ticket-details d-none-mobile-booking">
                           <div class="ticket_detail_info">
                              <p>   {localStorage.getItem("language") !== "ar" ? (
                                 "Transaction Details"
                              ) : (
                                 <Text>تفاصيل الصفقه</Text>
                              )}</p>
                           </div>
                           <div class="ticket_kd">
                              <p>{tckDetailsOutput.totalPrice ? tckDetailsOutput.totalPrice : ''}</p>
                           </div>
                        </div>


                        {/* Transaction Details */}
                        <div className="booking-new-payment d-none-mobile-booking">

                           <div class="location_infos ticket_details booking-new-payment">
                              <div class="location_date">
                                 <div class="cinescape_location">
                                    <p>Track ID</p>
                                    <h5>{tckDetailsOutput.bookingId ? tckDetailsOutput.bookingId : ''}</h5>
                                 </div>
                                 <div class="cinescape_location">
                                    <p> Transaction ID</p>
                                    <h5>{tckDetailsOutput.vistaNumber ? tckDetailsOutput.vistaNumber : ''}</h5>
                                 </div>
                                 <div class="cinescape_location">
                                    <p> Transaction Date & Time</p>
                                    <h5>{tckDetailsOutput.bookingTime ? tckDetailsOutput.bookingTime : ''}</h5>
                                 </div>
                                 {tckDetailsOutput.payStatus != '' ?
                                    <div class="cinescape_location">
                                       <p>Result</p>
                                       <h5>{tckDetailsOutput.payStatus ? tckDetailsOutput.payStatus : ''}</h5>
                                    </div> : ''}
                                 <div class="cinescape_location">
                                    <p>Payment Mode</p>
                                    <h5>{tckDetailsOutput.payDone ? tckDetailsOutput.payDone : ''}</h5>
                                 </div>


                              </div>
                              <div class="date_seats">
                                 {tckDetailsOutput.authCode != '' ?
                                    (< div class="cinescape_location">
                                       <p>Authorization Code </p>
                                       <h5>{tckDetailsOutput.authCode ? tckDetailsOutput.authCode : ''}</h5>
                                    </div>) : ''}
                                 {tckDetailsOutput.referenceId != '' ?
                                    <div class="cinescape_location">
                                       <p>Payment ID</p>
                                       <h5>{tckDetailsOutput.referenceId ? tckDetailsOutput.referenceId : ''}</h5>
                                    </div> : ''}
                              </div>
                           </div>
                        </div>


                     </div>

                     {/* Transaction Details */}

                     {/*   
      <div class="ticket_price">
         <table width="100%">
            <tr style={{ textAlign: "left" }}>
            <th>Ticket Price</th>
            <th>{tckDetailsOutput.totalTicketPrice ? tckDetailsOutput.totalTicketPrice : ''} </th>
            <th>Payment Mode</th>
            <th>Grand Total</th>
            </tr>
            <tr>
               <td width="10%">Food Price</td>
               <td width="35%">{tckDetailsOutput.foodTotal ? tckDetailsOutput.foodTotal : ''} </td>
               <td width="20%">
                  <b>{tckDetailsOutput.payDone ? tckDetailsOutput.payDone : ''} </b>
               </td>
               <td width="35%">
                  <b>{tckDetailsOutput.totalPrice ? tckDetailsOutput.totalPrice : ''} </b>
               </td>
            </tr>
         </table>
      </div>
      */}
                     {/* 
      <div class="location_infos ticket_details">
         <div class="location_date">
            <div class="cinescape_location">
               <p>
                  {" "}
                  {localStorage.getItem("language") !== "ar" ? (
                  "Payment ID"
                  ) : (
                  <Text>موقع</Text>
                  )}
               </p>
               <h5>
                  <b>{tckDetailsOutput.cinemaname}</b>
               </h5>
            </div>
            <div class="cinescape_location">
               <p>
                  {localStorage.getItem("language") !== "ar" ? (
                  "Track ID"
                  ) : (
                  <Text>شاشة</Text>
                  )}
               </p>
               <h5>
                  <b>{tckDetailsOutput.screenId} <span><img src={tckDetailsOutput.experienceIcon} /></span></b>
               </h5>
            </div>
         </div>
         <div class="date_seats">
            <div class="cinescape_location">
               <p>
                  {localStorage.getItem("language") !== "ar" ? (
                  "Transaction Date & Time"
                  ) : (
                  <Text>التاريخ والوقت</Text>
                  )}
               </p>
               <h5>
                  <b>{tckDetailsOutput.showDate} {tckDetailsOutput.showTime}</b>
               </h5>
            </div>
            <div class="cinescape_location screen_m">
               <p>
                  {localStorage.getItem("language") !== "ar" ? (
                  "Payment Mode"
                  ) : (
                  <Text>المقاعد</Text>
                  )}
               </p>
               <h5>
                  <b>{tckDetailsOutput.payDone}</b>
               </h5>
            </div>
         </div>
      </div>
      */}
                     <div class="ticket_price desktop-booking home-content-container">
                        <div class="food-info booking-food">
                           <div className="price-section">
                              <div className="price-info">
                                 <div className="ticket-section-info">
                                    <div className="ticket-price">

                                       <p>   {localStorage.getItem("language") !== "ar" ? (
                                          "Ticket Price"
                                       ) : (
                                          <Text>سعر التذكرة</Text>
                                       )}</p>
                                    </div>
                                    <div className="kd-price">
                                       <p>{tckDetailsOutput.totalTicketPrice ? tckDetailsOutput.totalTicketPrice : ''} </p>
                                    </div>
                                 </div>
                                 <div className="ticket-section-info">
                                    <div className="ticket-price">
                                       <p>{localStorage.getItem("language") !== "ar" ? (
                                          "Food Price"
                                       ) : (
                                          <Text>سعر الغذاء</Text>
                                       )}</p>

                                    </div>
                                    <div className="kd-price">
                                       <p>{tckDetailsOutput.foodTotal ? tckDetailsOutput.foodTotal : ''} </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="payment-section">
                              <div className="payment-mode">


                                 <div className="ticket-section-info payment-mode-info">
                                    <div className="ticket-price payment-card">
                                       <p>	  {localStorage.getItem("language") !== "ar" ? (
                                          "Payment Mode"
                                       ) : (
                                          <Text>طريقة الدفع</Text>
                                       )}</p>
                                       <b> {tckDetailsOutput.payDone} </b>
                                    </div>
                                 </div>
                                 <div class="grand-total ticket-price">
                                    <p>    {localStorage.getItem("language") !== "ar" ? (
                                       "Grand Total"
                                    ) : (
                                       <Text>المجموع الإجمالي</Text>
                                    )}</p>
                                    <b>{tckDetailsOutput.totalPrice} </b>
                                 </div>


                              </div>
                           </div>
                        </div>
                     </div>


                     {/*------------------- ---------------mobile-view ticket_price-------------------------------------- */}
                     <div className="new-payment-mobile">
                        <div class="ticket_price">


                           <div class="ticket_details booking-ticket-details">
                              {/* <div class="ticket_detail_info">
                              <p>Ticket Details</p>
                           </div>
                           <div class="ticket_kd">
                              <p>{tckDetailsOutput.totalTicketPrice ? tckDetailsOutput.totalTicketPrice : ''}</p>
                           </div> */}
                           </div>


                           <div class="location_infos ticket_details">

                              <div class="location_date">
                                 <div className="booking-con-mobile">
                                    <div className="cinescape_location">
                                       <p> Track ID</p>
                                       <h5>{tckDetailsOutput.bookingId ? tckDetailsOutput.bookingId : ''}</h5>
                                    </div>
                                    <div class="cinescape_location">
                                       <p> Transaction ID</p>
                                       <h5>{tckDetailsOutput.vistaNumber ? tckDetailsOutput.vistaNumber : ''}</h5>
                                    </div>
                                 </div>

                                 <div class="cinescape_location">
                                    <p> Transaction Date & Time</p>
                                    <h5>{tckDetailsOutput.bookingTime ? tckDetailsOutput.bookingTime : ''}</h5>
                                 </div>
                                 {/* <div class="cinescape_location">
                                    <p>Result</p>
                                    <h5>{tckDetailsOutput.payStatus ? tckDetailsOutput.payStatus : ''}</h5>
                                 </div> */}
                                 {/* <div class="cinescape_location">
                                    <p>Payment Mode</p>
                                    <h5>{tckDetailsOutput.payDone ? tckDetailsOutput.payDone : ''}</h5>
                                 </div> */}


                              </div>
                              <div class="date_seats">
                                 {tckDetailsOutput.authCode != '' ?
                                    (< div class="cinescape_location">
                                       <p>Authorization Code </p>
                                       <h5>{tckDetailsOutput.authCode ? tckDetailsOutput.authCode : ''}</h5>
                                    </div>) : ''}
                                 {tckDetailsOutput.referenceId != '' ?
                                    <div class="cinescape_location">
                                       <p>Payment ID</p>
                                       <h5>{tckDetailsOutput.referenceId ? tckDetailsOutput.referenceId : ''}</h5>
                                    </div> : ''}
                              </div>
                           </div>

                           <div class="food-info booking-food">
                              <div className="price-section">
                                 <div className="price-info">
                                    <div className="ticket-section-info">
                                       <div className="ticket-price">
                                          <p>Ticket Price</p>
                                       </div>
                                       <div className="kd-price">
                                          <p>{tckDetailsOutput.totalTicketPrice ? tckDetailsOutput.totalTicketPrice : ''} </p>
                                       </div>
                                    </div>
                                    <div className="ticket-section-info">
                                       <div className="ticket-price">
                                          <p>Food Price	</p>
                                       </div>
                                       <div className="kd-price">
                                          <p>{tckDetailsOutput.foodTotal ? tckDetailsOutput.foodTotal : ''} </p>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className="payment-section">
                                 <div className="payment-mode">
                                    <div className="ticket-section-info payment-mode-info">
                                       <div className="ticket-price payment-card">
                                          <p>Payment Mode	</p>
                                          <h5>{tckDetailsOutput.payDone ? tckDetailsOutput.payDone : ''} </h5>
                                       </div>
                                    </div>

                                 </div>
                              </div>




                              <div className="ticket-section-info grand-total-booking">
                                 <div className="ticket-price grand-total">
                                    <p><b>Grand Total</b> 	</p>
                                 </div>
                                 <div className="kd-price">
                                    <p><b>{tckDetailsOutput.foodTotal ? tckDetailsOutput.foodTotal : ''} </b></p>
                                 </div>
                              </div>


                           </div>




                        </div>


                     </div>





                     {/*------------------- ---------------mobile-view ticket_price-------------------------------------- */}


                  </div>
               </div>
               :
               <div class="cinescape_booking_section cinescape-booking-details">
                  <div
                     class="cinescape_section"
                     style={divStyle}
                  >
                     <div class="cinescape_booking home-content-container">
                        <div class="cinescape_booking_id">
                           <div class="cinescape_img">
                              <div class="booking_img">
                              </div>
                           </div>
                           <div class="booking_text_section">
                              <div class="booking_location_section booking-confirm-feild">
                                 <div class="cinescape_id">

                                 <div style={{ textAlign: 'center', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <div class="btnc btnc-primary" style={{ marginBottom: '30px' }}>
        Sorry, Your transaction FAILED!
      </div>
      <div style={{ marginBottom: '30px' }}>
        <p>Your reservation was unsuccessful, kindly contact your bank.</p>
      </div>
      <div style={{ marginBottom: '30px' }}>
        <p><b>Reference ID:</b> {tckDetailsOutput.referenceId ? tckDetailsOutput.referenceId : ''}</p>
      </div>
      <div style={{ marginBottom: '30px' }}>
        <p><b>Track Number:</b> {tckDetailsOutput.bookingId ? tckDetailsOutput.bookingId : ''}</p>
      </div>
      <div style={{ marginBottom: '30px' }}>
        <p><b>Transaction Date & Time:</b> {tckDetailsOutput.bookingTime ? tckDetailsOutput.bookingTime : ''}</p>
      </div>
   

                            <center>
                                       <p>
                               
                                             <button class="btnc btnc-primary" href={localStorage.getItem("moviesessionPath")}>
                                             Go back to Showtimes </button>
                                       
                                       </p>
                                       </center>
                                    </div>
                            
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            }
         </>
      );
   }
}
export default BookingConfirm;